import { DocumentNode, GraphQLFormattedError } from "graphql"
import {
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationOptions,
  OperationVariables,
} from "@apollo/client"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"

import defaults from "../../config/defaults"
import * as Types from "../types"
import client from "./app-apollo-client"

export * from "./mutations"
export * from "./queries"

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

export function isSuccess<a>(result: Types.Status<a>): result is Types.Success<a> {
  if (result === "init") return false
  if (result === "loading") return false
  return result.status === "success"
}

export function isError<a>(
  result: Types.Status<a>,
): result is Types.Err {
  if (result === "init") return false
  if (result === "loading") return false
  return result.status === "error"
}

export async function login(
  code: string,
  username: string,
  password: string,
): Promise<Types.Success<string> | Types.Err> {
  try {
    const response = await fetch(defaults.TIN.APP.BACKENDS.LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        username,
        password,
      }),
    }).then((res) => res.json())

    // The API returns status codes only for error states...
    if (response.status != null) {
      return {
        status: "error",
        message: response.message,
        statusCode: response.status,
      }
    }

    return {
      status: "success",
      payload: response.id,
    }
  } catch (err: any) {
    return {
      status: "error",
      message: err.message,
    }
  }
}

const abortedRequestString = "signal is aborted without reason"
const requiresAuthString = "Requires Authentication"

export async function query<
  a,
  b extends OperationVariables = OperationVariables,
>(
  document: DocumentNode,
  variables?: b,
  fetchPolicy?: MutationOptions["fetchPolicy"],
  context?: DefaultContext,
  noRedirect?: boolean,
): Promise<Types.Success<a> | Types.Err> {
  try {
    const response: ApolloQueryResult<a> = await client.query<a, b>({
      query: document,
      variables,
      fetchPolicy: fetchPolicy || "no-cache",
      context,
    })

    // On occasion, queries may result in a list of errors
    if (response.errors != null && response.errors.length > 0) {
      const errorString = response.errors
        .map((e: GraphQLFormattedError) => e.message)
        .join(", ")

      if (errorString.includes(requiresAuthString) && !noRedirect) {
        window.location.href = "/find-team"
      }

      return {
        status: "error",
        message: errorString,
      }
    }

    // On occasion, queries may result in a single error
    if (response.error != null) {
      if (response.error?.message.includes(requiresAuthString) && !noRedirect) {
        window.location.href = "/find-team"
      }

      return {
        status: "error",
        message: response.error.message,
      }
    }

    return {
      status: "success",
      payload: response.data,
    }
  } catch (err: any) {
    if (err?.message.includes(requiresAuthString) && !noRedirect) {
      window.location.href = "/find-team"
    }

    let statusCode: Types.ErrorStatusCode = 500

    if (err?.message.includes(abortedRequestString)) {
      statusCode = 499
    }

    return {
      status: "error",
      statusCode,
      message: err?.message,
    }
  }
}

export async function mutate<
  a,
  b extends OperationVariables = OperationVariables,
>(
  document: DocumentNode,
  variables?: b,
  refetchQueries?: MutationOptions["refetchQueries"],
  update?: MutationOptions["update"],
  noRedirect?: boolean,
): Promise<Types.Success<a | null> | Types.Err> {
  try {
    const response: FetchResult<a> = await client.mutate<a, b>({
      mutation: document,
      variables,
      refetchQueries,
      awaitRefetchQueries: true,
      update,
    })

    if (response.errors != null && response.errors.length > 0) {
      const errorString = response.errors
        .map((e: GraphQLFormattedError) => e.message)
        .join(", ")

      if (errorString.includes(requiresAuthString) && !noRedirect) {
        window.location.href = "/find-team"
      }

      return {
        status: "error",
        message: errorString,
      }
    }

    return {
      status: "success",
      payload: response.data || null,
    }
  } catch (err: any) {
    if (err?.message.includes(requiresAuthString) && !noRedirect) {
      window.location.href = "/find-team"
    }

    return {
      status: "error",
      statusCode: 500,
      message: err?.message,
    }
  }
}
