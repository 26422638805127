import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as Constant from "../../../util/constant"
import EntityInfoRow from "../../EntityInfoRow"
import ModalAddTo from "../../ModalAddTo"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  closeModalAddToCommunications,
  createCommunicationGroupNetworkAccountsAddModalMutation,
  getComms,
  toggleSelectedComm,
  getCommsForAccount,
} from "../../../state/modalAddToCommunications"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"
import ErrorHandler from "../../ErrorHandler"
import { pushToast } from "../../../state/toastSlice"
import { ALL_NETWORKS } from "../../../util/types"
import { getCommsForProfile } from "../../../state/listSocialProfileSlice"

export default function ModalAddToCommunications() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })

  const {
    open,
    comms,
    commsForAccount,
    selectedCommIDs,
    socialAccountID,
    socialAccountNetwork,
  } = useSelector(({ modalAddToComms }) => modalAddToComms)

  const dispatch = useDispatch()

  const [ submitLoading, setSubmitLoading ] = useState(false)

  useEffect(() => {
    if (open) {
      if (comms === "init") dispatch(getComms(""))
      if (commsForAccount === "init" && socialAccountID && socialAccountNetwork) {
        dispatch(getCommsForAccount(socialAccountID, [ socialAccountNetwork ]))
      }
    }
  }, [ open ])

  const renderContent = () => {
    if (comms === "init" || comms === "loading" || commsForAccount === "init" || commsForAccount === "loading") {
      return (
        <LoadingIndicatorCard />
      )
    }
    if (API.isError(comms) || API.isError(commsForAccount)) {
      return <ErrorHandler />
    }
    const idsAlreadyAdded = commsForAccount.payload.searchCommunicationGroupsByNetworkAccountIds.rows.map((row) => row.id)
    return comms.payload.searchCommunicationGroup.rows.map((row) => {
      const id = `comm-group-${ row.name }`
      const alreadyAdded = idsAlreadyAdded.includes(row.id)
      const checked = alreadyAdded || selectedCommIDs.includes(row.id)
      const disabled = alreadyAdded || (!checked && selectedCommIDs.length >= 10)
      const classes = [ "comm-group-entity" ]
      if (!checked) classes.push("entity-unchecked")
      return (
        <EntityInfoRow
          id={ id }
          className={ classes.join(" ") }
          key={ row.id }
          onClick={ () => {
            if (!disabled) dispatch(toggleSelectedComm(row.id))
          } }
          checkboxEnabled={ true }
          checkboxDisabled={ disabled }
          checkboxValue={ checked }
          name={ row.name }
          subInfo={
              `${ row.creator.username } | ${ dayjs(row.created, "X").format(Constant.LONGFORM_DATE) }`
            }
        />
      )
    })
  }

  const handleClose = () => {
    dispatch(closeModalAddToCommunications())
  }

  const handleSubmit = async () => {
    if (!socialAccountID) return
    setSubmitLoading(true)
    await dispatch(createCommunicationGroupNetworkAccountsAddModalMutation({
      variables: {
        socialAccountID,
        commIDs: selectedCommIDs,
      },
      onSuccess: async () => {
        dispatch(pushToast({
          message: translate("Account has successfully been added!"),
          type: "success",
        }))
        dispatch(getCommsForProfile({
          networkAccountIds: [ socialAccountID ],
          networkFilter: ALL_NETWORKS,
        }))
      },
      onError: () => dispatch(pushToast({
        message: translate("There was an error. Please try again."),
        type: "error",
      })),
    }))
    handleClose()
    setSubmitLoading(false)
  }

  return (
    <ModalAddTo
      title={ translate("Add To Communication") }
      subtitle={ translate("Select the communication group that you would like to add the selected account to.") }
      selectedEntityCount={ selectedCommIDs.length }
      open={ open }
      closeAction={ handleClose }
      primaryAction={ handleSubmit }
      submitLoading={ submitLoading }
      fetchData={ (e) => dispatch(getComms(e.target.value)) }
      disabled={ selectedCommIDs.length === 0 }
    >
      { renderContent() }
    </ModalAddTo>
  )
}
