/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useNavigate, useParams } from "react-router-dom"
import Tabs, { TabLabelProps } from "../../Tabs"
import "./style.sass"
import PublicListAccountHeader from "./PublicListAccountHeader"
import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicator from "../../LoadingIndicator"
import { isSuccess } from "../../../util/apiClient"
import {
  checkDisplayAccountDetails,
  checkDisplayAudienceTab,
  loadToggles,
} from "../../../state/publicListSlice"
import { ToggleFragment } from "../../../graphql"
import AccountContentDetails from "./AccountContentDetails"
import AccountInsightsDetails from "./AccountInsightsDetails"
import AccountAudienceDetails from "./AccountAudienceDetails"
import InfluentialLogo from "../../InfluentialLogo"
import EmptySplash from "../../EmptySplash"

// eslint-disable-next-line no-shadow
enum TabPathsEnum {
  INSIGHTS = "insights",
  AUDIENCE = "audience",
  NOTES = "notes",
  LISTS = "lists",
  CAMPAIGNS = "campaigns",
  COMMS = "communications",
  CONTENT = "content",
}
interface PublicTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.INSIGHTS
  | TabPathsEnum.AUDIENCE
  | TabPathsEnum.NOTES
  | TabPathsEnum.LISTS
  | TabPathsEnum.CAMPAIGNS
  | TabPathsEnum.COMMS
  | TabPathsEnum.CONTENT
}

export default function PublicListTabs() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListTabs" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tabPath, contentTabPath } = useParams()
  const {
    list,
    initialPostKeywords,
    initialPostsImageTags,
    loadedListSocialAccount,
    leftPanelStatus,
    rightPanelStatus,
    toggles,
  } = useSelector(({ publicList }) => publicList)

  const classes = [ "cp_profile_component-public-list-tabs" ]
  if (leftPanelStatus === "expanded" && rightPanelStatus === "closed") {
    classes.push("overview-expanded")
  }
  if (leftPanelStatus === "closed" && rightPanelStatus !== "closed") {
    classes.push("right-panel-expanded")
  }

  // loadToggles is a somewhat expensive calculation (n^2).
  // memoized listToggleValue is to make sure it only recalculates when it needs to
  const listToggleValue: ToggleFragment[] | null = React.useMemo(() => {
    if (isSuccess(list)) {
      return list.payload.publicSuggestionListByCode.toggles
    }
    return null
  }, [ list ])

  React.useEffect(() => {
    if (listToggleValue) {
      dispatch(loadToggles(listToggleValue))
    }
  }, [ listToggleValue ])

  const [
    displayImageTagPosts,
    displayKeywordPosts,
    displayTopPosts,
    displayRelevantPosts,
  ] = React.useMemo((): boolean[] => {
    if (isSuccess(loadedListSocialAccount) && toggles) {
      const {
        cippusFeatured,
      } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
      const displayFeatured = cippusFeatured.length > 0
      const displayImageTags = isSuccess(initialPostsImageTags)
        ? initialPostsImageTags.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.imageTagsWithPosts.length > 0
        : false
      const displayKeywords = isSuccess(initialPostKeywords)
        ? initialPostKeywords.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.keywordsWithPosts.length > 0
        : false

      return [
        displayImageTags,
        toggles.displayKeywords && displayKeywords,
        toggles.displayTopPosts && displayFeatured,
        toggles.displayKeywords && (displayImageTags || displayKeywords),
      ]
    }
    return new Array(4).fill(false)
  }, [ loadedListSocialAccount, toggles, initialPostKeywords, initialPostsImageTags ])

  const displayAccountDetailsTab = React.useMemo(() => checkDisplayAccountDetails(toggles), [ toggles ])
  const displayAudienceTab = React.useMemo(() => checkDisplayAudienceTab(toggles), [ toggles ])

  const tabs: PublicTabLabelProps[] = React.useMemo(() => {
    const t: PublicTabLabelProps[] = []
    if (displayAccountDetailsTab) t.push({ label: translate("Account Insights"), path: TabPathsEnum.INSIGHTS })
    if (displayAudienceTab) t.push({ label: translate("Audience"), path: TabPathsEnum.AUDIENCE })
    if ((displayTopPosts || displayRelevantPosts)) t.push({ label: translate("Content"), path: TabPathsEnum.CONTENT })
    return t
  }, [ translate, displayAccountDetailsTab, displayAudienceTab, displayTopPosts, displayRelevantPosts ])

  const setTab = (tabIndex: number) => {
    const newTabPath = tabs[tabIndex].path
    const pathPrefix = contentTabPath ? "../../" : (tabPath ? "../" : "")
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  React.useEffect(() => {
    if (tabs.length) setTab(0)
  }, [ tabs ])

  const tabPage = () => {
    switch (tabPath) {
      case TabPathsEnum.INSIGHTS:
      case undefined:
        if (displayAccountDetailsTab) return <AccountInsightsDetails toggles={ toggles } />
        return null
      case TabPathsEnum.AUDIENCE:
        if (displayAudienceTab) return <AccountAudienceDetails toggles={ toggles } />
        return null
      case TabPathsEnum.CONTENT:
        if (displayTopPosts || displayRelevantPosts) {
          return (
            <AccountContentDetails
              displayTopPosts={ displayTopPosts }
              displayImageTagPosts={ displayImageTagPosts }
              displayKeywordPosts={ displayKeywordPosts }
            />
          )
        }
        return null
      default:
        return null
    }
  }

  const tabIndex = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === tabPath)
    return foundTab ? tabs.indexOf(foundTab) : 0
  }, [ tabPath ])

  const renderTabs = () => {
    if (
      !toggles
      || (displayImageTagPosts && (initialPostsImageTags === "init" || initialPostsImageTags === "loading"))
      || (displayKeywordPosts && (initialPostKeywords === "init" || initialPostKeywords === "loading"))
    ) {
      return <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
    }
    if (toggles) {
      return (
        <Tabs
          handleChange={ setTab }
          tabs={ tabs }
          defaultValue={ tabIndex }
          controls={ <div /> }
          controlledValue={ tabIndex }
        />
      )
    }
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }

  // loadedListSocialAccount will be "init" if social accounts are hidden
  // the fetchPublicListSocialProfile is never dispatched
  if (isSuccess(list) && loadedListSocialAccount === "init") {
    return (
      <div className="cp_profile_component-public-list-tabs-empty-container">
        <EmptySplash headlineText={ translate("No social accounts have been added to this list.") } />
      </div>
    )
  }

  return (
    <div className={ classes.join(" ") }>
      <PublicListAccountHeader />
      <div className="cp_profile_component-public-list-tabs-container">
        { renderTabs() }
      </div>
      { tabPage() }
      <div className="cp_profile_component-public-list-tabs-logo-container">
        <InfluentialLogo className="logo" hasTag={ true } />
      </div>
    </div>
  )
}
