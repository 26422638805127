/* eslint-disable react/no-danger */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, CardMedia } from "@mui/material"
import {
  Attachment,
  InsertPhotoOutlined,
  CheckCircleOutlineOutlined,
} from "@mui/icons-material"
import { useParams } from "react-router-dom"
import * as GraphQL from "../../graphql"
import Avatar from "../Avatar"
import { formatTimestampToCustomDate, limitTextLength } from "../../util/miscHelper"

import "./communications-reading-panel-view.sass"
import EmailStatusLabel from "./EmailStatusLabel"
import Pill from "../Pill"
import { useSelector, useDispatch } from "../../state/hooks"
import { RootState } from "../../state/store"
import { removeLabelFromSocialAccount } from "../../state/communicationsPage"
import { Toast } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import { fetchConversationThread } from "../../state/commEmailListView"

interface ConversationData {
  hasMessages: boolean,
  messages: GraphQL.GetConversationQuery["getConversation"]["conversationThread"]["timeline"],
  subject: GraphQL.GetConversationQuery["getConversation"]["subject"],
  socialAccount: GraphQL.GetConversationQuery["getConversation"]["socialAccount"] | null,
  Labels: GraphQL.GetNetworkAccountLabelsQuery["getCommunicationGroupNetworkAccountLabels"],
}

// ConversationUploadsProps has the shared properties
interface ConversationUploadsProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string,
  message: ConversationData["messages"][0],
  socialAccount: ConversationData["socialAccount"],
  // eslint-disable-next-line react/no-unused-prop-types
  labels?: ConversationData["Labels"],
}

// CommMessageProps extends ConversationUploadsProps and adds extra fields
interface CommMessageProps extends ConversationUploadsProps {
  indx?: number
  totalMessages?: number
  presentation?: string
}

export function CommMessage({
  id, message, socialAccount, labels = [], indx, totalMessages, presentation,
}: CommMessageProps) {
  if (message.__typename !== "ConversationMessage") return null
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  const { commGroupID } = useParams()
  const dispatch = useDispatch()
  const { labelColors: lblColors } = useSelector((root: RootState) => root.communicationsPage)
  const [ showFullText, setShowFullText ] = React.useState(false)
  const fromName = message.outgoing ? message.from.alias : socialAccount?.userName
  const imageUrl = message.outgoing
    ? undefined
    : socialAccount?.profilePictureUrl || undefined
  const fromEmail = message.from.address
  const emailStatus = message.events.length ? message.events[message.events.length - 1].type : null
  const { created } = message
  const to = message.to.length ? message.to.map((toEmail) => toEmail.address).join(", ") : ""
  const cc = message.cc.length ? message.cc.map((ccEmail) => ccEmail.address).join(", ") : ""
  const bodyText = message.text
  const bodyHtml = message.html
  const hasAttachments = message.attachments.length > 0

  const shouldShowFullText = () => {
    if (presentation === "last") return true
    if (presentation === "first" || presentation === "middle") return false
    if (totalMessages === 1) return true
    if (totalMessages === 2 && indx === 1) return true
    return false
  }

  useEffect(() => {
    setShowFullText(shouldShowFullText())
  }, [ totalMessages, indx ])

  const deleteLabelFromAccount = (labelId: string) => {
    // Issue delete for pill
    dispatch(removeLabelFromSocialAccount({
      vars: {
        socialAccountLabelId: labelId,
      },
      onSuccess: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "success",
          message: translate("Successfully removed label from account"),
        }
        dispatch(pushToast(toast))
        if (commGroupID) {
          dispatch(fetchConversationThread(id, commGroupID))
        }
      },
      onError: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "error",
          message: translate("Failed to remove label from account"),
        }
        dispatch(pushToast(toast))
      },
    }))
  }

  const renderLabelPill = (
    label: ConversationData["Labels"][0],
  ) => {
    // Extract label color
    const labelColor = lblColors.find((lc) => lc.label === label.label)

    // Check to see if it was found
    if (labelColor) {
      // Return the label with correct color
      return (
        <Pill
          size="small"
          id={ label.id }
          className={ `cell-pill-color${ labelColor.colorIndex }` }
          label={ label.label }
          onDelete={ () => { deleteLabelFromAccount(label.id) } }
        />
      )
    }

    // Retrun label with default color
    return (
      <Pill
        size="small"
        id={ label.id }
        className="cell-pill-color0"
        label={ label.label }
        onDelete={ () => { deleteLabelFromAccount(label.id) } }
      />
    )
  }

  return (
    <div
      key={ message.id }
      className={ `cp_ct-thread single-message ${ presentation || "" }` }
    >
      <div className="cp_ct-thread-message-content">
        <div
          className="cp_ct-thread-message-content-top"
          onClick={ () => { setShowFullText(!showFullText) } }
          onKeyDown={ (e) => {
            if (e.key === "Enter" || e.key === " ") {
              setShowFullText(!showFullText)
            }
          } }
          role="button"
          tabIndex={ 0 }
        >
          <Avatar size="sm" src={ imageUrl } className="cp_ct-thread-avatar">
            { fromName ? fromName.charAt(0) : "" }
          </Avatar>

          <p className="cp_ct-thread-handle-name">
            { fromName }
          </p>
          { showFullText && (
          <p className="cp_ct-thread-message-address">{ fromEmail }</p>
          ) }
          <EmailStatusLabel className="cp_ct-thread-email-status" status={ emailStatus } />
          <p className="cp_ct-thread-date">{ formatTimestampToCustomDate(created) }</p>
        </div>
        { labels.length > 0 && (
          <div id="account-label-pills" className="account-label-pills-style">
            { labels.map((label) => renderLabelPill(label)) }
          </div>
        ) }
        { (to && showFullText) && <p className={ `cp_ct-thread-to ${ cc ? "has-cc" : "" }` }>{ `to ${ to }` }</p> }
        { (cc && showFullText) && <p className="cp_ct-thread-cc">{ `cc ${ cc }` }</p> }
        { showFullText
          ? (
            <div className="cp_ct-thread-body">
              { bodyHtml && <div dangerouslySetInnerHTML={ { __html: bodyHtml } } /> }
            </div>
          )
          : <p className="cp_ct-thread-body shorten">{ limitTextLength(bodyText, 144) }</p>
        }
        { hasAttachments && (
        <>
          <div className="cp_ct-thread-content-top">
            <Attachment className="email-attachment-icon attachment" />
            <p>{ translate("attachments") }</p>
          </div>
          <div className="cp_ct-thread-attachments">
            { message.attachments.map((attachment) => (
              <Link
                href={ attachment.content.downloadUrl }
                download={ attachment.name }
                className="cp_ct-thread-attachment"
              >
                { (attachment.thumbnail && attachment.thumbnail.url)
                  ? (
                    <CardMedia
                      component="img"
                      height="40"
                      image={ attachment.thumbnail.url }
                      alt={ attachment.name }
                    />
                  )
                  : (
                    <div className="cp_ct-thread-attachment-icon-placeholder">
                      <InsertPhotoOutlined className="cp_ct-thread-attachment-icon-download" />
                    </div>
                  ) }
              </Link>
            )) }
          </div>
        </>
        ) }
      </div>
    </div>
  )
}

export function ConversationUploads({
  id,
  message,
  socialAccount,
  labels = [],
}: ConversationUploadsProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  const dispatch = useDispatch()
  const { commGroupID } = useParams()
  const { labelColors: lblColors } = useSelector((root: RootState) => root.communicationsPage)
  if (message.__typename !== "ConversationUpload" || !socialAccount) return null
  const fromName = socialAccount.userName
  const fromEmail = `(${ message.from.address })`
  const { created, uuid } = message
  const hasAttachments = message.attachments.length > 0
  const hasSnippet = message.snippets.length > 0
  const imageUrl = socialAccount.profilePictureUrl || undefined

  const deleteLabelFromAccount = (labelId: string) => {
    // Issue delete for pill
    dispatch(removeLabelFromSocialAccount({
      vars: {
        socialAccountLabelId: labelId,
      },
      onSuccess: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "success",
          message: translate("Successfully removed label from account"),
        }
        dispatch(pushToast(toast))
        if (commGroupID) {
          dispatch(fetchConversationThread(id, commGroupID))
        }
      },
      onError: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "error",
          message: translate("Failed to remove label from account"),
        }
        dispatch(pushToast(toast))
      },
    }))
  }

  const renderLabelPill = (
    label: ConversationData["Labels"][0],
  ) => {
    // Extract label color
    const labelColor = lblColors.find((lc) => lc.label === label.label)

    // Check to see if it was found
    if (labelColor) {
      // Return the label with correct color
      return (
        <Pill
          size="small"
          id={ label.id }
          className={ `cell-pill-color${ labelColor.colorIndex }` }
          label={ label.label }
          onDelete={ () => { deleteLabelFromAccount(label.id) } }
        />
      )
    }

    // Retrun label with default color
    return (
      <Pill
        size="small"
        id={ label.id }
        className="cell-pill-color0"
        label={ label.label }
        onDelete={ () => { deleteLabelFromAccount(label.id) } }
      />
    )
  }

  return (
    <div
      key={ uuid }
      className="cp_ct-thread single-message upload"
    >
      <div className="cp_ct-thread-message-content">
        <div className="cp_ct-thread-message-content-top">
          <Avatar size="sm" src={ imageUrl } className="cp_ct-thread-avatar">
            { fromName ? fromName.charAt(0) : "" }
          </Avatar>

          <p className="cp_ct-thread-handle-name">
            { fromName }
          </p>
          <p className="cp_ct-thread-message-address">{ fromEmail }</p>
          <p className="cp_ct-thread-date">{ formatTimestampToCustomDate(created) }</p>
        </div>
        { labels.length > 0 && (
          <div id="account-label-pills" className="account-label-pills-style">
            { labels.map((label) => renderLabelPill(label)) }
          </div>
        ) }
        <div className="cp_ct-thread-content">
          <h2 className="cp_ct-thread-content-heading">
            { `${ fromName } ${ translate("Uploaded Content via the Content Drop Zone") }` }
          </h2>
          <div className="cp_ct-thread-content-top">
            { hasAttachments && (
            <>
              <Attachment className="email-attachment-icon attachment" />
              <p>{ translate("attachments") }</p>
            </>
            ) }
            { hasSnippet && (
            <>
              <CheckCircleOutlineOutlined className="email-attachment-icon check" />
              <p>{ translate("Caption Submitted") }</p>
            </>
            ) }
          </div>
          { hasAttachments && (
            <div className={ `cp_ct-thread-attachments ${ hasSnippet ? "has-snippets" : "" }` }>
              { message.attachments.map((attachment) => (
                <Link
                  key={ attachment.id }
                  href={ attachment.content.downloadUrl }
                  download={ attachment.name }
                  className="cp_ct-thread-attachment"
                >
                  { (attachment.thumbnail && attachment.thumbnail.url)
                    ? (
                      <CardMedia
                        component="img"
                        height="40"
                        image={ attachment.thumbnail.url }
                        alt={ attachment.name }
                      />
                    )
                    : (
                      <div className="cp_ct-thread-attachment-icon-placeholder">
                        <InsertPhotoOutlined className="cp_ct-thread-attachment-icon-download" />
                      </div>
                    )
                  }
                </Link>
              )) }
            </div>
          ) }
          { hasSnippet && (
            <div className="cp_ct-thread-snippets">
              { message.snippets.map((snippet) => (
                <div key={ snippet.id } className="cp_ct-thread-snippet">
                  <p className="cp_ct-thread-snippet-text">{ snippet.text }</p>
                </div>
              )) }
            </div>
          ) }
        </div>
      </div>
    </div>
  )
}
