// Miscellaneous Constants
import * as Type from "./types"
import * as GraphQL from "../graphql"

export const FEATURE_FLAGS: Record<Type.FeatureFlag, boolean> = {
  "customer-management": process.env.NODE_ENV === "development",
}

export const USERNAME_MAX_LENGTH = 255
export const PASSWORD_MAX_LENGTH = 255

export const TOAST_DEFAULT_TIMEOUT_MS = 5000

export const BILLION = 1000000000
export const MILLION = 1000000
export const THOUSAND = 1000

export const BILLION_SUFFIX = "b"
export const MILLION_SUFFIX = "m"
export const THOUSAND_SUFFIX = "k"

export const DEFAULT_DEBOUNCE_WAIT = 500
export const DEFAULT_MIN_AGE = 13
export const DEFAULT_MAX_AGE = 65
// eslint-disable-next-line max-len
export const DEFAULT_PROFILE_IMAGE = "https://social-hoarder-production.s3.us-west-2.amazonaws.com/media/541A57AFA3B36847256EA9472DCB2A73FEB14E490304CA7705707F3E78A8D4B1.png"
export const HREF_CUSTOMER_SUPPORT = "https://support.influential.co/hc/en-us/requests/new"
export const HREF_AI_SEARCH_GUIDE = "https://support.influential.co/hc/en-us/categories/360001577731-Search"

export const NSFW_CONTENT = "content"
export const NSFW_ACCOUNT = "account"

export const MEDIA_UPLOAD_PATH = "/api/media/upload"
export const UPLOAD_TYPE = "media"
export const MEDIA_AVATAR_UPLOAD_PATH = "/api/media/avatar"
export const COMM_FILE_ATTACHMENT_PATH = "/api/file/attachment"
export const ATTACHMENT_TYPE = "file"
export const UPLOAD_AVATAR_TYPE = "avatar"
export const SUGGESTED_POSTS_QTY = 9
export const KEYWORDS_POSTS_QTY = 4

// Date Constants
export const LONGFORM_DATE = "D MMM YYYY"
export const LONGFORM_DATE_TIME = "D MMM YYYY @ h:mm A"
export const MARIA_DATE_FORMAT = "YYYY-MM-DD"
export const MONTH_DAY_ABBR_FORMAT = "MMM D, YYYY"
export const INPUT_DATE_FORMAT = "MM/DD/YYYY"

// Communication Group Constants
export const COMM_GROUP_LABELS = [ "contracting", "opted-in", "garnering content" ]

// Color Constants
export const EngagementMapColors = {
  light: {
    maxColor: "#1866FE",
    minColor: "rgba(223, 225, 229, 0.6)",
    maxStop: "#B8E55C",
  },
  dark: {
    maxColor: "#66C4FF",
    minColor: "rgba(255, 255, 255, 0.2)",
    maxStop: "#88C38D",
  },
}

export const FollowersOverTimeChartColors = {
  light: "#FFC58F",
  dark: "#FFC58F",
}

export const CHART_BASE_COLORS = {
  light: [
    "#A39CF7",
    "#5265F3",
    "#B1C6F8",
    "#B2E4DE",
    "#9DEAED",
    "#FFBEF4",
  ],
  dark: [
    "#A39CF7",
    "#5265F3",
    "#B1C6F8",
    "#B2E4DE",
    "#D8F0F1",
    "#F2D5ED",
  ],
}

export const CHART_BORDER_COLORS = {
  light: "#FFFFFF",
  dark: "#323545",
}

export const BASIC_CHART_BASE_COLOR = "#ACC7FD"
export const GAUGE_CHART_BASE_COLOR = "rgba(223, 225, 229, 0.4)"

export const LOCATION_MAP_COLOR_STOPS: [number, string][] = [
  [ 0, "#fbf7e9" ],
  [ 0.2, "#c3ecb2" ],
  [ 1, "#617558" ],
]

export const LOCATION_MAP_COLORS = {
  stops: LOCATION_MAP_COLOR_STOPS,
  backgroundColor: "#aadaff",
  statesHover: "#f6cf65",
  tooltipBorder: "#e6e6e6",
}

export const LOCATION_CITY_MAP_COLORS = {
  backgroundColor: "#aadaff",
  tooltipBorder: "#e6e6e6",
  marker: {
    lineColor: "#ef3a38",
    fillColor: "#ef3a38",
  },
}

export const HISTOGRAM_COLORS = {
  selected: "#f6cf65",
  default: "#aadaff",
}

export const INSIGHTS_DISPLAY_TOGGLES = {
  ENGAGEMENT_RATE_OVER_TIME: "ToggleInsightsEngagementRateOverTime",
  ENGAGEMENT_RATE_BY_POST_TYPE: "ToggleInsightsEngagementsByPostType",
  ENGAGEMENT_RATE_BY_POST_TYPE_REEL: "ToggleInsightsEngagementsByPostTypeReel",
  ENGAGEMENTS_HEAT_MAP: "ToggleInsightsEngagementsHeatMap",
  RECENT_MEDIA_AI_IMAGE_ANALYSIS: "ToggleInsightsRecentMediaAIImageAnalysis",
  BRANDS_MENTIONED_BRANDS_WORKED_WITH: "ToggleInsightsBrandsMentionedBrandsWorkedWith",
  RECENT_MEDIA_STORIES: "ToggleInsightsRecentMediaStories",
  BRANDS_DETECTED: "ToggleInsightsBrandsDetected",
  PI_TRAITS: "ToggleInsightsPITraits",
}

export const AUDIENCE_DISPLAY_TOGGLES = {
  AFFINITIES: "ToggleAffinities",
  AGE_RANGE: "ToggleAgeRange",
  AUTHENTICITY: "ToggleAudienceAuthenticityScore",
  BASELINE: "ToggleAudienceBaseline",
  CITIES: "ToggleCities",
  COUNTRIES: "ToggleCountries",
  EDUCATION_LEVEL: "ToggleEducationLevel",
  EMPLOYERS: "ToggleEmployers",
  ETHNICITIES: "ToggleEthnicities",
  FAMILY_STATUS: "ToggleFamilyStatus",
  FOLLOWERS_OVER_TIME: "ToggleFollowersOverTime",
  GENDER: "ToggleGender",
  INCOME_RANGE: "ToggleIncomeRange",
  INDUSTRIES: "ToggleIndustries",
  LANGUAGES: "ToggleLanguages",
  OCCUPATIONS: "ToggleOccupations",
  QUALITY: "ToggleAudienceQuality",
  RELIGIONS: "ToggleReligions",
  STATES: "ToggleStates",
  UNIVERSITIES: "ToggleUniversities",
}

export const CONTENT_DISPLAY_TOGGLES = {
  TOP_POSTS: "ToggleTopPosts",
  KEYWORDS: "ToggleKeywords",
  POST_IMPRESSIONS: "TogglePostImpressions",
}

export const LIST_DISPLAY_TOGGLES = {
  AUDIENCE_AUTHENTICITY: "ToggleAudienceAuthenticityScore",
  AUDIENCE_QUALITY: "ToggleAudienceQualityScore",
  AD_COUNCIL_SCORE: "ToggleAdCouncilScore",
  ENG_SCORE: "ToggleEngagementScore",
  ENG_RATE: "ToggleEngagementRate",
  ISCORE: "ToggleIScore",
  DEMOGRAPHIC_SCORE: "ToggleDemographicScore",
  NO_SCORE: "ToggleNoScore",
  TTCM_ENGAGEMENT_RATE: "ToggleTTCMEngagementRate",
}

export const DELIVERABLE_CREATIVE_THEMES: Type.DeliverableCreativeThemes[] = [
  "Lifestyle",
  "Comedy",
  "Food",
  "Fashion",
  "Sports",
  "DIY",
  "Music",
]

export const DELIVERABLE_CREATIVE_THEME_SUBCATEGORIES: {[key in Type.DeliverableCreativeThemes]: string[]} = {
  Lifestyle: [ "Day In the Life", "Tutorial", "Unboxing", "Haul", "Vlog", "__ with me" ],
  Comedy: [ "Skit", "Monologue", "Prank", "Interview" ],
  Food: [ "Recipe Tutorial", "Haul", "Challenge", "Review", "Food Combo" ],
  Fashion: [
    "Get ready with me", "Unboxing", "Haul", "Try-on haul", "Lookbook",
    "This, Not That", "Outfit Grid", "Outfit Transition", "How it fits",
  ],
  Sports: [ "Trick Shot", "Impersonation", "Reenactment", "Watch Party" ],
  DIY: [ "How To", "Before & After", "Hacks" ],
  Music: [ "Jingle", "Duet/Remix", "Dance", "Challenge" ],
}

export const CAMPAIGN_REPORTING_TEMPLATES = {
  AWARENESS: "awareness",
  NATIVE: "native",
  CONVERSION: "conversion",
}

export const CAMPAIGN_REPORTING_VISUALS = {
  MEDIA_POSTS_NATIVE: "media_posts_native",
  SHOW_POSTS: "show_posts",
  MEDIA_POST_PAID: "media_posts_paid",
}

export const CAMPAIGN_REPORTING_TRENDS_GROUPS = {
  TRENDS_BY_INFLUENCER: "trends_by_influencer",
  TRENDS_BY_DELIVERABLE: "trends_by_deliverable",
  TRENDS_BY_OBJECTIVE: "trends_by_objective",
  TRENDS_BY_AUDIENCE_TARGETING: "trends_by_audience_targeting",
  TRENDS_BY_CREATIVE_THEME: "trends_by_creative_theme",
}

export const CAMPAIGN_REPORTING_SECTIONS = {
  METRICS_CONVERSION: "metrics_conversion",
  METRICS_NATIVE: "metrics_native",
  METRICS_PAID: "metrics_paid",
}

export const CAMPAIGN_REPORTING_SECTION_ORDER_BY_TITLE = [
  "Campaign Overview Instagram (Native)",
  "Campaign Overview Facebook (Native)",
  "Campaign Overview Meta (Paid)",
  "Campaign Overview Meta (Conversion)",
  "Campaign Overview TikTok (Native)",
  "Campaign Overview TikTok (Paid)",
  "Campaign Overview TikTok (Conversion)",
  "Campaign Overview YouTube (Native)",
  "Campaign Overview YouTube (Paid)",
  "Native Posts Instagram",
  "Native Posts Facebook",
  "Native Posts TikTok",
  "Native Posts YouTube",
  "Instagram Native Stories",
  "Meta Optimized Paid Media",
  "TikTok Optimized Paid Media",
  "YouTube Optimized Paid Media",
  "Trends",
]

export const NETWORK_REPORTING_ORDER = [
  "Instagram",
  "Facebook",
  "Meta",
  "TikTok",
  "YouTube",
]

export const scoreModalTitles = {
  ENGAGEMENT: "Engagement Score",
  AUDIENCE_AUTHENTICITY: "Audience Authenticity",
  AUDIENCE_QUALITY: "Audience Quality Score",
  DEMOGRAPHIC: "Demographic Score",
  INFLUENCER: "Influencer",
  I_SCORE: "I-Score",
  PI_TRAIT: "Pi Trait",
  ADD_COUNCIL: "Ad Council Score",
  Unknown: "Unknown ScoreType",
}

export const scoreModalTypes = {
  AUDIENCE_QUALITY: "AUDIENCE_QUALITY",
  DEMOGRAPHIC: "DEMOGRAPHIC",
  ENGAGEMENT: "ENGAGEMENT",
  INFLUENCER: "INFLUENCER",
  I_SCORE: "I_SCORE",
  PI_TRAIT: "PI_TRAIT",
  ADD_COUNCIL: "ADD_COUNCIL",
}

export const SCOREBREAKDOWN = {
  Posting_Frequency: "Posting Frequency",
}

export const CAMPAIGN_REPORTING_METRICS = {
  CLICK_THROUGH_RATE: "click_through_rate",
  COMMENTS: "comments",
  CPA: "cpa",
  ENGAGEMENT_RATE: "engagement_rate",
  ENGAGEMENT_RATE__STATIC: "engagement_rate__static",
  LIKES: "likes",
  LINK_CLICKS: "link_clicks",
  REACH: "reach",
  SAVES: "saves",
  SHARES: "shares",
  STORY_CLICK_THROUGH_RATE: "story_click_through_rate",
  STORY_LIKES: "story_likes",
  STORY_LINK_CLICKS: "story_link_clicks",
  STORY_REPLIES: "story_replies",
  STORY_SHARES: "story_shares",
  STORY_STICKER_TAPS: "story_sticker_taps",
  STORY_VIEWS: "story_views",
  TOTAL_ENGAGEMENTS: "total_engagements",
  TOTAL_IMPRESSIONS: "total_impressions",
  TOTAL_IMPRESSIONS__GROSS: "total_impressions__gross",
  TOTAL_IMPRESSIONS__TRUE: "total_impressions__true",
  VIDEO_COMPLETION_RATE: "video_completion_rate",
  VIDEO_VIEWS: "video_views",
  VIDEO_VIEWS__NATIVE: "video_views__native",
  VIDEO_VIEWS__PAID: "video_views__paid",
  VIDEO_VIEWS__PAID_2_SEC: "video_views__paid_2_sec",
  VIDEO_VIEWS__PAID_30_SEC: "video_views__paid_30_sec",
  VIDEO_VIEWS__PAID_3_SEC: "video_views__paid_3_sec",
  VIDEO_VIEWS__PAID_6_SEC: "video_views__paid_6_sec",
  VIEW_ENGAGEMENT_RATE: "view_engagement_rate",
  VIEW_THROUGH_RATE__PAID_2_SEC: "view_through_rate__paid_2_sec",
  VIEW_THROUGH_RATE__PAID_30_SEC: "view_through_rate__paid_30_sec",
  VIEW_THROUGH_RATE__PAID_3_SEC: "view_through_rate__paid_3_sec",
  VIEW_THROUGH_RATE__PAID_6_SEC: "view_through_rate__paid_6_sec",
}

export const FieldCharacterMax = {
  SHORT_MAX: "32",
  STANDARD_MAX: "64",
}

export const DEFAULT_CONVERSATION_QUERY = {
  STARTS_WITH: undefined,
  PAGE: 1,
  LIMIT: 16,
  FILTER: "inbox",
  BATCH_ID: null,
}

export const SENT_CONVERSATION_QUERY = {
  STARTS_WITH: undefined,
  PAGE: 1,
  LIMIT: 16,
  FILTER: "sent",
  BATCH_ID: null,
}

export const COMMUNICATION_DRAFT_DATA_EMPTY: Type.CommunicationDraftData = {
  mode: GraphQL.CommunicationGroupDraftType.Single,
  toList: [],
  ccList: [],
  enableUploadLink: false,
  subject: "",
  template: null,
  richTextEditorState: {
    attachmentHistory: [],
    attachementHistoryIndex: 0,
    attachments: [],
    uploadedfiles: [],
    content: "",
  },
}

export const EMPTY_STATE_BOX_HEIGHT = 130 // as per Figma design

/**
 * This is the elm host name that is used to redirect to the elm platform that is hosted in the same environment.
 * If you are on localhost this will point to the dev environment.
 */
export const ELM_HOST_NAME = window.location.hostname !== "localhost"
  ? window.location.hostname.replace("data-cloud-campaign-portal", "platform").replace("radius", "platform")
  : "platform.dev.influentialops.com"

export const SUPPORT_PAGE_URL = "https://support.influential.co/hc/en-us"

/**
 * CommsFolder:  These are the left name items that can be selected to change the body content of communications
 * page.
 */
export type CommsFolder = "Inbox" | "Sent" | "Batch" | "Drafts" | "GroupAccounts" | "GroupAccountSearch"

export const ACCEPTED_FILE_TYPES = "image/png, image/jpeg, .webm, .mp4, .mov, application/pdf"

export const ReadableNetwork = {
  [GraphQL.Network.Facebook]: "Facebook",
  [GraphQL.Network.Instagram]: "Instagram",
  [GraphQL.Network.Snapchat]: "Snapchat",
  [GraphQL.Network.Tiktok]: "TikTok",
  [GraphQL.Network.Twitter]: "Twitter",
  [GraphQL.Network.Youtube]: "YouTube",
  NA: "No Network Selected",
}

export const INFLUENTIAL_PRIVACY_POLICY_URL = "https://platform.rc2.influentialops.com/influential-team/user/customer"
// eslint-disable-next-line max-len
export const TIKTOK_OAUTH_URL = "https://business-api.tiktok.com/marketing_api/auth?app_id=6942591692943917058&redirect_uri=https%3A%2F%2Ffrontdesk.influential.co%2Foauth%2Ftiktok%2Fcallback&rid=2l8bcsgjqds&state=prod_3r5NHsZfwRnLhe5Q1VnzzStYKmSuhNVvSKJSdooLQkwV"
export const INSTAGRAM_OAUTH_URL = ""
export const YOUTUBE_OAUTH_URL = ""
