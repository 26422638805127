import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import Affinities from "../../Audience/Affinities"
import "../../Audience/index.sass"

export default function AffinitiesWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.Affinities" })
  const {
    list, selectedListSocialAccount, audience,
  } = useSelector(({ publicList }) => publicList)
  if (audience === "loading" || audience === "init" || list === "loading" || list === "init" || !selectedListSocialAccount) {
    return (
      <>
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Affinities") }
        </h6>
        <LoadingIndicatorCard />
      </>
    )
  }
  if (audience.status === "error" || list.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <Affinities
      audienceDemographics={ audience.payload.publicSocialAccount.audienceDemographics }
      socialProfile={ selectedListSocialAccount?.socialAccount }
    />
  )
}
