import React, { JSX, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import * as API from "../../util/apiClient"
import * as SearchHelper from "../../state/searchAISlice/helper"
import { Scope, SearchAIType } from "../../util/types"
import {
  setSearchInput,
  fetchContentSearchResults,
  fetchAccountSearchResults,
} from "../../state/searchAISlice"
import { useDispatch, useSelector } from "../../state/hooks"

/* NOTE:
**
** Why does this component exist? In short, to outsource any search input
** logic from the Search Page functional component. The justification
** is twofold:
**
** 1. Ensure that the initial search isn't kicked off until user details
** are loaded
**
** 2. Ensure there are no references to search input within the search slice
** that might cause the virtual DOM for the entire search page to be rerendered
** when something within said input changes
*/

export default function SearchInitiator(): JSX.Element {
  const dispatch = useDispatch()
  const [ searchParams ] = useSearchParams()
  const searchInput = useSelector(({ searchAI }) => searchAI.searchAIInput)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const user = useSelector(({ user: userSlice }) => userSlice.user)

  // Upon mounting the search page, parse the query parameters in the URL
  // and apply them to the search input state.
  useEffect(() => {
    // Do nothing if user hasn't loaded yet.
    if (!API.isSuccess(user) || scopes.length === 0) return

    const searchInputQueryString = searchParams.get(SearchHelper.QUERY_PARAM_Q)

    const profileInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_INFLUENCER,
    )

    const audienceInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_AUDIENCE,
    )

    const contentInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_CONTENT,
    )

    let newInput = SearchHelper.initialSearchState()

    // Base Query Parameters
    if (searchInputQueryString != null) {
      newInput = {
        ...searchInput,
        ...SearchHelper.baseQueryToSearchInput(searchInputQueryString),
      }
    }

    // Profile Query Parameters
    if (profileInputQueryString != null) {
      const newProfileParams = {
        ...searchInput.profileParams,
        ...SearchHelper.baseQueryToProfileInput(profileInputQueryString),
      }

      newInput.profileParams = newProfileParams
    }

    // Content Query Parameters
    if (contentInputQueryString != null) {
      const newContentParams = {
        ...searchInput.contentParams,
        ...SearchHelper.baseQueryToContentInput(contentInputQueryString),
      }

      newInput.contentParams = newContentParams
    }

    // Audience Query Parameters
    if (audienceInputQueryString != null) {
      const newAudienceParams = {
        ...searchInput.audienceParams,
        ...SearchHelper.baseQueryToAudienceInput(audienceInputQueryString),
      }
      newInput.audienceParams = newAudienceParams
    }

    // If user is not scoped for Account search, default to Content search
    if (
      !scopes.includes(Scope.FEATURE_SEARCH_INFLUENCER)
      && !scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE)
      && searchInput.type === SearchAIType.Accounts
    ) {
      newInput.type = SearchAIType.Accounts
    }

    // Only dispatch new search input if some change in input has occurred
    if (
      searchInputQueryString != null
      || profileInputQueryString != null
      || contentInputQueryString != null
      || audienceInputQueryString != null
      || searchInput.type !== SearchAIType.Accounts
    ) {
      dispatch(setSearchInput(newInput))
    }

    // Fetch search results if the user navigates back to the search page
    window.onpopstate = () => {
      if (!window.location.pathname.includes("/search-ai")) return
      if (searchInput.type === SearchAIType.Accounts) {
        dispatch(fetchAccountSearchResults())
      } else {
        dispatch(fetchContentSearchResults())
      }
    }
  }, [])

  return <></>
}
