import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Box, Divider } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useSelector } from "../../state/hooks"
import LoadingIndicator from "../LoadingIndicator"
import "./style.sass"
import OverviewCard from "./OverviewCard"
import ListOverviewTable from "./ListOverviewTable"
import { SuggestionListMode } from "../../graphql"
import ErrorHandler from "../ErrorHandler"

export function PublicListOverview(): React.JSX.Element {
  const {
    list, leftPanelStatus,
  } = useSelector(({ publicList: publicListSlice }) => publicListSlice)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.PublicListOverview" })

  const navigate = useNavigate()

  const [ numberOfApproved, totalAccounts ] = useMemo(() => {
    let approvedCount = 0
    let accountCount = 0
    if (list === "init" || list === "loading" || list.status === "error") return [ approvedCount, accountCount ]
    list.payload.publicSuggestionListByCode.suggestionListCategories.forEach((category) => {
      category.suggestionListSocialAccounts.forEach((suggestionListSocialAccount) => {
        if (suggestionListSocialAccount.approvalStatus?.value === true) approvedCount += 1
        if (suggestionListSocialAccount.visible) accountCount += 1
      })
    })
    return [ approvedCount, accountCount ]
  }, [ list ])

  const panelClass = useMemo(() => [ "cp_public-list-overview", leftPanelStatus ], [ leftPanelStatus ])

  if (list === "loading" || list === "init") {
    return (
      <div className={ panelClass.join(" ") }>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
      </div>
    )
  }

  if (list.status === "error") {
    return <ErrorHandler />
  }

  const onReturnClick = (code: string) => {
    navigate(`/public/group/${ code }`)
  }

  const {
    name,
    network,
    reach,
    minAge,
    maxAge,
    sexes,
    description,
    suggestionListCategories,
    avatar,
    suggestionListMode,
    toggles,
    suggestionListGroup,
  } = list.payload.publicSuggestionListByCode

  return (
    <div className={ panelClass.join(" ") }>
      { suggestionListGroup?.code && (
      <Box
        id="cp_public-list-return-to-group-button"
        className="cp_public-list-return-to-group-button"
        onClick={ () => onReturnClick(suggestionListGroup.code) }
      >
        { `${ translate("< Back to") } ${ suggestionListGroup.name }` }
      </Box>
      ) }
      <OverviewCard
        className="cp_public-list-overview-card"
        listName={ name }
        numberOfAccounts={ totalAccounts }
        platform={ network }
        reach={ parseInt(reach, 10) }
        sexes={ sexes }
        minAge={ minAge }
        maxAge={ maxAge }
        description={ description }
        avatarSrc={ avatar?.url.address }
        avatarSize="lg"
        isCampaignMode={ suggestionListMode === SuggestionListMode.Campaign }
        approved={ numberOfApproved }
      />
      { suggestionListCategories.filter((category) => category.suggestionListSocialAccounts.length > 0).map((category) => (
        <div className="cp_public-list-overview-card-section" key={ category.id }>
          <h4>{ category.name }</h4>
          <Divider />
          <ListOverviewTable suggestionListSocialAccounts={ category.suggestionListSocialAccounts } toggles={ toggles } />
        </div>
      )) }
    </div>
  )
}

export default PublicListOverview
