import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Tabs, { TabLabelProps } from "../Tabs"
import { useSelector } from "../../state/hooks"
import { Scope } from "../../util/types"
import ChangePasswordSettings from "./ChangePassword"
import UserDetails from "./UserDetails"
import OAuthSettingsTab from "./OAuth"

// eslint-disable-next-line no-shadow
enum UserSettingsTabsEnum {
  USER_DETAILS = "details",
  CHANGE_PASSWORD = "password",
  OAUTH = "oauth",
}

interface UserSettingsTabsProps extends TabLabelProps {
  path: UserSettingsTabsEnum
}

export default function UserSettingsTabs() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.UserSettingsTabs" })
  const navigate = useNavigate()
  const { tabPath } = useParams()
  const { scopes } = useSelector(({ user }) => user)

  const tabs: UserSettingsTabsProps[] = React.useMemo(() => {
    const t = [
      { label: translate("User Details"), path: UserSettingsTabsEnum.USER_DETAILS },
      { label: translate("Change Password"), path: UserSettingsTabsEnum.CHANGE_PASSWORD },
    ]

    // Check to see if tab is within scope
    if (scopes.includes(Scope.ADMIN)) t.push({ label: translate("OAuth"), path: UserSettingsTabsEnum.OAUTH })

    return t
  }, [ scopes ])

  const setTab = (tabIndex: number) => {
    const newTabPath = tabs[tabIndex].path
    const pathPrefix = tabPath ? "../" : ""
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  const tabContent = React.useMemo(() => {
    switch (tabPath) {
      case UserSettingsTabsEnum.USER_DETAILS:
      case undefined:
        return null
      case UserSettingsTabsEnum.CHANGE_PASSWORD:
        return null
      case UserSettingsTabsEnum.OAUTH:
        if (scopes.includes(Scope.ADMIN)) {
          return null
        }
        return <p>User does not have access to this tab!</p>
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }, [ tabPath, tabs.length, scopes ])

  const tabPage = React.useMemo(() => {
    switch (tabPath) {
      case UserSettingsTabsEnum.USER_DETAILS:
      case undefined:
        return <UserDetails />
      case UserSettingsTabsEnum.CHANGE_PASSWORD:
        return <ChangePasswordSettings />
      case UserSettingsTabsEnum.OAUTH:
        if (scopes.includes(Scope.ADMIN)) {
          return <OAuthSettingsTab />
        }
        return null
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }, [ tabPath, tabs.length, scopes ])

  const tabIndex = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === tabPath)
    const index = foundTab ? tabs.indexOf(foundTab) : 0
    return index
  }, [ tabPath, tabs.length, scopes ])

  return (
    <div className="cp_component_user-settings-tabs_tabs-container">
      <Tabs
        handleChange={ setTab }
        tabs={ tabs }
        defaultValue={ tabIndex }
        controlledValue={ tabIndex }
        controls={ (
          <div className="tab-controls" />
        ) }
      />
      <div className="cp_component_user-settings-tabs_tabs-content">
        { tabContent }
      </div>
      { tabPage }
    </div>
  )
}
