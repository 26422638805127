import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { createSlice } from "@reduxjs/toolkit"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

// Modal Add To Comms Slice Interface and Initial State
export interface ModalAddToCommunicationsState {
  open: boolean
  socialAccountID?: string,
  socialAccountNetwork?: GraphQL.Network,
  comms: Status<GraphQL.SearchCommunicationGroupsQuery>
  commsForAccount: Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>
  selectedCommIDs: string[],
}

const initialState: ModalAddToCommunicationsState = {
  open: false,
  comms: "init",
  commsForAccount: "init",
  selectedCommIDs: [],
}

// Profile Slice
export const modalAddToCommunications = createSlice({
  name: "ModalAddToCommunications",
  initialState,
  reducers: {
    openModalAddToCommunications: (state, action: PayloadAction<{
      socialAccountId: string,
      socialAccountNetwork: GraphQL.Network
    }>) => ({
      ...state,
      open: true,
      socialAccountID: action.payload.socialAccountId,
      socialAccountNetwork: action.payload.socialAccountNetwork,
      comms: "init",
      commsForAccount: "init",
      selectedCommIDs: [],
    }),
    closeModalAddToCommunications: (state) => ({
      ...state,
      open: false,
    }),
    setCommsForProfile: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>>) => ({
      ...state,
      commsForProfile: action.payload,
    }),
    setComms: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsQuery>>) => ({
      ...state,
      comms: action.payload,
    }),
    setCommsForAccount: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>>) => ({
      ...state,
      commsForAccount: action.payload,
    }),
    setSelectedCommIDs: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedCommIDs: action.payload,
    }),
    setSelectedSocialAccountID: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedSocialAccountID: action.payload,
    }),
    setSelectedSocialAccountNetwork: (
      state,
      action: PayloadAction<GraphQL.Network>,
    ) => ({
      ...state,
      selectedSocialAccountNetwork: action.payload,
    }),
    toggleSelectedComm: (
      state,
      action: PayloadAction<string>,
    ) => {
      const indexOfID = state.selectedCommIDs.indexOf(action.payload)
      // Remove if already selected
      if (indexOfID !== -1) {
        const newIDs = [ ...state.selectedCommIDs ]
        newIDs.splice(indexOfID, 1)
        return {
          ...state,
          selectedCommIDs: newIDs,
        }
      }
      // Only add to if not selected and length is < 10
      if (state.selectedCommIDs.length < 10) {
        return {
          ...state,
          selectedCommIDs: [ ...state.selectedCommIDs, action.payload ],
        }
      }
      return state
    },
  },
})

export const {
  setSelectedSocialAccountID,
  setSelectedSocialAccountNetwork,
  setCommsForProfile,
  setComms,
  setCommsForAccount,
  closeModalAddToCommunications,
  openModalAddToCommunications,
  setSelectedCommIDs,
  toggleSelectedComm,
} = modalAddToCommunications.actions
export default modalAddToCommunications.reducer

// Profile Slice Thunks
export const getComms = (searchInput: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setComms("loading"))

  const commsResults = await API.query<
      GraphQL.SearchCommunicationGroupsQuery,
      GraphQL.SearchCommunicationGroupsQueryVariables
    >(GraphQL.SearchCommunicationGroupsDocument, {
      startsWith: searchInput,
    })
  dispatch(setComms(commsResults))
}

export const getCommsForAccount = (
  networkAccountId: string,
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCommsForAccount("loading"))
  if (!networkFilter) {
    dispatch(setCommsForAccount({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCommunicationsByNetworkAccountIds({
      networkAccountIds: networkAccountId,
      networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setCommsForAccount(results))
  }
}

export const createCommunicationGroupNetworkAccountsAddModalMutation = (params: {
  variables: {
    socialAccountID: string,
    commIDs: string[]
  },
  onSuccess: () => void,
  onError: () => void
}) => async () => {
  const results = await Promise.all(params.variables.commIDs.map((id) => API.mutate<
    GraphQL.CreateCommunicationGroupNetworkAccountsAddModalMutation,
    GraphQL.CreateCommunicationGroupNetworkAccountsAddModalMutationVariables
    >(GraphQL.CreateCommunicationGroupNetworkAccountsAddModalDocument, {
      communicationGroupId: id, networkAccountIds: [ params.variables.socialAccountID ],
    })))
  if (results.some((result) => API.isError(result))) {
    params.onError()
  } else {
    params.onSuccess()
  }
}
