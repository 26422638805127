import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  UnixTimestamp: { input: any; output: any; }
};

export enum Age_Groups {
  Age_17AndUnder = 'age_17_and_under',
  Age_18To_20 = 'age_18_to_20',
  Age_21To_24 = 'age_21_to_24',
  Age_25To_29 = 'age_25_to_29',
  Age_30To_34 = 'age_30_to_34',
  Age_35To_44 = 'age_35_to_44',
  Age_45To_54 = 'age_45_to_54',
  Age_55To_64 = 'age_55_to_64',
  Age_65AndOver = 'age_65_and_over'
}

export type AudienceFiltersInput = {
  ageGroups: Array<Age_Groups>;
  ageMinimum?: InputMaybe<Scalars['Float']['input']>;
  ethnicityAfricanAmericanMinimum?: InputMaybe<Scalars['Float']['input']>;
  ethnicityAsianPacificIslanderMinimum?: InputMaybe<Scalars['Float']['input']>;
  ethnicityHispanicLatinoMinimum?: InputMaybe<Scalars['Float']['input']>;
  ethnicityWhiteCaucasianMinimum?: InputMaybe<Scalars['Float']['input']>;
  familyMarriedMinimum?: InputMaybe<Scalars['Float']['input']>;
  familyParentsMinimum?: InputMaybe<Scalars['Float']['input']>;
  familySingleMinimum?: InputMaybe<Scalars['Float']['input']>;
  genderFemaleMinimum?: InputMaybe<Scalars['Float']['input']>;
  genderMaleMinimum?: InputMaybe<Scalars['Float']['input']>;
  income: Array<Scalars['String']['input']>;
  locationMinimum: Array<MinimumLocationMatch>;
};

export type AudienceLocation = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: AudienceLocationType;
};

export enum AudienceLocationType {
  City = 'CITY',
  Country = 'COUNTRY',
  State = 'STATE'
}

export type BrandLogo = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type BrandLogoDetection = {
  __typename?: 'BrandLogoDetection';
  brandLogoDetectionBoundingBoxes: Array<BrandLogoDetectionBoundingBox>;
  logoCode: Scalars['String']['output'];
  logoDescription: Scalars['String']['output'];
};

export type BrandLogoDetectionBoundingBox = {
  __typename?: 'BrandLogoDetectionBoundingBox';
  confidenceScore: Scalars['Float']['output'];
  heightOffsetRatio: Scalars['String']['output'];
  widthOffsetRatio: Scalars['String']['output'];
  xOffsetRatio: Scalars['String']['output'];
  yOffsetRatio: Scalars['String']['output'];
};

export type ContentFiltersInput = {
  keywords: Array<Scalars['String']['input']>;
  minPostEngagement?: InputMaybe<Scalars['Float']['input']>;
};

export type EngagementPostReach = {
  __typename?: 'EngagementPostReach';
  ratioPercentile25: Scalars['Float']['output'];
  ratioPercentile75: Scalars['Float']['output'];
  value: Scalars['Int']['output'];
};

export type ImageAnalysisConfidence = {
  __typename?: 'ImageAnalysisConfidence';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

/**
 * IncomeBrackets enums start with an underscore because an enum
 * cannot start with a number.
 */
export enum IncomeBrackets {
  '10000_19999' = '_10000_19999',
  '20000_29999' = '_20000_29999',
  '30000_39999' = '_30000_39999',
  '40000_49999' = '_40000_49999',
  '50000_74999' = '_50000_74999',
  '75000_100000' = '_75000_100000',
  '100000Above' = '_100000_ABOVE',
  Under_10000 = '_UNDER_10000'
}

export type MinimumLocationMatch = {
  location: AudienceLocation;
  match: Scalars['Float']['input'];
};

export enum Network {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Snapchat = 'SNAPCHAT',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE'
}

export type PersonalityTraitInput = {
  code: Scalars['String']['input'];
  definitionHigh: Scalars['String']['input'];
  definitionLow: Scalars['String']['input'];
  description: Scalars['String']['input'];
  descriptorHigh: Scalars['String']['input'];
  descriptorLow: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  polarity?: InputMaybe<Scalars['String']['input']>;
};

export type Post = {
  __typename?: 'Post';
  avatarUrl: Scalars['String']['output'];
  engagementRate: Scalars['Float']['output'];
  followers: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isNsfw: Scalars['Boolean']['output'];
  network: Network;
  networkAccountId: Scalars['String']['output'];
  permalink: Scalars['String']['output'];
  postComments: Scalars['Int']['output'];
  postContent: Scalars['String']['output'];
  postLikes: Scalars['Int']['output'];
  postMedia: Array<PostMedia>;
  postShares: Scalars['Int']['output'];
  postType: PostType;
  postedTime: Scalars['UnixTimestamp']['output'];
  score: Scalars['Int']['output'];
  sponsored: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type PostContentToken = {
  __typename?: 'PostContentToken';
  highlighted: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export type PostMedia = {
  __typename?: 'PostMedia';
  brandLogoDetections: Array<BrandLogoDetection>;
  imageAnalysis: Array<ImageAnalysisConfidence>;
  mediaType: Scalars['String']['output'];
  mediaUrl: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
};

export enum PostType {
  Carousel = 'CAROUSEL',
  ImagePost = 'IMAGE_POST',
  InFeed = 'IN_FEED',
  LiveVideo = 'LIVE_VIDEO',
  Post = 'POST',
  Reel = 'REEL',
  Short = 'SHORT',
  Story = 'STORY',
  VideoCarousel = 'VIDEO_CAROUSEL',
  VideoClip = 'VIDEO_CLIP',
  VideoPost = 'VIDEO_POST'
}

export type PostedRangeInput = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

export type ProfileFiltersInput = {
  bioKeywords: Array<Scalars['String']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minEngagement?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
};

export enum PublishLocation {
  Ad = 'AD',
  Feed = 'FEED',
  Short = 'SHORT',
  Story = 'STORY',
  Unknown = 'UNKNOWN'
}

export type Query = {
  __typename?: 'Query';
  searchAccounts: SearchAccountsOutput;
  searchPosts: SearchPostsOutput;
};


export type QuerySearchAccountsArgs = {
  input: SearchAccountsInput;
};


export type QuerySearchPostsArgs = {
  input: SearchPostsInput;
};

export enum Religion {
  Christian = 'CHRISTIAN',
  Jewish = 'JEWISH',
  Muslim = 'MUSLIM'
}

export type SearchAccountsInput = {
  audienceParams?: InputMaybe<AudienceFiltersInput>;
  contentParams?: InputMaybe<ContentFiltersInput>;
  networks: Array<Network>;
  profileParams?: InputMaybe<ProfileFiltersInput>;
  prompts: Array<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type SearchAccountsOutput = {
  __typename?: 'SearchAccountsOutput';
  accounts: Array<SocialAccount>;
  total: Scalars['Int']['output'];
};

export enum SearchCampaignFilter {
  Archived = 'ARCHIVED',
  Bookmarked = 'BOOKMARKED',
  MyCampaigns = 'MY_CAMPAIGNS',
  NotBookmarked = 'NOT_BOOKMARKED',
  Standard = 'STANDARD',
  TiktokAll = 'TIKTOK_ALL',
  TiktokPublished = 'TIKTOK_PUBLISHED',
  TiktokUnpublished = 'TIKTOK_UNPUBLISHED'
}

export type SearchPostsInput = {
  audienceParams?: InputMaybe<AudienceFiltersInput>;
  contentParams?: InputMaybe<ContentFiltersInput>;
  networks: Array<Network>;
  profileParams?: InputMaybe<ProfileFiltersInput>;
  prompts: Array<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type SearchPostsOutput = {
  __typename?: 'SearchPostsOutput';
  posts: Array<Post>;
  total: Scalars['Int']['output'];
};

/**
 * This enum represents different data from the Gender enum.
 * Even though they look similar, they serve different purpose.
 * Sex limits input and output to two values only.
 */
export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type SocialAccount = {
  __typename?: 'SocialAccount';
  adCouncilScore: Scalars['Int']['output'];
  audienceAuthenticityScore: Scalars['Int']['output'];
  audienceQualityScore: Scalars['Int']['output'];
  bio?: Maybe<Scalars['String']['output']>;
  engagementRate: Scalars['Float']['output'];
  engagementScore: Scalars['Int']['output'];
  followers: Scalars['Int']['output'];
  fullName: Scalars['String']['output'];
  hasPersonality: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBlockListed: Scalars['Boolean']['output'];
  isNsfw: Scalars['Boolean']['output'];
  isPlaceholder: Scalars['Boolean']['output'];
  lastPostedDate?: Maybe<Scalars['UnixTimestamp']['output']>;
  median_engagement_rate: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  network: Network;
  networkUrl: Scalars['String']['output'];
  oauthed: Scalars['Boolean']['output'];
  profilePictureUrl: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type SearchPostsTableRowFragment = { __typename?: 'Post', id: string, networkAccountId: string, score: number, network: Network, permalink: string, postedTime: any, postType: PostType, postContent: string, engagementRate: number, avatarUrl: string, username: string, followers: number, postShares: number, postLikes: number, isNsfw: boolean, postComments: number, postMedia: Array<{ __typename?: 'PostMedia', mediaType: string, mediaUrl: string, thumbnailUrl: string }> };

export type SearchAccountsTableRowFragment = { __typename?: 'SocialAccount', score: number, id: string, username: string, fullName: string, followers: number, networkUrl: string, isBlockListed: boolean, median_engagement_rate: number, hasPersonality: boolean, bio?: string | null, network: Network, name?: string | null, summary: string, profilePictureUrl: string, engagementRate: number, lastPostedDate?: any | null, engagementScore: number, audienceQualityScore: number, audienceAuthenticityScore: number, adCouncilScore: number, oauthed: boolean, isPlaceholder: boolean, isNsfw: boolean };

export type Search2AccountsQueryVariables = Exact<{
  input: SearchAccountsInput;
}>;


export type Search2AccountsQuery = { __typename?: 'Query', searchAccounts: { __typename?: 'SearchAccountsOutput', total: number, accounts: Array<{ __typename?: 'SocialAccount', score: number, id: string, username: string, fullName: string, followers: number, networkUrl: string, isBlockListed: boolean, median_engagement_rate: number, hasPersonality: boolean, bio?: string | null, network: Network, name?: string | null, summary: string, profilePictureUrl: string, engagementRate: number, lastPostedDate?: any | null, engagementScore: number, audienceQualityScore: number, audienceAuthenticityScore: number, adCouncilScore: number, oauthed: boolean, isPlaceholder: boolean, isNsfw: boolean }> } };

export type Search2PostsQueryVariables = Exact<{
  input: SearchPostsInput;
}>;


export type Search2PostsQuery = { __typename?: 'Query', searchPosts: { __typename?: 'SearchPostsOutput', total: number, posts: Array<{ __typename?: 'Post', id: string, networkAccountId: string, score: number, network: Network, permalink: string, postedTime: any, postType: PostType, postContent: string, engagementRate: number, avatarUrl: string, username: string, followers: number, postShares: number, postLikes: number, isNsfw: boolean, postComments: number, postMedia: Array<{ __typename?: 'PostMedia', mediaType: string, mediaUrl: string, thumbnailUrl: string }> }> } };

export const SearchPostsTableRowFragmentDoc = gql`
    fragment searchPostsTableRow on Post {
  id
  networkAccountId
  score
  network
  permalink
  postedTime
  postType
  postContent
  engagementRate
  avatarUrl
  username
  followers
  permalink
  network
  postShares
  postLikes
  isNsfw
  postComments
  postMedia {
    mediaType
    mediaUrl
    thumbnailUrl
  }
}
    `;
export const SearchAccountsTableRowFragmentDoc = gql`
    fragment searchAccountsTableRow on SocialAccount {
  score
  id
  username
  fullName
  followers
  networkUrl
  isBlockListed
  median_engagement_rate
  hasPersonality
  bio
  network
  name
  summary
  profilePictureUrl
  engagementRate
  engagementRate
  lastPostedDate
  engagementScore
  audienceQualityScore
  audienceAuthenticityScore
  adCouncilScore
  oauthed
  isPlaceholder
  isNsfw
}
    `;
export const Search2AccountsDocument = gql`
    query search2Accounts($input: SearchAccountsInput!) {
  searchAccounts(input: $input) {
    total
    accounts {
      ...searchAccountsTableRow
    }
  }
}
    ${SearchAccountsTableRowFragmentDoc}`;

/**
 * __useSearch2AccountsQuery__
 *
 * To run a query within a React component, call `useSearch2AccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch2AccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch2AccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearch2AccountsQuery(baseOptions: Apollo.QueryHookOptions<Search2AccountsQuery, Search2AccountsQueryVariables> & ({ variables: Search2AccountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search2AccountsQuery, Search2AccountsQueryVariables>(Search2AccountsDocument, options);
      }
export function useSearch2AccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search2AccountsQuery, Search2AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search2AccountsQuery, Search2AccountsQueryVariables>(Search2AccountsDocument, options);
        }
export function useSearch2AccountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Search2AccountsQuery, Search2AccountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Search2AccountsQuery, Search2AccountsQueryVariables>(Search2AccountsDocument, options);
        }
export type Search2AccountsQueryHookResult = ReturnType<typeof useSearch2AccountsQuery>;
export type Search2AccountsLazyQueryHookResult = ReturnType<typeof useSearch2AccountsLazyQuery>;
export type Search2AccountsSuspenseQueryHookResult = ReturnType<typeof useSearch2AccountsSuspenseQuery>;
export type Search2AccountsQueryResult = Apollo.QueryResult<Search2AccountsQuery, Search2AccountsQueryVariables>;
export const Search2PostsDocument = gql`
    query search2Posts($input: SearchPostsInput!) {
  searchPosts(input: $input) {
    total
    posts {
      ...searchPostsTableRow
    }
  }
}
    ${SearchPostsTableRowFragmentDoc}`;

/**
 * __useSearch2PostsQuery__
 *
 * To run a query within a React component, call `useSearch2PostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch2PostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch2PostsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearch2PostsQuery(baseOptions: Apollo.QueryHookOptions<Search2PostsQuery, Search2PostsQueryVariables> & ({ variables: Search2PostsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search2PostsQuery, Search2PostsQueryVariables>(Search2PostsDocument, options);
      }
export function useSearch2PostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search2PostsQuery, Search2PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search2PostsQuery, Search2PostsQueryVariables>(Search2PostsDocument, options);
        }
export function useSearch2PostsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Search2PostsQuery, Search2PostsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Search2PostsQuery, Search2PostsQueryVariables>(Search2PostsDocument, options);
        }
export type Search2PostsQueryHookResult = ReturnType<typeof useSearch2PostsQuery>;
export type Search2PostsLazyQueryHookResult = ReturnType<typeof useSearch2PostsLazyQuery>;
export type Search2PostsSuspenseQueryHookResult = ReturnType<typeof useSearch2PostsSuspenseQuery>;
export type Search2PostsQueryResult = Apollo.QueryResult<Search2PostsQuery, Search2PostsQueryVariables>;