/* eslint-disable max-len */
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import LaunchIcon from "@mui/icons-material/Launch"
import EditIcon from "@mui/icons-material/Edit"

import { useNavigate, useParams } from "react-router-dom"
import Tabs, { TabLabelProps } from "../../Tabs"
import "./style.sass"
import { useDispatch, useSelector } from "../../../state/hooks"
import AccountInsightsWrapper from "./AccountInsightsWrapper"
import AccountInsightsDetailsToggleWrapper from "./AccountInsightDetails/AccountInsightDetailsToggleWrapper"
import {
  checkDisplayAccountInsights,
  checkDisplayAudienceDetails,
  fetchListSocialProfileToggles,
  setEditModalOpen,
} from "../../../state/listSocialProfileSlice"
import AccountAudienceDetailsToggleWrapper from "./AccountAudienceDetails/AccountAudienceDetailsToggleWrapper"
import AccountContentWrapper from "./AccountContentWrapper"
import AccountCampaignsWrapper from "./AccountCampaignsWrapper"
import { Scope } from "../../../util/types"
import { isSuccess } from "../../../util/apiClient"
import AccountCommunicationsWrapper from "./AccountCommunicationsWrapper"

// eslint-disable-next-line no-shadow
enum TabPathsEnum {
  INSIGHTS = "insights",
  AUDIENCE = "audience",
  NOTES = "notes",
  LISTS = "lists",
  CAMPAIGNS = "campaigns",
    // https://influential-team.atlassian.net/browse/ID-2171?atlOrigin=eyJpIjoiYjQwNDhjYmJlOTY0NDVhNGJjMmZlMzA2YWI1NGY0YjciLCJwIjoiaiJ9
  // ISSUE 9 - Remove Communications Tab until the module is fully implemented
  COMMS = "communications",
  CONTENT = "content",
}
// NOTE: Notes, List and Comms tabs not needed for React parity
interface ProfileTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.INSIGHTS
  | TabPathsEnum.AUDIENCE
  | TabPathsEnum.NOTES
  | TabPathsEnum.LISTS
  | TabPathsEnum.CAMPAIGNS
  | TabPathsEnum.COMMS
  | TabPathsEnum.CONTENT
}

export default function ListProfileTabs() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListProfileTabs" })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    tabPath, contentTabPath, socialProfileID, listID,
  } = useParams()
  const scopes = useSelector(({ user }) => user.scopes)
  const { listProfile, listProfileToggles } = useSelector(({ listSocialProfile }) => listSocialProfile)

  useEffect(() => {
    if (!socialProfileID || !listID) return
    dispatch(fetchListSocialProfileToggles({ listId: listID, socialAccountId: socialProfileID }))
  }, [ socialProfileID, listID ])

  const [ displayAccountInsightsTab, displayAudienceTab ] = useMemo(() => {
    if (isSuccess(listProfileToggles)) {
      const {
        toggles,
      } = listProfileToggles.payload.suggestionListSocialAccountByListIdSocialAccountId.suggestionList
      const toggleValues = toggles.map((toggle) => toggle.name)
      return [
        checkDisplayAccountInsights(toggleValues),
        checkDisplayAudienceDetails(toggleValues),
      ]
    }
    return [ false, false ]
  }, [ listProfileToggles ])

  const tabs: ProfileTabLabelProps[] = useMemo(() => {
    const t = []
    if (listProfileToggles === "init" || listProfileToggles === "loading") return []
    if (displayAccountInsightsTab) t.push({ label: translate("Account Insights"), path: TabPathsEnum.INSIGHTS })
    if (displayAudienceTab) t.push({ label: translate("Audience"), path: TabPathsEnum.AUDIENCE })
    t.push({ label: translate("Content"), path: TabPathsEnum.CONTENT })
    if (scopes.includes(Scope.FEATURE_CAMPAIGN)) t.push({ label: translate("Campaigns"), path: TabPathsEnum.CAMPAIGNS })
    if (scopes.includes(Scope.DEVELOPMENT) && scopes.includes(Scope.COMMUNICATIONS_VIEW)) t.push({ label: translate("Communications"), path: TabPathsEnum.COMMS })
    return t
  }, [ translate, scopes, displayAccountInsightsTab, displayAudienceTab ])

  const setTab = (tabIndex: number) => {
    const newTabPath = tabs[tabIndex].path
    const pathPrefix = contentTabPath ? "../../" : (tabPath ? "../" : "")
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  const tabContent = () => {
    switch (tabPath) {
      case TabPathsEnum.INSIGHTS:
        if (displayAccountInsightsTab) {
          return (
            <AccountInsightsWrapper />
          )
        }
        return null
      case TabPathsEnum.AUDIENCE:
        if (displayAudienceTab) {
          return (
            <AccountInsightsWrapper />
          )
        }
        return null
      case TabPathsEnum.CAMPAIGNS:
        return (
          null
        )
      case TabPathsEnum.CONTENT:
        return null
      default:
        return null
    }
  }

  const tabPage = () => {
    switch (tabPath) {
      case TabPathsEnum.INSIGHTS:
        if (displayAccountInsightsTab) return <AccountInsightsDetailsToggleWrapper />
        return null
      case TabPathsEnum.AUDIENCE:
        if (displayAudienceTab) return <AccountAudienceDetailsToggleWrapper />
        return null
      case TabPathsEnum.CONTENT:
        return <AccountContentWrapper />
      case TabPathsEnum.CAMPAIGNS:
        if (scopes.includes(Scope.FEATURE_CAMPAIGN)) {
          return <AccountCampaignsWrapper />
        }
        return null
      case TabPathsEnum.COMMS:
        if (scopes.includes(Scope.DEVELOPMENT) && scopes.includes(Scope.COMMUNICATIONS_VIEW)) {
          return <AccountCommunicationsWrapper />
        }
        return null
      default:
        return null
    }
  }

  const tabIndex = React.useMemo(() => {
    const foundTabIndex = tabs.findIndex(({ path }) => path === tabPath)
    if (foundTabIndex === -1) {
      if (tabs.length) setTab(0)
      return 0
    }
    return foundTabIndex
  }, [ tabPath, tabs ])

  const platformURL = (listProfile !== "loading" && listProfile !== "init" && listProfile.status !== "error")
  && listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount.networkUrl

  if (!tabs.length) return null

  return (
    <>
      <div className="cp_profile_component-list-tabs-container">
        <Tabs
          handleChange={ setTab }
          tabs={ tabs }
          defaultValue={ tabIndex }
          controlledValue={ tabIndex }
          controls={ (
            <div className="cp_profile_component-list-tabs-controls">
              { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
              <a href={ platformURL || "" } target="_blank" rel="noreferrer">
                <IconButton>
                  <LaunchIcon />
                </IconButton>
              </a>
              <IconButton onClick={ () => dispatch(setEditModalOpen(true)) }>
                <EditIcon />
              </IconButton>
            </div>
) }
        />
        { tabIndex !== 2 && (
          <div className="cp_profile_component-list-tabs-content" data-control={ tabIndex }>
            { tabContent() }
          </div>
        ) }
      </div>
      { tabIndex === 2 && (
      // suggestions content should not be wrapped by the tab container
      <div className="cp_profile_component-list-tabs-content suggestions-social" data-control={ tabIndex }>
        { tabContent() }
      </div>
      ) }
      { tabPage() }
    </>
  )
}
