import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import AvatarUploader from "../../AvatarUploader"
import Button from "../../Button"
import Input from "../../Input"
import { FileResponse, Toast } from "../../../util/types"
import Badge from "../../Badge"
import IconButton from "../../IconButton"
import Avatar from "../../Avatar"
import { uploadFile } from "../../../util/miscHelper"
import { useSelector, useDispatch } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import { fetchCurrentUser, updateUserDetails } from "../../../state/userSlice"
import { pushToast } from "../../../state/toastSlice"

type ImageUploader = {
  avatar: FileResponse | undefined
  setAvatar: React.Dispatch<React.SetStateAction<FileResponse | undefined>>
  handleClientAvatarUpdate: (file: File) => Promise<void>
}

function UserDetailsAvatarUploader({
  avatar,
  setAvatar,
  handleClientAvatarUpdate,
}: ImageUploader) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.SettingsUserDetails" })
  return (
    <section className="cp_component_user-details_profile-image">
      { avatar
        ? (
          <Badge
            className="cp_component_user-details_profile-image_photo-badge"
            badgeContent={ (
              <IconButton
                className="cp_avatar-removal"
                aria-label="delete"
                onClick={ () => setAvatar(undefined) }
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              ) }
            anchorOrigin={ {
              vertical: "bottom",
              horizontal: "right",
            } }
          >
            <Avatar src={ avatar.url } />
          </Badge>
        )
        : (
          <AvatarUploader
            customClasses="cp_component_user-details_profile-image_photo-uploader"
            title={ translate("User Image") }
            infoText={ translate("Upload thumbnail") }
            onAvatarSelect={ handleClientAvatarUpdate }
          />
        )
      }
    </section>
  )
}

export default function UserDetails() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.SettingsUserDetails" })
  const dispatch = useDispatch()
  const { currentUser } = useSelector(({ user }) => ({ currentUser: user.user }))

  const [ avatar, setAvatar ] = React.useState<FileResponse | undefined>()
  const [ fullName, setFullName ] = React.useState<string>("")
  const [ emailAddress, setEmailAddress ] = React.useState<string>("")
  const [ position, setPosition ] = React.useState<string>("")
  const [ buttonEnabled, setButtonEnabled ] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (API.isSuccess(currentUser)) {
      if (currentUser.payload.currentUser) {
        const { currentUser: cuser } = currentUser.payload
        const response: FileResponse = {
          id: cuser.contact.avatar.id,
          url: cuser.contact.avatar.url.address,
        }

        setAvatar(response)
        setFullName(cuser.contact.name)
        setEmailAddress(cuser.contact.email.address)
        setPosition(cuser.contact.title || "")
      }
    }
  }, [ currentUser ])

  const avatarSelected = async (file: File): Promise<void> => {
    const fileStatus = await uploadFile(file)
    if (fileStatus.id) {
      setAvatar({ id: fileStatus.id, url: fileStatus.url })
    }
  }

  const handleSaveChanges = () => {
    // Disable action button to indicate that it's processing
    setButtonEnabled(false)

    if (API.isSuccess(currentUser)) {
      if (currentUser.payload.currentUser) {
        const { currentUser: cuser } = currentUser.payload
        dispatch(updateUserDetails({
          vars: {
            id: cuser.id,
            name: fullName,
            email: emailAddress,
            title: position,
            avatarMediaId: (avatar) ? `${ avatar.id }` : undefined,
          },
          onSuccess: () => {
            // Display toast to indicate success
            const toast: Toast = {
              type: "success",
              message: translate("Successfully updated user details"),
            }
            dispatch(pushToast(toast))

            // Re-fect the current user
            dispatch(fetchCurrentUser())

            // Mkae action button enabled
            setButtonEnabled(true)
          },
          onError: () => {
            // Display toast to indicate success
            const toast: Toast = {
              type: "error",
              message: translate("Error updating user details. Please try again later!"),
            }
            dispatch(pushToast(toast))

            // Mkae action button enabled
            setButtonEnabled(true)
          },
        }))
      }
    }
  }

  return (
    <div className="cp_component_user-details">
      <UserDetailsAvatarUploader
        avatar={ avatar }
        setAvatar={ setAvatar }
        handleClientAvatarUpdate={ avatarSelected }
      />
      <section className="cp_component_user-details_form-container">
        <div className="form-full-name">
          <Input
            id="settings-user-full-name"
            className="user-details-input"
            value={ fullName }
            onChange={ (e) => setFullName(e.currentTarget.value) }
            placeholder={ translate("Enter Your Full Name") }
            label={ translate("Full Name*") }
          />
        </div>
        <div className="form-email-address">
          <Input
            id="settings-user-email-address"
            className="user-details-input"
            value={ emailAddress }
            onChange={ (e) => setEmailAddress(e.currentTarget.value) }
            placeholder={ translate("Enter Your Emaill Address") }
            label={ translate("Email Address*") }
          />
        </div>
        <div className="form-position">
          <Input
            id="settings-user-position"
            className="user-details-input"
            value={ position }
            onChange={ (e) => setPosition(e.currentTarget.value) }
            placeholder={ translate("Enter Your Work Position") }
            label={ translate("Position*") }
          />
        </div>
      </section>
      <section className="cp_component_user-details_actions">
        <Button
          id="user-details-save-changes-btn"
          onClick={ handleSaveChanges }
          label={ translate("Save Changes") }
          isEnabled={ buttonEnabled }
        />
      </section>
    </div>
  )
}
