import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface commEmailListView {
  conversations: GraphQL.SearchConversationsQuery | "init",
  conversationQuery: string | null,
  batches: Status<GraphQL.SearchConversationsQuery>,
  drafts: Status<GraphQL.GetCommunicationGroupDraftsQuery>,
  draftMessage: Status<GraphQL.GetCommunicationGroupDraftQuery>,
  page: number,
  communicationMessages: Status<GraphQL.GetConversationQuery>,
  replyConversation: Status<GraphQL.ReplyToConversationMutation | null>,
  activeConversation: string | null,
  cmAccountLabels: GraphQL.GetNetworkAccountLabelsQuery["getCommunicationGroupNetworkAccountLabels"]
}

// Initialize state
const initialState: commEmailListView = {
  conversations: "init",
  batches: "init",
  drafts: "init",
  draftMessage: "init",
  page: 1,
  communicationMessages: "init",
  replyConversation: "init",
  activeConversation: null,
  cmAccountLabels: [],
  conversationQuery: "",
}

export const commEmailListViewSlice = createSlice({
  name: "commEmailListView",
  initialState,
  reducers: {
    setConversations: (
      state,
      action: PayloadAction<GraphQL.SearchConversationsQuery>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
    resetConversations: (state) => ({ ...state, conversations: "init" }),
    setBatches: (
      state,
      action: PayloadAction<Status<GraphQL.SearchConversationsQuery>>,
    ) => ({
      ...state,
      batches: action.payload,
    }),
    setDrafts: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationGroupDraftsQuery>>,
    ) => ({
      ...state,
      drafts: action.payload,
    }),
    setDraftMessage: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationGroupDraftQuery>>,
    ) => ({
      ...state,
      draftMessage: action.payload,
    }),
    setPage: (
      state,
    ) => ({
      ...state,
      page: state.page + 1,
    }),
    setPageValue: (state, action: PayloadAction<number>) => ({
      ...state,
      page: action.payload,
    }),
    resetPage: (state) => ({ ...state, page: 1 }),
    apendConversations: (
      state,
      action: PayloadAction<GraphQL.SearchConversationsQuery>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
    setCommMessages: (
      state,
      action: PayloadAction<Status<GraphQL.GetConversationQuery>>,
    ) => ({
      ...state,
      communicationMessages: action.payload,
    }),
    resetCommMessages: (state) => ({ ...state, communicationMessages: "init" }),
    setCmAccountLabels: (
      state,
      action: PayloadAction<GraphQL.GetNetworkAccountLabelsQuery["getCommunicationGroupNetworkAccountLabels"]>,
    ) => ({
      ...state,
      cmAccountLabels: action.payload,
    }),
    resetCmAccountLabels: (state) => ({ ...state, cmAccountLabels: [] }),
    resetDraftMessage: (state) => ({ ...state, draftMessage: "init" }),
    resetListViewState: () => initialState,
    setReplyToConversation: (
      state,
      action: PayloadAction<Status<GraphQL.ReplyToConversationMutation | null>>,
    ) => ({
      ...state,
      replyConversation: action.payload,
    }),
    resetReplyToConversation: (state) => ({ ...state, replyConversation: "init" }),
    setActiveConversation: (state, actions) => ({
      ...state,
      activeConversation: actions.payload,
    }),
    setConversationQuery: (state, actions) => ({
      ...state,
      conversationQuery: actions.payload,
    }),
  },
})

export const {
  setConversations,
  resetConversations,
  setPage,
  resetPage,
  setCommMessages,
  resetCommMessages,
  resetListViewState,
  setPageValue,
  setReplyToConversation,
  resetReplyToConversation,
  setActiveConversation,
  setBatches,
  setDrafts,
  setDraftMessage,
  resetDraftMessage,
  setCmAccountLabels,
  resetCmAccountLabels,
  setConversationQuery,
} = commEmailListViewSlice.actions
export default commEmailListViewSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchEmailsList = (
  params: GraphQL.SearchConversationsQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  if (params.filter === "batch" && params.batchId === null) dispatch(setBatches("loading"))

  const emailListResults = await API.fetchConversations(params)

  if (params.filter === "batch" && params.batchId === null) dispatch(setBatches(emailListResults))
  else if (API.isSuccess(emailListResults)) dispatch(setConversations(emailListResults.payload))
}

export const fetchConversationThread = (
  id: string,
  commGroupId: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommMessages("loading"))

  const conversations = await API.getConversationMessages(id)

  if (API.isSuccess(conversations)) {
    const labels = await API.getNetworkAccountLabels({
      communicationGroupId: commGroupId,
      networkAccountId: conversations.payload.getConversation.socialAccount.id,
    })

    if (API.isSuccess(labels)) {
      dispatch(setCmAccountLabels(labels.payload.getCommunicationGroupNetworkAccountLabels))
    }
  }

  dispatch(setCommMessages(conversations))
}

export const replyToConversation = (
  params: GraphQL.ReplyToConversationMutationVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setReplyToConversation("loading"))

  const reply = await API.replyToConversation(params)

  dispatch(setReplyToConversation(reply))
}

export const fetchDraftsList = (params: GraphQL.GetCommunicationGroupDraftsQueryVariables) => async (
  dispatch: Dispatch,
): Promise<void> => {
  // Inidicate that we're laoding drafts
  dispatch(setDrafts("loading"))

  // Make call to laod drafts
  const results = await API.getCommunicationGroupDrafts(params)

  // Set the results
  dispatch(setDrafts(results))
}

export const fetchDraftMessage = (params: GraphQL.GetCommunicationGroupDraftQueryVariables) => async (
  dispatch: Dispatch,
): Promise<void> => {
  // Set loading
  dispatch(setDraftMessage("loading"))

  // Call query
  const results = await API.getCommunicationGroupDraft(params)

  // Set loaded
  dispatch(setDraftMessage(results))
}

export const deleteDraftMessage = (params: {
  vars: GraphQL.DeleteCommuncationDraftMessageMutationVariables,
  onSuccess: () => void,
  onError: () => void,
}) => async (): Promise<void> => {
  // Call to delete
  const result = await API.deleteCommunicationDraftMessage(params.vars)

  // Check for success
  if (API.isSuccess(result)) params.onSuccess()
  if (API.isError(result)) params.onError()
}
