import React from "react"
import { useTranslation } from "react-i18next"
import { ConversationEventType } from "../../../graphql"
import "./style.sass"

type Props = {
  className?: string
  status: ConversationEventType | null
}

const VALID_CONVERSATION_TYPES = [
  ConversationEventType.Opened,
  ConversationEventType.Clicked,
  ConversationEventType.Delivered,
  ConversationEventType.Failed,
]

export default function EmailStatusLabel({ className, status }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.EmailStatusLabel" })

  if (status === null || !VALID_CONVERSATION_TYPES.includes(status)) return null

  const classes = [ `cp_component_email-status-label status-${ status.toLowerCase() }` ]
  if (className) classes.push(className)

  return (
    <div className={ classes.join(" ") }>
      { translate(status) }
    </div>
  )
}
