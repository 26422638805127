/* eslint-disable max-len */
import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
} from "react"
import AddIcon from "@mui/icons-material/Add"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import {
  Autocomplete,
  FilterOptionsState,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  createFilterOptions,
  debounce,
} from "@mui/material"

import * as API from "../../util/apiClient"
import AddConnection from "./AddConnection"
import Avatar from "../Avatar"
import Button from "../Button"
import Connection from "./Connection"
import Divider from "../Divider"
import IconButtonClose from "../IconButtonClose"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import Modal from "../Modal"
import NetworkIcon from "../NetworkIcon"
import Pill from "../Pill"
import Radio from "../Radio"
import mapConnectionToProfileInput from "./helper"
import { DEFAULT_PROFILE_IMAGE, DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import { pushToast } from "../../state/toastSlice"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  CreateProfileMutationVariables,
  EditProfileMutationVariables,
  Network,
  VerticalFragment,
} from "../../graphql"
import {
  ProfileConnection,
  ProfileFlag,
  Scope,
  Toast,
} from "../../util/types"
import {
  createProfile,
  searchVerticals,
  setConnection,
  setOpenModal,
  setProfileImage,
  updateProfile,
  updateSocialAccountContact,
  updateSocialAccountEmails,
} from "../../state/profileSlice"

import "./style.sass"

const filter = createFilterOptions<VerticalFragment>()

type InputChangeEvent = React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>
type Email = string

function ModalProfile(): JSX.Element {
  const [ changedEmails, setChangedEmails ] = React.useState<{ accountid: string[], id: string, original: string, updated: string }[]>([])
  const [ firstName, setFirstName ] = React.useState("")
  const [ lastName, setLastName ] = React.useState("")
  const [ displayName, setDisplayName ] = React.useState("")
  const [ primaryEmailIndex, setPrimaryEmailIndex ] = React.useState(0)
  const [ emails, setEmails ] = React.useState<Email[]>([ "" ])
  const [ profileFlag, setProfileFlag ] = React.useState<ProfileFlag>("None")
  const [ blockListReason, setBlockListReason ] = React.useState<string | undefined>()
  const [ selectedVerticals, selectVerticals ] = React.useState<VerticalFragment[]>([])
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)

  const {
    loadingVerticals,
    verticals: verticalTags,
    openModal,
    isEditMode,
    profile: initialProfile,
    connections,
    profileImage,
    updateProfileCallback: updateCallback,
  } = useSelector(({ profile: profileSlice }) => profileSlice)

  const { scopes } = useSelector(({ user }) => user)

  const dispatch = useDispatch()
  const { vanity } = useParams()

  const resetState = () => {
    dispatch(setProfileImage(""))
    setFirstName("")
    setLastName("")
    setDisplayName("")
    setPrimaryEmailIndex(0)
    setEmails([ "" ])
    setProfileFlag("None")
    setBlockListReason(undefined)
    selectVerticals([])
    dispatch(setConnection({ network: Network.Facebook, connection: undefined }))
    dispatch(setConnection({ network: Network.Instagram, connection: undefined }))
    dispatch(setConnection({ network: Network.Tiktok, connection: undefined }))
    dispatch(setConnection({ network: Network.Snapchat, connection: undefined }))
    dispatch(setConnection({ network: Network.Youtube, connection: undefined }))
  }

  const loadProfile = () => {
    resetState()
    if (!isEditMode || !openModal) return
    if (!initialProfile || initialProfile === "init" || initialProfile === "loading" || API.isError(initialProfile)) return
    const { personality } = initialProfile.payload

    dispatch(setProfileImage(personality.avatar.url.address))
    setDisplayName(personality.name)
    switch (true) {
      case personality.blacklist:
        setProfileFlag("Blocklisted")
        break
      case personality.vip:
        setProfileFlag("VIP")
        break
      default:
        setProfileFlag("None")
        break
    }

    setBlockListReason(personality.blacklistReason || "")
    selectVerticals(personality.verticals)
    if (personality.contacts && personality.contacts.length > 0) {
      const contact = personality.contacts[personality.contacts.length - 1]
      setFirstName(contact.firstName || "")
      setLastName(contact.lastName || "")
      if (contact.emails && contact.emails.length > 0) {
        const loadedEmails = contact.emails.map(({ email, primaryEmail }, i) => {
          if (primaryEmail) setPrimaryEmailIndex(i)
          return email
        })
        const cemails: { accountid: string[], id: string, original: string, updated: string }[] = []
        initialProfile.payload.personality.socialAccounts.forEach((account) => {
          account.emailsSourcedFromTeam.forEach((esft) => {
            const found = contact.emails.find((e) => e.email === esft.address)
            if (found) {
              const item = cemails.find((e) => e.id === esft.id)
              if (!item) {
                cemails.push({
                  accountid: [ account.id ],
                  id: esft.id,
                  original: esft.address,
                  updated: esft.address,
                })
              } else {
                item.accountid.push(account.id)
              }
            } else {
              cemails.push({
                accountid: [ account.id ],
                id: esft.id,
                original: esft.address,
                updated: "",
              })
            }
          })
        })
        setChangedEmails(cemails)
        setEmails(loadedEmails)
      }
    }

    personality.socialAccounts.forEach((socialAccount) => {
      dispatch(setConnection({
        network: socialAccount.network,
        connection: {
          userName: socialAccount.userName,
          network: socialAccount.network,
          foreignUserId: socialAccount.id,
          id: socialAccount.id,
          profilePictureUrl: socialAccount.profilePictureUrl,
        },
      }))
    })
  }

  useEffect(loadProfile, [ openModal, initialProfile ])

  const fetchVerticals = async (startsWith: string) => dispatch(searchVerticals({ startsWith }))

  useEffect(() => { fetchVerticals("") }, [])

  useEffect(() => {
    // set profile image to the first available social account image
    let newImageUrl: string = profileImage
    if (newImageUrl) return
    if (!newImageUrl && connections[Network.Instagram]) newImageUrl = connections[Network.Instagram].profilePictureUrl
    if (!newImageUrl && connections[Network.Facebook]) newImageUrl = connections[Network.Facebook].profilePictureUrl
    if (!newImageUrl && connections[Network.Tiktok]) newImageUrl = connections[Network.Tiktok].profilePictureUrl
    if (!newImageUrl && connections[Network.Snapchat]) newImageUrl = connections[Network.Snapchat].profilePictureUrl
    if (!newImageUrl && connections[Network.Youtube]) newImageUrl = connections[Network.Youtube].profilePictureUrl
    dispatch(setProfileImage(newImageUrl))
  }, [ connections ])

  const onAutoCompleteChange = (event: React.SyntheticEvent, verticals: (string | VerticalFragment)[]) => {
    // remove the add keyword in filtered options
    const cleanedVerticals = verticals.map((vertical) => {
      if (typeof vertical === "string") return { name: vertical, id: "" }
      const { name, id } = vertical
      // eslint-disable-next-line quotes
      const newVerticalSubstring = `Add: "`
      if (!name.includes(newVerticalSubstring)) return vertical
      const cleanedVerticalName = name.replace(newVerticalSubstring, "").slice(0, -1)
      return { name: cleanedVerticalName, id }
    })
    selectVerticals(cleanedVerticals)
  }

  const autoCompleteFilter = (verticals: VerticalFragment[], params: FilterOptionsState<VerticalFragment>) => {
    const { inputValue } = params

    // Suggest the creation of a new value
    const isExisting = verticals.some(({ name }) => inputValue.toLowerCase() === name.toLowerCase())

    const filtered = filter(verticals, params)
      .filter(({
        id: filteredId,
      }) => !selectedVerticals.some(({ id: selectedId }) => selectedId === filteredId))

    if (inputValue && !isExisting) filtered.push({ name: `Add: "${ inputValue }"`, id: inputValue })

    return filtered
  }

  const handleChangeEmail = (e: InputChangeEvent, i: number) => {
    const newEmail = e.currentTarget.value
    const newChangedEmails = [ ...changedEmails ]
    const newSecondaryEmails = [ ...emails ]
    newSecondaryEmails[i] = newEmail

    if (newChangedEmails[i] == null) {
      newChangedEmails.push({
        accountid: [],
        id: "",
        original: "",
        updated: newEmail,
      })
    } else {
      newChangedEmails[i].updated = newEmail
    }

    setEmails(newSecondaryEmails)
    setChangedEmails(newChangedEmails)
  }

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalProfile" })

  const removeEmail = (i: number) => {
    const newEmails = emails.filter((_, index) => index !== i)
    setEmails(newEmails)
  }

  const closeModal = () => {
    resetState()
    dispatch(setOpenModal(false))
  }

  const createProfileHandler = async () => {
    const socialAccounts: ProfileConnection[] = []
    if (connections[Network.Instagram]) socialAccounts.push(connections[Network.Instagram])
    if (connections[Network.Facebook]) socialAccounts.push(connections[Network.Facebook])
    if (connections[Network.Snapchat]) socialAccounts.push(connections[Network.Snapchat])
    if (connections[Network.Tiktok]) socialAccounts.push(connections[Network.Tiktok])
    if (connections[Network.Youtube] && !Array.isArray(connections[Network.Youtube])) {
      socialAccounts.push(connections[Network.Youtube])
    }
    const profile: CreateProfileMutationVariables = {
      name: displayName || `${ firstName } ${ lastName }`,
      verticalIds: selectedVerticals.map(({ id }) => id),
      socialAccounts: socialAccounts.map((account) => mapConnectionToProfileInput(account)),
      avatarUrl: profileImage || DEFAULT_PROFILE_IMAGE,
      vip: profileFlag === "VIP",
      blacklist: profileFlag === "Blocklisted",
      blacklistReason: profileFlag === "Blocklisted" ? blockListReason : undefined,
      contacts: [ {
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        emails: emails
          .filter((email) => email.trim() !== "")
          .map((email, i) => ({ address: email, primary: i === primaryEmailIndex })),
      } ],
    }
    setLoadingPrimaryAction(true)
    const profileInfo = await dispatch(createProfile(profile))

    // Update the social accounts contact information
    if (firstName !== "" && lastName !== "") await dispatch(updateSocialAccountContact(socialAccounts, firstName, lastName))

    // Upadate the social account email addresses, including the primary email address
    await dispatch(updateSocialAccountEmails(socialAccounts, emails, primaryEmailIndex))

    setLoadingPrimaryAction(false)
    let toast: Toast
    if (profileInfo?.profileId) {
      const socialID = Array.isArray(profileInfo.socialAccounts) ? profileInfo.socialAccounts[0] : undefined
      toast = {
        type: "success",
        message: translate("Profile is successfully created"),
        additionalInfo: socialID ? (
          <Link className="cp-toast-link" to={ `/${ vanity }/profile/${ socialID }` }>
            { translate("VIEW PROFILE") }
          </Link>
        ) : undefined,
      }
      closeModal()
    } else {
      toast = {
        message: translate("There was an error creating your Profile"),
        type: "error",
      }
    }
    dispatch(pushToast(toast))
  }

  const updateProfileHandler = async () => {
    if (!initialProfile || initialProfile === "init" || initialProfile === "loading" || API.isError(initialProfile)) return
    const { id: personalityID } = initialProfile.payload.personality

    const socialAccounts: ProfileConnection[] = []
    if (connections[Network.Instagram]) socialAccounts.push(connections[Network.Instagram])
    if (connections[Network.Facebook]) socialAccounts.push(connections[Network.Facebook])
    if (connections[Network.Snapchat]) socialAccounts.push(connections[Network.Snapchat])
    if (connections[Network.Tiktok]) socialAccounts.push(connections[Network.Tiktok])
    if (connections[Network.Youtube] && !Array.isArray(connections[Network.Youtube])) {
      socialAccounts.push(connections[Network.Youtube])
    }

    const socialAccountsProfileInput = socialAccounts
      .map((account) => mapConnectionToProfileInput(account))

    const profile: EditProfileMutationVariables = {
      id: personalityID,
      name: displayName || `${ firstName } ${ lastName }`,
      verticalIds: selectedVerticals.map(({ id }) => id),
      socialAccounts: socialAccountsProfileInput,
      avatarUrl: profileImage,
      vip: profileFlag === "VIP",
      blacklist: profileFlag === "Blocklisted",
      blacklistReason: profileFlag === "Blocklisted" ? blockListReason : undefined,
      contacts: [ {
        personalityContactId: initialProfile.payload.personality.contacts
        && initialProfile.payload.personality.contacts.length > 0
          ? initialProfile.payload.personality.contacts[initialProfile.payload.personality.contacts.length - 1].id
          : undefined,
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        emails: emails
          .filter((email) => email.trim() !== "")
          .map((email, i) => ({ address: email, primary: i === primaryEmailIndex })),
      } ],
    }
    setLoadingPrimaryAction(true)

    // Update the profile
    const updated = await dispatch(updateProfile({
      vars: profile,
      changedEmails: changedEmails.filter((email) => email.original !== email.updated),
    }))

    // Update the contact information for the social accounts
    if (firstName !== "" && lastName !== "") await dispatch(updateSocialAccountContact(socialAccounts, firstName, lastName))

    // Update the social accounts emails, including the pirmary email address
    await dispatch(updateSocialAccountEmails(socialAccounts, emails, primaryEmailIndex))

    setLoadingPrimaryAction(false)
    let toast: Toast
    if (!updated) {
      toast = {
        message: translate("There was an error updating your Profile"),
        type: "error",
      }
    } else {
      toast = {
        type: "success",
        message: translate("Profile is successfully updated"),
      }
      dispatch(pushToast(toast))
      closeModal()
      if (updateCallback) await updateCallback(profile)
    }
  }

  const debouncedSearchVerticals = useCallback(debounce((e, inputValue) => {
    fetchVerticals(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchVerticals ])

  const isValid = Boolean((displayName.trim() !== "" || (firstName.trim() !== "" && lastName.trim() !== ""))
    && (connections[Network.Instagram]
    || connections[Network.Facebook]
    || connections[Network.Snapchat]
    || connections[Network.Tiktok]
    || connections[Network.Youtube]
    ))

  const hasScopeToConnect = React.useMemo(() => {
    const connectScopes = [
      Scope.SOCIAL_ACCOUNT_FACEBOOK_CREATE,
      Scope.SOCIAL_ACCOUNT_INSTAGRAM_CREATE,
      Scope.SOCIAL_ACCOUNT_SNAPCHAT_CREATE,
      Scope.SOCIAL_ACCOUNT_TIKTOK_CREATE,
      Scope.SOCIAL_ACCOUNT_YOUTUBE_CREATE,
    ]
    return connectScopes.some((connectScope) => scopes.includes(connectScope))
  }, [ scopes ])

  const profileFlagScopes = [
    Scope.BLOCKLISTED_PROFILES,
    Scope.VIP_PROFILES,
  ]

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return isEditMode ? translate("Update Profile") : translate("Create Profile")
  }, [ isEditMode, loadingPrimaryAction ])

  const tiktokConnection = (): JSX.Element | null => {
    if (!scopes.includes(Scope.FEATURE_TIKTOK)) return null

    return connections[Network.Tiktok] ? (
      <Connection
        network={ Network.Tiktok }
      />
    ) : (
      <AddConnection
        inputLabel={ translate("TikTok Account") }
        network={ Network.Tiktok }
      />
    )
  }

  const snapchatConnection = (): JSX.Element | null => {
    if (!scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) return null

    return connections[Network.Snapchat] ? (
      <Connection
        network={ Network.Snapchat }
      />
    ) : (
      <AddConnection
        inputLabel={ translate("Snapchat Account") }
        network={ Network.Snapchat }
      />
    )
  }

  return (
    <Modal
      title={ isEditMode ? translate("Edit Profile") : translate("Add Profile") }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ openModal }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ isEditMode ? updateProfileHandler : createProfileHandler }
      maxWidth="xl"
      className="cp_component_modal-add-profile"
      disabled={ !isValid || loadingPrimaryAction }
    >
      { isEditMode && (
        !initialProfile
    || initialProfile === "init"
    || initialProfile === "loading"
    || API.isError(initialProfile)
      ) ? <LoadingIndicator size={ 50 } /> : (
        <>
          <div className="cp_component_modal-add-profile_photo">
            <Avatar src={ profileImage }>
              <CameraAltOutlinedIcon fontSize="inherit" />
            </Avatar>
            <div>

              <h5>
                { translate("Profile photo") }
              </h5>
              <h5 className="title_small">
                { translate("Select from social account thumbnails") }
              </h5>
            </div>
          </div>
          <div className="cp_component_modal-add-profile_row cp_component_modal-add-profile_panels">
            <div className="cp_component_modal-add-profile_column">
              { scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO) && (
              <>
                <div className="cp_component_modal-add-profile-contact">
                  <h4 className="headline_small">
                    { translate("Contact Information") }
                  </h4>
                  <div className="cp_component_modal-add-profile_row">
                    <Input
                      id="social-account-contact-first-name"
                      value={ firstName }
                      onChange={ (e) => setFirstName(e.currentTarget.value) }
                      fullWidth={ true }
                      label={ translate("First Name") }
                    />
                    <Input
                      id="social-account-contact-last-name"
                      value={ lastName }
                      onChange={ (e) => setLastName(e.currentTarget.value) }
                      fullWidth={ true }
                      label={ translate("Last Name") }
                    />
                  </div>
                  { emails.map((email, i) => {
                    const id = `secondary-email-${ i }`
                    return (
                      <div className="cp_component_modal-add-profile_row" key={ id }>
                        <Input
                          label={ i === 0 && translate("Email") }
                          autoFocus={ true }
                          value={ email }
                          onChange={ (e) => handleChangeEmail(e, i) }
                          fullWidth={ true }
                          type="email"
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          value={ i }
                          label={ translate("Primary Email") }
                          control={ (
                            <Radio
                              id={ id }
                              title={ translate("Primary Email") }
                              value={ i }
                              checked={ i === primaryEmailIndex }
                              onChange={ () => setPrimaryEmailIndex(i) }
                            />
                  ) }
                        />
                        <div
                          className={ i === primaryEmailIndex
                            ? "cp_component_modal-add-profile-hidden" : ""
                    }
                        >
                          <IconButtonClose onClick={ () => removeEmail(i) } />
                        </div>
                      </div>
                    )
                  }) }

                  <div className="cp_component_modal-add-profile_row">
                    <Button
                      className="cp_component_modal-add-profile-button_narrow"
                      isEnabled={ true }
                      label={ translate("Add Secondary Email") }
                      isPrimary={ false }
                      startIcon={ <AddIcon /> }
                      onClick={ () => setEmails(emails.concat("")) }
                    />
                  </div>
                </div>
                <Divider />
              </>
              ) }
              <div className="cp_component_modal-add-profile-profile">
                <div className="cp_component_modal-add-profile_row">
                  <div className="cp_component_modal-add-profile_column_2">
                    <h4 className="headline_small">
                      { translate("Profile") }
                    </h4>
                    <div
                      className="cp_component_modal-add-profile_row"
                    >
                      <Input
                        className="cp_component_modal-add-profile_input"
                        value={ displayName }
                        placeholder={ `${ firstName } ${ lastName }` }
                        onChange={ (e) => setDisplayName(e.currentTarget.value) }
                        type="text"
                        fullWidth={ true }
                        InputProps={ {
                          inputProps: {
                            maxLength: 60,
                          },
                        } }
                        required={ true }
                        label={ `${ translate("Profile Display Name") }*` }
                      />
                    </div>
                    { profileFlag === "Blocklisted" && (
                      <div
                        className="cp_component_modal-add-profile_row"
                      >
                        <div>
                          <div className="cp_component_modal-add-profile-label">
                            <p className="label_small-caps-bold">{ `${ translate("Reasons for Blocklisting") }*` }</p>
                            <p className="label_small-caps-bold">
                              { blockListReason?.length || 0 }
                              /120
                            </p>
                          </div>
                          <Input
                            className="cp_component_modal-add-profile_input"
                            value={ blockListReason }
                            onChange={ (e) => setBlockListReason(e.currentTarget.value) }
                            InputProps={ {
                              inputProps: {
                                maxLength: 120,
                              },
                            } }
                            fullWidth={ true }
                            required={ true }
                            placeholder={ `${ translate("Provide a reason for blocklisting") }...` }
                            rows={ 4 }
                            multiline={ true }
                          />
                        </div>
                      </div>
                    ) }
                    <div
                      className="cp_component_modal-add-profile_row"
                    >
                      <Autocomplete
                        filterSelectedOptions={ true }
                        disabled={ selectedVerticals.length === 5 }
                        value={ selectedVerticals }
                        multiple={ true }
                        disableClearable={ true }
                        forcePopupIcon={ true }
                        getOptionLabel={ (vertical) => {
                          // Value selected with enter, right from the input
                          if (typeof vertical === "string") return vertical
                          return vertical.name
                        } }
                        ListboxProps={ {
                          className: "cp_component_modal-add-profile_verticals-list",
                        } }
                        onInputChange={ debouncedSearchVerticals }
                        renderTags={ () => <></> }
                        onChange={ onAutoCompleteChange }
                        filterOptions={ autoCompleteFilter }
                        selectOnFocus={ true }
                        clearOnBlur={ false }
                        handleHomeEndKeys={ true }
                        id="auto-complete-verticals"
                        options={ [ ...verticalTags ].sort() }
                        loading={ loadingVerticals }
                        loadingText={ <LoadingIndicator size={ 20 } /> }
                        renderOption={ (props, { name, id }, state, ownerState) => {
                          if (ownerState.loading && state.index > 0) return (<></>)
                          if (ownerState.loading) {
                            return (
                              <li
                                { ...props }
                              >
                                <LoadingIndicator size={ 20 } />
                              </li>
                            )
                          }
                          return (
                            <li
                              { ...props }
                              key={ id }
                            >
                              { name }
                            </li>
                          )
                        }
                    }
                        freeSolo={ true }
                        renderInput={ (params) => (
                          <Input
                            { ...params as InputProps }
                            label={ translate("Vertical Tags") }
                          />
                        )
                    }
                      />
                    </div>
                    <div
                      className="cp_component_modal-add-profile_row"
                    >
                      <p className="body_small cp_component_modal-add-profile_vertical-description">
                        { translate("Verticals are like categories that help you better distinguish types of profiles.") }
                        { " " }
                        { translate("Attach one or more verticals that you feel are most associated with this profile.") }
                      </p>
                    </div>
                    <div
                      className="cp_component_modal-add-profile_row"
                    >
                      <div className="cp_component_modal-add-profile_pills">

                        { selectedVerticals.map(({ name, id }) => (
                          <Pill
                            label={ name }
                            key={ id }
                            deleteIcon={ <CloseIcon /> }
                            onDelete={
                      () => selectVerticals(selectedVerticals.filter(({ id: _id }) => _id !== id))
                    }
                          />
                        )) }
                      </div>
                    </div>
                  </div>
                  <div className="cp_component_modal-add-profile_column">
                    { profileFlagScopes.some((scope) => scopes.includes(scope)) && (
                      <div className="cp_component_modal_add_profile_flags">
                        <FormControl>
                          <FormLabel className="cp_component_modal_add_profile_flag-label">
                            { translate("Profile Flag") }
                            *
                          </FormLabel>
                          <RadioGroup
                            onChange={ (_, flag) => setProfileFlag(flag as ProfileFlag) }
                            className="cp_component_modal_add_profile_flag-radios"
                            value={ profileFlag }
                          >
                            { scopes.includes(Scope.VIP_PROFILES) && (
                            <FormControlLabel
                              value="VIP"
                              control={ <Radio /> }
                              label="VIP"
                              checked={ profileFlag === "VIP" }
                            />
                            ) }
                            { scopes.includes(Scope.BLOCKLISTED_PROFILES) && (
                            <FormControlLabel
                              value="Blocklisted"
                              control={ <Radio /> }
                              label="Blocklisted"
                              checked={ profileFlag === "Blocklisted" }
                            />
                            ) }
                            <FormControlLabel
                              value="None"
                              control={ <Radio /> }
                              label="None"
                              checked={ profileFlag === "None" }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    ) }
                  </div>
                </div>
              </div>

            </div>
            <div className="cp_component_modal-add-profile_column">
              <div className="cp_component_modal-add-profile-accounts">

                <h4 className="headline_small">
                  { translate("Accounts") }
                </h4>
                { !hasScopeToConnect ? (
                  <div className="cp_component_modal-add-profile_add-permissions">
                    <article>
                      <div className="cp_component_modal-add-profile_add-permissions-mag" />
                    </article>
                    <aside>
                      <h6>{ translate("To Add Accounts, contact Account Manager for permissions.") }</h6>
                    </aside>
                  </div>
                ) : null }
                { hasScopeToConnect ? (
                  <>
                    <div className="cp_component_modal-add-profile_row">
                      <div className="cp_component_modal-add-profile_requirements">
                        <p className="label_small">
                          { translate("Facebook Instagram TikTok Requirements") }
                        </p>
                        <div className="cp_component_modal-add-profile_row">
                          <div className="cp_component_modal-add-profile_network">
                            <NetworkIcon network={ Network.Facebook } isActive={ true } />
                          </div>
                          <p className="body_small">
                            { translate("Facebook accounts must be Pages. Personal accounts will return as invalid.") }
                          </p>
                        </div>
                        <div className="cp_component_modal-add-profile_row">
                          <div className="cp_component_modal-add-profile_network">
                            <NetworkIcon network={ Network.Instagram } isActive={ true } />
                          </div>
                          <p className="body_small">
                            { `${ translate("Instagram accounts must be Business or Creator accounts.") } ` }
                            { translate("Personal accounts will return as invalid.") }
                          </p>
                        </div>
                        { scopes.includes(Scope.FEATURE_TIKTOK) && (
                          <div className="cp_component_modal-add-profile_row">
                            <div className="cp_component_modal-add-profile_network">
                              <NetworkIcon network={ Network.Tiktok } isActive={ true } />
                            </div>
                            <p className="body_small">
                              { /* eslint-disable-next-line max-len */ }
                              { translate("TikTok accounts must be in TCM. Accounts that are not a part of TCM will return as invalid.") }
                            </p>
                          </div>
                        ) }
                      </div>
                    </div>

                    <h5 className="title_medium cp_component_modal-add-profile_connections-description">
                      { `${ translate("At least one account is required") }` }
                    </h5>
                    {
              connections[Network.Instagram] ? (
                <Connection
                  network={ Network.Instagram }
                />
              )
                : (
                  <AddConnection
                    network={ Network.Instagram }
                    inputLabel={ translate("Instagram Account") }
                  />
                )
            }
                    {
              connections[Network.Facebook] ? (
                <Connection
                  network={ Network.Facebook }
                />
              )
                : (
                  <AddConnection
                    network={ Network.Facebook }
                    inputLabel={ translate("Facebook Account") }
                  />
                )
            }
                    { tiktokConnection() }
                    { snapchatConnection() }
                    {
              connections[Network.Youtube] && !Array.isArray(connections[Network.Youtube])
                ? (
                  <Connection
                    network={ Network.Youtube }

                  />
                )
                : (
                  <AddConnection
                    inputLabel={ translate("YouTube Account") }
                    network={ Network.Youtube }
                  />
                )
              }
                  </>
                ) : null }
              </div>
            </div>
          </div>
        </>
      )
      }
    </Modal>
  )
}

export default ModalProfile
