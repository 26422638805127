import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import CommunicationsTable from "../Communications/CommunicationsTable"
import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"
import ErrorHandler from "../../ErrorHandler"
import { getCommsForProfile, setCommsForProfile } from "../../../state/listSocialProfileSlice"
import { ALL_NETWORKS } from "../../../util/types"

export default function CommunicationsTableWrapper() {
  const { commsForProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const dispatch = useDispatch()
  const { socialProfileID } = useParams()

  useEffect(() => {
    if (commsForProfile === "init" && socialProfileID) {
      dispatch(getCommsForProfile({
        networkAccountIds: [ socialProfileID ],
        networkFilter: ALL_NETWORKS,
      }))
    }

    return () => {
      dispatch(setCommsForProfile("init"))
    }
  }, [])

  if (commsForProfile === "init" || commsForProfile === "loading") {
    return <LoadingIndicatorCard />
  }

  if (commsForProfile.status === "error") {
    return <ErrorHandler />
  }

  const comms = commsForProfile.payload.searchCommunicationGroupsByNetworkAccountIds.rows

  return (
    <CommunicationsTable comms={ comms } />
  )
}
