import React, {
  useState,
  JSX,
  useMemo,
  useEffect,
} from "react"
import { useTranslation } from "react-i18next"
import {
  AutocompleteRenderInputParams,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slider,
  SliderValueLabelProps,
  Stack,
  debounce,
} from "@mui/material"
import { useSearchParams } from "react-router-dom"
import PeopleIcon from "@mui/icons-material/People"

import {
  fetchAudienceLocations,
  setAudienceLocations,
  setSearchInput,
  setSelectedLocations,
} from "../../state/searchAISlice"
import { Scope } from "../../util/types"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql/search"
import { AudienceLocation } from "../../graphql"
import * as SearchHelper from "../../state/searchAISlice/helper"
import Autocomplete from "../Autocomplete"
import Button from "../Button"
import Checkbox from "../Checkbox"
import Input from "../Input"
import MinResultInput from "./MinResultsInput"
import Pill from "../Pill"

import "./audience-filters.sass"
import FilterPanel from "./FilterPanel"
import {
  AGE_MARKS,
  getAgeGroups,
  setAudienceInputQueryParams,
} from "../../state/searchAISlice/helper"
import Tooltip from "../Tooltip"

function SliderLabel(props: SliderValueLabelProps) {
  const {
    children, value,
  } = props
  return (
    <Tooltip placement="top" title={ AGE_MARKS.find((mark) => mark.value === value)?.tooltipLabel || "" }>
      { children }
    </Tooltip>
  )
}

export default function AudienceFilters(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIFilterTabs" })
  const dispatch = useDispatch()
  const locationOptions = useSelector(({ searchAI }) => searchAI.audienceLocations)
  const searchInput = useSelector(({ searchAI }) => searchAI.searchAIInput)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const selectedLocations = useSelector(({ searchAI }) => searchAI.selectedLocations)
  const [ activeLocation, setActiveLocation ] = useState<AudienceLocation | null>(null)
  const [ locationMinResults, setLocationMinResults ] = useState<number | null>()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const handleAgeChange = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue) || newValue.length !== 2) return
    const [ minAge, maxAge ] = newValue
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newAgeGroups = getAgeGroups(minAge, maxAge)
    newInput.audienceParams.ageGroups = newAgeGroups.length === SearchHelper.AgeGroups.length ? [] : newAgeGroups
    dispatch(setSearchInput(newInput))
  }

  // Event Handler for Income
  const handleIncomeCheckboxChange = (
    income: GraphQL.IncomeBrackets,
    incomeIsActive: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newIncome = [ ...searchInput.audienceParams.income ]

    if (incomeIsActive) {
      newIncome.push(income)
      newInput.audienceParams.income = newIncome
    } else {
      const filteredIncome = newIncome
        .filter((i) => i.toLowerCase() !== income.toLowerCase())

      newInput.audienceParams.income = filteredIncome
    }

    dispatch(setSearchInput(newInput))
  }

  // Values set booleans
  const checkedGenderFemale = searchInput.audienceParams.genderFemaleMinimum != null

  const checkedGenderMale = searchInput.audienceParams.genderMaleMinimum != null

  function checkedIncome(income: GraphQL.IncomeBrackets): boolean {
    return searchInput.audienceParams.income
      .some((i) => i.toLowerCase() === income.toLowerCase())
  }

  const setMinAgeMatch = searchInput.audienceParams.ageGroups.length !== SearchHelper.AgeGroups.length
    && searchInput.audienceParams.ageGroups.length !== 0

  // Location Helpers
  function fetchLocationsByStartsWithValue(startsWith: string): void {
    if (startsWith === "") {
      setActiveLocation(null)
      dispatch(setAudienceLocations("init"))
      return
    }

    dispatch(fetchAudienceLocations(startsWith))
  }

  function getAutocompleteOptionLabel(
    option: string | AudienceLocation,
  ): string {
    if (typeof option === "string") return option
    return `${ option.type }: ${ option.name }`
  }

  function getLocationOptions(): AudienceLocation[] {
    if (
      locationOptions === "init"
      || locationOptions === "loading"
      || API.isError(locationOptions)
    ) {
      return []
    }

    return locationOptions.payload.searchAudienceLocations.rows
  }

  function handleLocationSave(): void {
    if (activeLocation == null) return
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newLocationMinimum: GraphQL.MinimumLocationMatch[] = newInput.audienceParams.locationMinimum

    const newLocationMinimumMatches = newLocationMinimum
      .filter((m) => m.location.code !== activeLocation.code)

    newLocationMinimumMatches.push({
      location: activeLocation,
      match: locationMinResults || 1,
    })

    // Set new locations and minimum matches
    // newInput.audienceParams.locations = newLocations
    newInput.audienceParams.locationMinimum = newLocationMinimumMatches
    dispatch(setSelectedLocations(newLocationMinimumMatches))
    dispatch(setSearchInput(newInput))

    // Reset location autocomplete form
    setActiveLocation(null)
    setLocationMinResults(null)
    dispatch(setAudienceLocations("init"))
  }

  function handleLocationRemove(locationCode: string): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    const newLocationMinimumMatches = newInput.audienceParams.locationMinimum
      .filter((m) => m.location.code !== locationCode)

    newInput.audienceParams.locationMinimum = newLocationMinimumMatches
    dispatch(setSelectedLocations(newLocationMinimumMatches))
    dispatch(setSearchInput(newInput))
    setAudienceInputQueryParams(newInput.audienceParams, searchParams, setSearchParams)
  }

  function renderLocationInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <Input
        { ...params }
        label=""
        placeholder={ translate("Select Location") }
        InputLabelProps={ {} }
      />
    )
  }

  function setSelectedLocation(
    location: string
    | AudienceLocation
    | (string | AudienceLocation)[]
    | null,
  ): void {
    if (
      location == null
      || typeof location === "string"
      || Array.isArray(location)
    ) {
      return
    }

    setActiveLocation(location)
  }

  // Translation Helpers
  function mapIncomeBracketToString(income: GraphQL.IncomeBrackets): string {
    switch (income) {
      case GraphQL.IncomeBrackets["10000_19999"]:
        return translate("$10,000-$19,999")

      case GraphQL.IncomeBrackets["20000_29999"]:
        return translate("$20,000-$29,999")

      case GraphQL.IncomeBrackets["30000_39999"]:
        return translate("$30,000-$39,999")

      case GraphQL.IncomeBrackets["40000_49999"]:
        return translate("$40,000-$49,999")

      case GraphQL.IncomeBrackets["50000_74999"]:
        return translate("$50,000-$74,999")

      case GraphQL.IncomeBrackets["75000_100000"]:
        return translate("$75,000-$99,999")

      case GraphQL.IncomeBrackets["100000Above"]:
        return translate("$100,000 +")

      default:
        return translate("$0-$9,999")
    }
  }

  const filterCount = useMemo(() => {
    let count = 0
    Object.values(searchInput.audienceParams).forEach((v) => {
      if (v === searchInput.audienceParams.ageMinimum) return
      if (v == null) return
      // if the array contains values for age groups, then we want to count this as one, instead of each age group.
      if (Array.isArray(v) && !SearchHelper.AgeGroups.includes(v[0] as GraphQL.Age_Groups)) {
        count += v.length
        return
      }
      count += 1
    })
    return count
  }, [ searchInput.audienceParams ])

  useEffect(() => {
    setAudienceInputQueryParams(searchInput.audienceParams, searchParams, setSearchParams)
  }, [ searchInput.audienceParams ])

  return (
    <FilterPanel
      filterCount={ filterCount }
      panelID="search-ai-audience-filter-panel"
      title={ translate("Audience") }
      panelIcon={ PeopleIcon }
    >
      <Stack padding={ 2 } gap={ 1 }>
        <Stack direction="row" justifyContent="space-between">
          <Container className="cp_component_search-ai-audience cp_component_search-form-container">
            <form>
              { /* Gender section */ }
              <FormControl className="cp_component_search-ai-audience-gender" component="fieldset" fullWidth={ true }>
                <FormLabel component="legend">
                  { translate("Gender") }
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    className={ checkedGenderFemale ? "cp_has-min-results" : "" }
                    control={ (
                      <Checkbox
                        checked={ checkedGenderFemale }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          if (checkedGenderFemale) newInput.audienceParams.genderFemaleMinimum = null
                          if (!checkedGenderFemale) newInput.audienceParams.genderFemaleMinimum = 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Female") }
                      />
                ) }
                    label={ translate("Female") }
                  />
                  { checkedGenderFemale && (
                  <MinResultInput
                    customClass="gender"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.genderFemaleMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                      searchInput.audienceParams.genderFemaleMinimum || 0
                    }
                  />
                  ) }
                  <FormControlLabel
                    className={ checkedGenderMale ? "cp_has-min-results" : "" }
                    control={ (
                      <Checkbox
                        checked={ checkedGenderMale }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          if (checkedGenderMale) newInput.audienceParams.genderMaleMinimum = null
                          if (!checkedGenderMale) newInput.audienceParams.genderMaleMinimum = 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Male") }
                      />
                ) }
                    label={ translate("Male") }
                  />
                  { checkedGenderMale && (
                  <MinResultInput
                    customClass="gender"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.genderMaleMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={ searchInput.audienceParams.genderFemaleMinimum || 0 }
                  />
                  ) }
                </FormGroup>
              </FormControl>

              { /* Min & Max age section */ }
              <FormControl
                className="cp_component_search-ai-audience-age"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("AGE RANGE") }
                </FormLabel>
                <Slider
                  className="cp_component_search-ai-audience-age-slider"
                  marks={ AGE_MARKS }
                  value={ [
                    AGE_MARKS.find((mark) => mark.ageGroup === searchInput.audienceParams.ageGroups[0])?.value || 1,
                    // eslint-disable-next-line max-len
                    AGE_MARKS.find((mark) => mark.ageGroup === searchInput.audienceParams.ageGroups[searchInput.audienceParams.ageGroups.length - 1])?.value || 100,
                  ] }
                  step={ null }
                  size="small"
                  valueLabelDisplay="auto"
                  onChange={ handleAgeChange }
                  aria-label="Age range"
                  slots={ {
                    valueLabel: SliderLabel,
                  } }
                />
                { setMinAgeMatch && (
                <MinResultInput
                  customClass="age-range"
                  handleChange={ (v: number | null) => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    newInput.audienceParams.ageMinimum = v
                    dispatch(setSearchInput(newInput))
                  } }
                  resultValue={
              searchInput.audienceParams.ageMinimum || 0
            }
                />
                ) }
              </FormControl>

              { /* Ethnicity section */ }
              { scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE_ETHNICITY) && (
              <FormControl
                className="cp_component_search-ai-audience-ethnicity"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("Ethnicity") }
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.ethnicityAfricanAmericanMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.ethnicityAfricanAmericanMinimum = searchInput
                            .audienceParams.ethnicityAfricanAmericanMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("AFRICAN AMERICAN") }
                      />
                  ) }
                    label={ translate("AFRICAN AMERICAN") }
                  />
                  { searchInput.audienceParams.ethnicityAfricanAmericanMinimum != null && (
                  <MinResultInput
                    customClass="ethnicities"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.ethnicityAfricanAmericanMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                    searchInput.audienceParams.ethnicityAfricanAmericanMinimum || 0
                  }
                  />
                  ) }
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.ethnicityAsianPacificIslanderMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.ethnicityAsianPacificIslanderMinimum = searchInput
                            .audienceParams.ethnicityAsianPacificIslanderMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("ASIAN / PACIFIC ISLANDER") }
                      />
                  ) }
                    label={ translate("ASIAN / PACIFIC ISLANDER") }
                  />
                  { searchInput.audienceParams.ethnicityAsianPacificIslanderMinimum != null && (
                  <MinResultInput
                    customClass="ethnicities"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)

                      newInput.audienceParams.ethnicityAsianPacificIslanderMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                    searchInput.audienceParams.ethnicityAsianPacificIslanderMinimum || 0
                    }
                  />
                  ) }
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.ethnicityHispanicLatinoMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.ethnicityHispanicLatinoMinimum = searchInput
                            .audienceParams.ethnicityHispanicLatinoMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("HISPANIC / LATINO") }
                      />
                  ) }
                    label={ translate("HISPANIC / LATINO") }
                  />
                  { searchInput.audienceParams.ethnicityHispanicLatinoMinimum != null && (
                  <MinResultInput
                    customClass="ethnicities"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.ethnicityHispanicLatinoMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                    searchInput.audienceParams.ethnicityHispanicLatinoMinimum || 0
                  }
                  />
                  ) }
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.ethnicityWhiteCaucasianMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.ethnicityWhiteCaucasianMinimum = searchInput
                            .audienceParams.ethnicityWhiteCaucasianMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("WHITE / CAUCASIAN") }
                      />
                  ) }
                    label={ translate("WHITE / CAUCASIAN") }
                  />
                  { searchInput.audienceParams.ethnicityWhiteCaucasianMinimum != null && (
                  <MinResultInput
                    customClass="ethnicities"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.ethnicityWhiteCaucasianMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                      searchInput.audienceParams.ethnicityWhiteCaucasianMinimum || 0
                  }
                  />
                  ) }
                </FormGroup>
              </FormControl>
              ) }

              { /* Location section */ }
              { scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE_LOCATION) && (
              <FormControl
                className="cp_component_search-ai-audience-location"
                component="fieldset"
                fullWidth={ true }
              >
                <div className="cp_component_search-content-audience_header">
                  <FormLabel component="legend">
                    { translate("LOCATION") }
                  </FormLabel>
                </div>
                <Autocomplete
                  disablePortal={ true }
                  filterOptions={ (x) => x }
                  filterSelectedOptions={ true }
                  getOptionLabel={ (o) => getAutocompleteOptionLabel(o) }
                  isOptionEqualToValue={ ({ code }) => code === activeLocation?.code }
                  loading={ locationOptions === "loading" }
                  loadingText={ translate("Loading locations...") }
                  noOptionsText={ translate("Search for a location") }
                  onInputChange={ debounce((_, newValue) => {
                    fetchLocationsByStartsWithValue(newValue)
                  }, Constant.DEFAULT_DEBOUNCE_WAIT) }
                  onChange={ (_, newValue) => setSelectedLocation(newValue) }
                  options={ getLocationOptions() }
                  renderInput={ (params) => renderLocationInput(params) }
                  value={
                activeLocation == null
                  ? null
                  : `${ activeLocation.type }: ${ activeLocation.name }`
              }
                />
                { activeLocation && (
                <MinResultInput
                  customClass="location"
                  handleChange={ setLocationMinResults }
                  resultValue={ locationMinResults || 0 }
                />
                ) }
                { activeLocation && (
                <div className="cp_component_search-ai-audience-location-action">
                  <Button
                    isPrimary={ false }
                    label={ translate("Save Location") }
                    onClick={ () => handleLocationSave() }
                  />
                </div>
                ) }
                <div className="cp_component_search-ai-audience-location-pills">
                  { selectedLocations.map((l) => (
                    <Pill
                      key={ `cp_component-search-ai-audience-location-pill-${ l.location.code }` }
                      label={ `${ l.location.name } | ${ l.match }%` }
                      onDelete={ () => handleLocationRemove(l.location.code) }
                    />
                  )) }
                </div>
              </FormControl>
              ) }

              { /* Family status section */ }
              <FormControl
                className="cp_component_search-ai-audience-family"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("Family") }
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.familyMarriedMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.familyMarriedMinimum = searchInput.audienceParams.familyMarriedMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("MARRIED") }
                      />
                ) }
                    label={ translate("MARRIED") }
                  />
                  { searchInput.audienceParams.familyMarriedMinimum != null && (
                  <MinResultInput
                    customClass="family-status"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.familyMarriedMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                  searchInput.audienceParams.familyMarriedMinimum || 0
                }
                  />
                  ) }
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.familySingleMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.familySingleMinimum = searchInput.audienceParams.familySingleMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("SINGLE") }
                      />
                ) }
                    label={ translate("SINGLE") }
                  />
                  { searchInput.audienceParams.familySingleMinimum != null && (
                  <MinResultInput
                    customClass="family-status"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.familySingleMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                  searchInput.audienceParams.familySingleMinimum || 0
                }
                  />
                  ) }
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.audienceParams.familyParentsMinimum != null }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          newInput.audienceParams.familyParentsMinimum = searchInput.audienceParams.familyParentsMinimum != null
                            ? null : 0
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("PARENTS") }
                      />
                ) }
                    label={ translate("PARENTS") }
                  />
                  { searchInput.audienceParams.familyParentsMinimum != null && (
                  <MinResultInput
                    customClass="family-status"
                    handleChange={ (v: number | null) => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.audienceParams.familyParentsMinimum = v
                      dispatch(setSearchInput(newInput))
                    } }
                    resultValue={
                  searchInput.audienceParams.familyParentsMinimum || 0
                }
                  />
                  ) }
                </FormGroup>
              </FormControl>

              { /* Income section */ }
              <FormControl
                className="cp_component_search-ai-audience-income"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("Income") }
                </FormLabel>
                <FormGroup className="cp_component_search-ai-audience-income-options">
                  { SearchHelper.INCOME_BRACKETS.map((i: GraphQL.IncomeBrackets) => (
                    <FormControlLabel
                      key={ `cp_component_search-ai-audience-form-income-${ i }` }
                      control={ (
                        <Checkbox
                          checked={ checkedIncome(i) }
                          onChange={ () => {
                            handleIncomeCheckboxChange(i, !checkedIncome(i))
                          } }
                          name={ mapIncomeBracketToString(i) }
                        />
                ) }
                      label={ mapIncomeBracketToString(i) }
                    />
                  )) }
                </FormGroup>
              </FormControl>
            </form>
          </Container>
        </Stack>
      </Stack>
    </FilterPanel>
  )
}
