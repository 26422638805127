import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import { NavigateFunction } from "react-router-dom"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

// User Slice Interface and Initial State
export interface UserState {
  forgotPasswordEmailAttempt: Status<
    GraphQL.ForgotPasswordEmailMutation | null
  >,
  loginAttempt: Status<string>,
  loginViaEmailAttempt: Status<GraphQL.LoginEmailMutation | null>
  scopes: Array<string>,
  user: Status<GraphQL.CurrentUserQuery>,
  openOauthModal: boolean,
  openOAuthContinueModal: boolean,
  oauthModalNetwork: GraphQL.Network | "NA",
  oauthModalUrl: string
}

const initialState: UserState = {
  forgotPasswordEmailAttempt: "init",
  loginAttempt: "init",
  loginViaEmailAttempt: "init",
  scopes: [],
  user: "init",
  openOauthModal: false,
  openOAuthContinueModal: false,
  oauthModalNetwork: "NA",
  oauthModalUrl: "",
}

// User Slice
export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setForgotPasswordEmailAttempt: (
      state,
      action: PayloadAction<Status<GraphQL.ForgotPasswordEmailMutation | null>>,
    ) => ({
      ...state,
      forgotPasswordEmailAttempt: action.payload,
    }),
    setLoginAttempt: (
      state,
      action: PayloadAction<Status<string>>,
    ) => ({
      ...state,
      loginAttempt: action.payload,
    }),
    setLoginViaEmailAttempt: (
      state,
      action: PayloadAction<Status<GraphQL.LoginEmailMutation | null>>,
    ) => ({
      ...state,
      loginViaEmailAttempt: action.payload,
    }),
    setScopes: (state, action: PayloadAction<Array<string> | undefined>) => ({
      ...state,
      scopes: action.payload || [],
    }),
    setUser: (
      state,
      action: PayloadAction<Status<GraphQL.CurrentUserQuery>>,
    ) => ({
      ...state,
      user: action.payload,
      scopes: API.isSuccess(action.payload) ? action.payload.payload.currentUser!.scopes : [],
    }),
    setOpenOAuthModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openOauthModal: action.payload,
    }),
    setOpenOAuthContinueModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openOAuthContinueModal: action.payload,
    }),
    setOauthNetwork: (state, action: PayloadAction<GraphQL.Network | "NA">) => ({
      ...state,
      oauthModalNetwork: action.payload,
    }),
    setOauthUrl: (state, action: PayloadAction<string>) => ({
      ...state,
      oauthModalUrl: action.payload,
    }),
  },
})

export const {
  setForgotPasswordEmailAttempt,
  setLoginAttempt,
  setLoginViaEmailAttempt,
  setScopes,
  setUser,
  setOpenOAuthModal,
  setOpenOAuthContinueModal,
  setOauthNetwork,
  setOauthUrl,
} = userSlice.actions
export default userSlice.reducer

// User Slice Thunks
export const fetchCurrentUser = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setUser("loading"))

  const userResult = await API.fetchCurrentUser()

  if (API.isSuccess(userResult)) {
    dispatch(setScopes(userResult.payload.currentUser?.scopes))
  }

  dispatch(setUser(userResult))
}

export const fetchCurrentUserWithoutRedirect = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setUser("loading"))

  const userResult = await API.fetchCurrentUserWithoutRedirect()

  if (API.isSuccess(userResult)) {
    dispatch(setScopes(userResult.payload.currentUser?.scopes))
  }

  dispatch(setUser(userResult))
}

export const login = (
  vanity: string,
  username: string,
  password: string,
  navigate: NavigateFunction,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setLoginAttempt("loading"))
  const loginResult = await API.login(vanity, username, password)

  if (!API.isSuccess(loginResult)) {
    dispatch(setLoginAttempt(loginResult))
    return
  }

  const userResult = await API.query<
    GraphQL.CurrentUserQuery
  >(GraphQL.CurrentUserDocument)

  dispatch(setUser(userResult))
  dispatch(setLoginAttempt(loginResult))

  if (API.isSuccess(userResult)) {
    navigate("/")
  }
}

export const loginViaEmail = (code: string, email: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLoginViaEmailAttempt("loading"))
  const response = await API.loginViaEmail({ code, email })
  dispatch(setLoginViaEmailAttempt(response))
}

export const sendForgotPasswordEmail = (code: string, email: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setForgotPasswordEmailAttempt("loading"))
  const response = await API.sendForgotPasswordEmail({ code, email })
  dispatch(setForgotPasswordEmailAttempt(response))
}

export const logout = (
  navigate: NavigateFunction,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setLoginAttempt("init"))
  dispatch(setUser("init"))
  navigate("/logout-page")
}

export const changePassword = (params: {
  vars: GraphQL.ChangePasswordMutationVariables,
  onSuccess: () => void,
  onError: () => void,
}) => async (): Promise<void> => {
  const result = await API.changePassword(params.vars)

  // Call callbacks for success/fail
  if (API.isSuccess(result)) params.onSuccess()
  if (API.isError(result)) params.onError()
}

export const updateUserDetails = (
  params: {
    vars: GraphQL.UpdateUserDetailsMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Update the details in the database
  const response = await API.updateUserDetails(params.vars)

  // Make call for succss/fail
  if (API.isSuccess(response)) params.onSuccess()
  if (API.isError(response)) params.onError()
}
