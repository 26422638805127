import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"
import dayjs from "dayjs"

import * as Constant from "../../util/constant"
import DataGrid from "../DataGrid"
import EmptyElement from "../EmptyElement"
import EntityInfoRow from "../EntityInfoRow"
import ListManagementRowContextMenu from "./ListManagementRowContextMenu"
import LoadingIndicator from "../LoadingIndicator"
import ModalScoreBreakDown from "../ModalScoreBreakDown"
import MoveCategoryMenuItem, { MoveCategoryMenuTitle } from "./MoveCategoryMenuItem"
import Pill from "../Pill"
import Select from "../Select"
import Status from "../DataGrid/RenderCellComponents/Status"
import Timestamp from "../DataGrid/RenderCellComponents/Timestamp"
import VisibilityIcon from "../DataGrid/RenderCellComponents/VisibilityIcon"
import { ListCategory, ScoreBreakDown } from "../../util/types"
import { SuggestionListMode } from "../../graphql"
import { prettyPrintDecimal, shorthandNumber } from "../../util/miscHelper"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import { scoreModalTypes } from "../../util/constant"
import { updateSuggestionListSocialAccount } from "../../util/apiClient"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  deselectSocialAccount,
  fetchList,
  openCreateFeedbackModal,
  selectSocialAccount,
} from "../../state/listSlice"

import "./list-management-table.sass"
import OauthedIcon from "../RowAdornments/OauthedIcon"

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      { ...props }
      slots={ {
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
      } }
    />
  )
}

interface ListManagementTableProps {
  categories: ListCategory[]
  currentCategory: ListCategory
}

export default function ListManagementTable({ categories, currentCategory }: ListManagementTableProps) {
  const [ loadingVisibility, setLoadingVisibility ] = React.useState("")
  const [ engagementScoreModal, setEngagementScoreModal ] = React.useState(false)
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListManagementTable" })
  const fetchedList = useSelector(({ list }) => list.list)
  const selectedAccounts = useSelector(({ list }) => list.selectedSocialAccounts)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  if (fetchedList === "init" || fetchedList === "loading") return null
  if (fetchedList.status === "error") return null

  const listPayload = fetchedList.payload.suggestionListById

  const templatesContent = listPayload
    .suggestionListCategories
    .find((category) => category.id === currentCategory.id)

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    const isAdCouncilScore = listPayload.toggles.some((toggle) => toggle.name === "ToggleAdCouncilScore")
    if (scoreBreakDown.scores.some((item) => item === null)) return
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType, isAdCouncilScore))
    setEngagementScoreModal(true)
  }

  let COLUMNS: GridColDef[] = [
    {
      field: "__check__",
      headerName: "",
      sortable: false,
      filterable: false,
      renderHeader: () => "",
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
      maxWidth: 50,
      align: "center",
    },
    {
      field: "visible",
      headerName: "",
      sortable: false,
      filterable: false,
      renderHeader: () => "",
      renderCell: (params) => (
        loadingVisibility === params.row.id ? <LoadingIndicator size={ 20 } />
          : (
            <VisibilityIcon
              visible={ params.row.visible }
              onClick={ async () => {
                setLoadingVisibility(params.row.id)
                const verticals = params.row.listSocialAccount.verticals.map(({ id }: { id: string }) => id)
                await updateSuggestionListSocialAccount({
                  id: params.row.listSocialAccount.id,
                  input: {
                    name: params.row.listSocialAccount.name,
                    verticals,
                    public: !params.row.visible,
                  },
                })
                setLoadingVisibility("")
                await dispatch(fetchList(listPayload.id))
              } }
            />
          )
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
      maxWidth: 60,
    },
    {
      field: "followersCount",
      headerName: translate("FOLLOWERS"),
      sortable: true,
      filterable: true,
      filterOperators: getGridNumericOperators(),
      valueGetter(params) {
        return params.row.followers
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "followers",
      headerName: translate("ACCOUNT"),
      sortable: true,
      filterable: true,
      filterOperators: getGridStringOperators(),
      valueGetter(params) {
        return params.row.account
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          isPlaceholder={ params.row.isPlaceholder }
          avatarSrc={ params.row.profilePictureUrl }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
          rowAdornment={ (
            <OauthedIcon
              tooltipText={ translate("Influencer is OAuth") }
              oauthed={ params.row.listSocialAccount.oauthed }
            />
          ) }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "inDemo",
      headerName: translate("In-Demo"),
      sortable: true,
      filterable: true,
      filterOperators: getGridNumericOperators(),
      renderCell: (params) => (
        <Pill label={ `${ prettyPrintDecimal(params.row.inDemo) }%` } />
      ),
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "score",
      headerName: listPayload.toggles.map(({ name }) => name).includes("ToggleEngagementRate")
        ? translate("ENG. RATE") : translate("SCORE"),
      sortable: true,
      valueGetter(params) {
        return listPayload.toggles.map(({ name }) => name).includes("ToggleEngagementRate")
          ? prettyPrintDecimal(params.row.score)
          : Math.round(params.row.score)
      },
      filterOperators: getGridNumericOperators(),
      renderCell: (params) => (
        <Pill
          label={ listPayload.toggles.map(({ name }) => name).includes("ToggleEngagementRate")
            ? `${ prettyPrintDecimal(params.row.score) }%`
            : Math.round(params.row.score) }
          handleClick={
            params.row.scoreBreakDown
              ? () => { handleEngagmentScoreModal(params.row.scoreBreakDown, scoreModalTypes.ENGAGEMENT) }
              : () => {}
           }
        />
      ),
      flex: 0.7,
      maxWidth: 200,
    },
    {
      field: "created",
      headerName: translate("ACTIVITY"),
      sortable: true,
      filterOperators: getGridDateOperators(),
      valueGetter(params) {
        return new Date(params.row.created * 1000)
      },
      renderCell: (params) => (
        <Timestamp
          time={ dayjs(params.row.created * 1000).format(Constant.LONGFORM_DATE) }
          subInfo={ `${ translate("Added by") } ${ params.row.creator.contact.name }` }
        />
      ),
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "category",
      headerName: translate("CATEGORY"),
      sortable: false,
      renderCell: (params) => (
        <Select
          id={ `${ params.row.id }-select-category` }
          label=""
          labelId="select-brands-date"
          value=""
          className="cp_list-management-table_component-select-category"
          displayEmpty={ true }
          renderValue={ (value) => {
            if (value === "") return translate("SELECT")
            return value
          } }
          menuItems={ [ <MoveCategoryMenuTitle key={ `category-menu-${ params.row.id }` } /> ]
            .concat(categories
              .map((category) => (
                <MoveCategoryMenuItem
                  key={ category.id }
                  category={ category }
                  currentCategory={ currentCategory }
                  socialAccountId={ params.row.id }
                />
              ))) }
        />
      ),
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "status",
      headerName: translate("STATUS"),
      sortable: true,
      filterOperators: getGridBooleanOperators(),
      renderCell: (params) => (
        <Status
          status={ params.row.status === true
            ? "success"
            : "error" }
          label={ params.row.status === true
            ? translate("APPROVED")
            : translate("NOT APPROVED") }
        />
      ),
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "commentCount",
      headerName: translate("# OF FEEDBACK"),
      sortable: true,
      filterable: false,
      renderCell: (params) => (
        <div className="cp_list-management-table_component-column-feedback">
          <Pill label={ params.row.commentCount } />
        </div>
      ),
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "comments",
      headerName: translate("FEEDBACK"),
      sortable: false,
      filterOperators: getGridBooleanOperators(),
      valueGetter(params) {
        return params.row.comments && params.row.comments.length > 0
      },
      renderCell: (params) => (
        <div
          onKeyUp={ () => {} }
          role="button"
          tabIndex={ 0 }
          className="cp_list-management-table_component-column-feedback"
          onClick={ () => dispatch(openCreateFeedbackModal(params.row.listSocialAccount)) }
        >
          <p className="cp_list-management-table_component-column-feedback-content">
            { params.row.comments[0]?.content }
          </p>
          { params.row.commentCount === 0 && (
            <p className="cp_list-management-table_component-column-feedback-content_none">
              { translate("No feedback") }
            </p>
          ) }
        </div>
      ),
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="cp_list-management-table_component-column-context">
          <ListManagementRowContextMenu
            suggestionListSocialAccount={ params.row.listSocialAccount }
            categoryId={ currentCategory.id }
            rowIndex={ params.row.rowIndex }
            rowCount={ templatesContent?.suggestionListSocialAccounts.length || 0 }
          />
        </div>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ]

  // Remove the inDemo column if the list is has no demographics set during the list creation.
  if (listPayload.maxAge == null && listPayload.maxAge == null && listPayload.sexes.length === 0) {
    COLUMNS = COLUMNS.filter((column) => column.field !== "inDemo")
  }
  if (listPayload.suggestionListMode !== SuggestionListMode.Campaign) {
    COLUMNS = COLUMNS.filter(({ field }) => field !== "status" && field !== "comments" && field !== "commentCount")
  }
  if (!templatesContent?.suggestionListSocialAccounts.some(({ score }) => Boolean(score))) {
    COLUMNS = COLUMNS.filter(({ field }) => field !== "score")
  }

  return (
    <>
      <DataGrid
        getRowId={ (row) => row.id }
        slots={ {
          columnMenu: CustomColumnMenu,
          noRowsOverlay: EmptyElement,
          noResultsOverlay: EmptyElement,
        } }
        columnVisibilityModel={ {
          followersCount: false,
        } }
        checkboxSelection={ true }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={
        {
          left: [ "__check__", "visible", "followers" ],
          right: [ "ellipsisMenu" ],
        }
      }
        rows={ templatesContent?.suggestionListSocialAccounts.map(({
          id,
          score,
          visible,
          inDemo,
          socialAccount,
          approvalStatus,
          created,
          creator,
          comments,
        }, i, socialAccounts) => ({
          score: listPayload.toggles.map(({ name }) => name).includes("ToggleEngagementRate")
            ? score?.components.find(({ code }) => code === "cippus_eng_rate")?.raw
            : (score?.value || 0),
          account: socialAccount.userName,
          profilePictureUrl: socialAccount.profilePictureUrl,
          isPlaceholder: socialAccount.isPlaceholder,
          followers: socialAccount.socialAccountStatistics.followers,
          id,
          rowIndex: i,
          activity: "added by william during testing",
          categories,
          status: Boolean(approvalStatus?.value),
          visible,
          created,
          creator,
          comments,
          commentCount: comments.length,
          inDemo: inDemo || 0,
          listSocialAccount: socialAccounts[i],
          scoreBreakDown: { socialAccount, scores: [ score ] },
        })) || [] }
        loading={ false }
        scrollEndThreshold={ 200 }
        onRowSelectionModelChange={ (ids) => {
          const selectedTableIds = new Set(ids)
          const globallySelectedAccountIds = new Set(selectedAccounts.map(({ id }) => id))
          const selectedRows = templatesContent?.suggestionListSocialAccounts.filter(({
            id,
          }) => selectedTableIds.has(id)) || []
          const unSelectedRows = templatesContent?.suggestionListSocialAccounts.filter(({
            id,
          }) => !selectedTableIds.has(id)) || []
          selectedRows.forEach((row) => {
            if (!globallySelectedAccountIds.has(row.id)) {
              dispatch(selectSocialAccount(row))
            }
          })
          unSelectedRows.forEach((row) => {
            if (globallySelectedAccountIds.has(row.id)) {
              dispatch(deselectSocialAccount(row))
            }
          })
        } }
      />
      <ModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </>
  )
}
