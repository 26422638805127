import React from "react"
import AssignReviewersTab from "./AssignReviewers"
import ReviewerGroupListContainer from "./ReviewerGroupListContainer"

export default function AssignReviewers() {
  return (
    <>
      <ReviewerGroupListContainer />
      <AssignReviewersTab />
    </>
  )
}
