import React, { useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import ReviewerGroupsList, { ReviewerGroup } from "./ReviewerGroupsList"
import "./reviewer-groups-list.sass"

const GET_REVIEWER_GROUPS = gql`
  query GetReviewerGroups($campaignId: ID!) {
    reviewerGroupsForCampaign(campaignId: $campaignId) {
      id
      displayName
      description
      userAssignments {
        userId
        permission
        createdAt
        updatedAt
        user {
          id
          fullName
          initials
          avatarUrl
        }
      }
    }
  }
`

interface ReviewerGroupsResponse {
  reviewerGroupsForCampaign: ReviewerGroup[]
}

interface ReviewerGroupsVars {
  campaignId: string
}

export default function ReviewerGroupListContainer() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedGroup, setSelectedGroup ] = useState<ReviewerGroup | null>(null)
  const { campaignID: campaignId } = useParams()
  const query$ = useQuery<ReviewerGroupsResponse, ReviewerGroupsVars>(
    GET_REVIEWER_GROUPS,
    {
      variables: {
        campaignId: campaignId || "",
      },
      context: {
        apiVersion: "v2",
      },
    },
  )

  const groups = query$.data?.reviewerGroupsForCampaign || []
  const handleSelectGroup = (group: ReviewerGroup) => {
    setSelectedGroup(group)
  }
  return (
    <div className="assign-reviewers-tab">
      <ReviewerGroupsList
        loading={ query$.loading }
        groups={ groups }
        onSelectGroup={ handleSelectGroup }
      />
    </div>
  )
}
