import React, { JSX } from "react"

import "./style.sass"

type Props = {
  bodyText?: string
  headlineText?: string
  children?: React.JSX.Element
}

export default function EmptySplash(
  {
    headlineText, bodyText, children,
  }: Props,
): JSX.Element {
  return (
    <div className="cp_component_empty-splash">
      <article>
        <div className="cp_component_empty-splash-icon-mag" />
      </article>
      <aside>
        { headlineText && (<h4>{ headlineText }</h4>) }
        { bodyText && (<p className="body_large">{ bodyText }</p>) }
        { children }
      </aside>
    </div>
  )
}
