import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { UploadFileRounded, Reply } from "@mui/icons-material"
import { Container } from "@mui/material"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import CommunicationMessagesEmpty from "./CommunicationMessagesEmpty"
import ReplyToConversation from "./ReplyToConversation"
import { CommMessage, ConversationUploads } from "./communicationMessage"

import { Scope } from "../../util/types"
import { openModalCreateDeliverable } from "../../state/ModalCreateDeliverable"
import ErrorHandler from "../ErrorHandler"
import Button from "../Button"
import CommunicationBatchEmpty from "./CommunicationBatchEmpty"
// eslint-disable-next-line max-len
import { openNoUserAssociatedWithCampaignModalOpen, setNoCampaignAssociatedWarningOpen } from "../../state/ModalCreateDeliverableRequirements"
import { fetchConversationThread } from "../../state/commEmailListView"
import GroupAccountsLabels from "./GroupAccounts/labels"

interface ConversationData {
  id: string,
  hasMessages: boolean,
  messages: GraphQL.GetConversationQuery["getConversation"]["conversationThread"]["timeline"],
  subject: GraphQL.GetConversationQuery["getConversation"]["subject"],
  socialAccount: GraphQL.GetConversationQuery["getConversation"]["socialAccount"] | null,
  labels: GraphQL.GetNetworkAccountLabelsQuery["getCommunicationGroupNetworkAccountLabels"]
}

type Props = {
  isBatch?: boolean
}

export default function CommunicationsMessages({ isBatch = false }: Props) {
  const { communicationMessages, cmAccountLabels } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const { commGroupID } = useParams()
  const { scopes } = useSelector(({ user }) => user)
  const [ showReplay, setShowReplay ] = React.useState(false)
  const dispatch = useDispatch()
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsMessages" })
  const [ showMiddleMessages, setShowMiddleMessages ] = React.useState(false)

  const mappedConversations = useMemo((): ConversationData => {
    if (API.isSuccess(communicationMessages)) {
      return {
        id: communicationMessages.payload.getConversation.id,
        hasMessages: true,
        messages: communicationMessages.payload.getConversation.conversationThread.timeline,
        subject: communicationMessages.payload.getConversation.subject,
        socialAccount: communicationMessages.payload.getConversation.socialAccount,
        labels: cmAccountLabels,
      }
    }
    return {
      id: "",
      hasMessages: false,
      messages: [],
      subject: "",
      socialAccount: null,
      labels: [],
    }
  }, [ communicationMessages ])

  const messagesLastToFirst = useMemo(() => mappedConversations.messages.slice().reverse(), [ mappedConversations.messages ])

  const topMessage: ConversationData["messages"] = useMemo(
    () => [ messagesLastToFirst[0] ],
    [ messagesLastToFirst ],
  )

  const lastMessage: ConversationData["messages"] = useMemo(
    () => [ messagesLastToFirst[mappedConversations.messages.length - 1] ],
    [ messagesLastToFirst ],
  )

  const middleMessages = useMemo(() => {
    if (mappedConversations.messages.length <= 2) return []
    return messagesLastToFirst.slice(1, -1)
  }, [ messagesLastToFirst ])

  const RenderMessages = (
    messagesArr: ConversationData["messages"],
    presentation?: string,
  ) => messagesArr.map((message, indx) => {
    if (message.__typename === "ConversationMessage") {
      const fromName = message.outgoing ? message.from.alias : mappedConversations.socialAccount?.userName
      return (
        <CommMessage
          id="cp_component_comm-message"
          key={ message.id }
          message={ message }
          socialAccount={ mappedConversations.socialAccount }
          indx={ indx }
          totalMessages={ messagesArr.length }
          presentation={ presentation }
          labels={ (fromName === mappedConversations.socialAccount?.userName) ? mappedConversations.labels : undefined }
        />
      )
    }
    return (
      <ConversationUploads
        id="cp_component_comm-message"
        message={ message }
        socialAccount={ mappedConversations.socialAccount }
        labels={ mappedConversations.labels }
      />
    )
  })

  if (communicationMessages === "loading") {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (isBatch) {
    return (
      <div id="cp_comm-messages-empty" className="communications-email-list-view">
        <CommunicationBatchEmpty parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  if (communicationMessages === "init" || mappedConversations.hasMessages === false) {
    return (
      <div id="cp_comm-messages-empty" className="communications-email-list-view">
        <CommunicationMessagesEmpty parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  if (communicationMessages.status === "error") return <ErrorHandler />

  const handleNewDeliverableClick = () => {
    const {
      campaignNetworkAccount,
      communicationGroup: {
        campaign,
      },
      socialAccount,
    } = communicationMessages.payload.getConversation

    // If the communication is not associated with a campaign
    if (!campaign) {
      dispatch(setNoCampaignAssociatedWarningOpen(true))
      return
    }
    // If the social account is not associated with the campaign
    if (!campaignNetworkAccount) {
      dispatch(openNoUserAssociatedWithCampaignModalOpen({
        user: {
          id: socialAccount.id,
          name: socialAccount.userName,
        },
        campaign: {
          id: campaign.id,
          name: campaign.name,
        },
      }))
      return
    }
    dispatch(openModalCreateDeliverable({ conversation: communicationMessages.payload.getConversation }))
  }

  const reloadCommunicationMessages = () => {
    if (commGroupID) {
      if (API.isSuccess(communicationMessages)) {
        dispatch(fetchConversationThread(communicationMessages.payload.getConversation.id, commGroupID))
      }
    }
  }

  return (
    <div className="communications-email-list-view cp_communications-thread cp_ct">
      <div className="cp_ct-thread-header">
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Container
            id="cp_component_comms-messages_create-new-deliverable-button"
            onClick={ handleNewDeliverableClick }
            className="create-new-deliverable-button"
          >
            <>
              <UploadFileRounded />
              <p>{ translate("Create New Deliverable") }</p>
            </>
          </Container>
        ) }
        <div className="cp_ct-trhead-header_actions">
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <GroupAccountsLabels
              socialAccount={ mappedConversations.socialAccount }
              onLabelApply={ reloadCommunicationMessages }
            />
          ) }
        </div>
      </div>
      <h2 className="cp_ct-subject">{ mappedConversations.subject }</h2>
      { messagesLastToFirst.length > 2 ? (
        <>
          { RenderMessages(topMessage, "first") }

          { showMiddleMessages ? (
            RenderMessages(middleMessages, "middle")
          ) : (
            <div className="cp_ct-show-more">
              <Button
                type="button"
                id="cp_ct-show-more-text"
                className="cp_ct-show-more-text"
                label={ `${ middleMessages.length }` }
                aria-label={ `${ middleMessages.length }` }
                onClick={ () => setShowMiddleMessages(true) }
              />
            </div>
          ) }
          { RenderMessages(lastMessage, "last") }
        </>
      ) : (
        RenderMessages(messagesLastToFirst)
      ) }
      <div className="cp_ct-message-replay">
        { (scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && mappedConversations.hasMessages && !showReplay) && (
          <div className="cp_ct-message-replay-button-section">
            <Button
              id="cp_ct-message-replay-form-actions-reply"
              isPrimary={ false }
              startIcon={ <Reply /> }
              onClick={ () => setShowReplay(true) }
              label={ translate("REPLY") }
            />
          </div>
        ) }
        { showReplay && (
          <ReplyToConversation handleCancel={ setShowReplay } />
        ) }
      </div>
    </div>
  )
}
