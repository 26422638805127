import React from "react"
import "./draft-edit-panel-empty.sass"
import { EMPTY_STATE_BOX_HEIGHT as boxHeight } from "../../../util/constant"

interface Props {
  parentIdName: string
}

export default function DraftMessageEmptyEditPanel({ parentIdName }: Props) {
  // ********************* Local state *********************
  const [ numberOfBoxes, setNumberOfBoxes ] = React.useState<number>(0)

  // ********************* React hooks *********************

  /**
   * Calculates the number of boxes that will fit in the parent element
   */
  const calculateNumberOfBoxes = React.useCallback(() => {
    // Extract the parent element
    const parentContainer = document.getElementById(parentIdName)

    // Extract the height of the parent
    if (parentContainer) {
      const parentHeight = parentContainer?.offsetHeight

      // Calculate the number of boxes that will fit
      const boxes = Math.floor(parentHeight / boxHeight) + 1

      // Set the number of boxes
      setNumberOfBoxes(boxes)
    }
  }, [ boxHeight ])

  /**
   * Handles resize of the screen to re-calculate when the window size changes
   */
  React.useEffect(() => {
    // Initial calculation of number of boxes
    calculateNumberOfBoxes()

    // Add window listener for number boxes
    window.addEventListener("resize", calculateNumberOfBoxes)

    // Clean up and remove when unmounted
    return () => {
      window.removeEventListener("resize", calculateNumberOfBoxes)
    }
  }, [ boxHeight ])

  // Return JSX
  return (
    <div className="draft-messages-view-empty">
      { Array.from({ length: numberOfBoxes }, (_, index) => (
        <div
          key={ index }
          className="cp_ghost-box"
        />
      )) }
    </div>
  )
}
