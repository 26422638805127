import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import { Link, Typography } from "@mui/material"
import EmptySplash from "../EmptySplash"
import { HREF_AI_SEARCH_GUIDE, HREF_CUSTOMER_SUPPORT } from "../../util/constant"

export default function ContentEmptySplash(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResultsTable" })

  return (
    <EmptySplash
      bodyText={ translate("Your search may be too specific. Try following these best practices") }
      headlineText={ translate("No matching posts!") }
    >
      <ul>
        <li>
          &#x2022;
          { " " }
          <Typography display="inline" component="p" className="body_large">
            <Link target="_blank" underline="hover" href={ HREF_AI_SEARCH_GUIDE }>{ translate("Learn More") }</Link>
            { " " }
            { translate("about using the Influential AI Search or about our filtered search.") }
          </Typography>
        </li>
        <li>
          { " " }
          &#x2022;
          { " " }
          <Typography display="inline" component="p" className="body_large">
            { translate("Create seperate entries in the Influential AI Search like Cat and Mom") }
          </Typography>
        </li>
        <li>
          { " " }
          &#x2022;
          { " " }
          <Typography display="inline" component="p" className="body_large">
            { translate("Reset a filter section and apply fewer filters next time.") }
          </Typography>
        </li>
        <li>
          { " " }
          &#x2022;
          { " " }
          <Typography display="inline" component="p" className="body_large">
            { translate("Expand the range of Engagement Rate or Followers") }
          </Typography>
        </li>
        <li>
          &#x2022;
          { " " }
          <Typography display="inline" component="p" className="body_large">
            { translate("Still no matching posts?") }
            { " " }
            <Link href={ HREF_CUSTOMER_SUPPORT } underline="hover" target="_blank">
              { translate("Contact Support") }
            </Link>
          </Typography>
        </li>
      </ul>
    </EmptySplash>
  )
}
