import React, { JSX, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

import { Stack } from "@mui/material"
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices"
import * as GraphQL from "../../graphql/search"
import { setSearchInput } from "../../state/searchAISlice"
import {
  cloneSearchInput,
  setSearchInputQueryParams,
  NETWORKS_AVAILABLE,
  NETWORK_REQUIRED_SCOPES,
} from "../../state/searchAISlice/helper"
import { useDispatch, useSelector } from "../../state/hooks"
import NetworkIconButton from "../NetworkButton"
import FilterPanel from "./FilterPanel"

import "./network-filter.sass"

interface Props {
  disabledNetworks?: GraphQL.Network[]
  /**
   * Show or hide the disabled networks.
   */
  hideDisabled?: boolean
}

export default function AINetworkFilter({ disabledNetworks = [], hideDisabled = false }: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AISearchFilter" })
  const dispatch = useDispatch()
  const searchAiInput = useSelector(({ searchAI }) => searchAI.searchAIInput)
  const { scopes } = useSelector(({ user }) => user)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const toggleNetwork = (network: GraphQL.Network) => {
    const newInput = cloneSearchInput(searchAiInput)
    const networkIncluded = newInput.socialNetworks.includes(network)
    newInput.socialNetworks = networkIncluded
      ? newInput.socialNetworks.filter((n) => n !== network)
      : [ ...newInput.socialNetworks, network ]
    dispatch(setSearchInput(newInput))
    setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
  }

  const SHOW_FILTERS = useMemo(() => {
    const result: {[key in GraphQL.Network]: boolean} = {
      [GraphQL.Network.Facebook]: false,
      [GraphQL.Network.Instagram]: false,
      [GraphQL.Network.Snapchat]: false,
      [GraphQL.Network.Tiktok]: false,
      [GraphQL.Network.Youtube]: false,
    }
    NETWORKS_AVAILABLE.forEach((network) => {
      result[network] = NETWORK_REQUIRED_SCOPES[network].every((requiredScope) => scopes.includes(requiredScope))
    })
    return result
  }, [ scopes ])

  const filterCount = useMemo(() => {
    const count = searchAiInput.socialNetworks.length
    return count
  }, [ searchAiInput.socialNetworks.length ])

  return (
    <FilterPanel
      panelID="search-ai-network-filter-panel"
      title={ translate("Network Filters") }
      panelIcon={ ImportantDevicesIcon }
      filterCount={ filterCount }
    >
      <Stack padding={ 2 } gap={ 1 }>
        <Stack direction="row" justifyContent="space-between">
          { NETWORKS_AVAILABLE.map((network) => {
            if (hideDisabled && SHOW_FILTERS[network]) {
              return null
            }
            return (
              <NetworkIconButton
                id={ `cp_component-ai-network-filter-button-${ network.toLowerCase() }` }
                key={ network }
                network={ network }
                isButtonDisabled={ disabledNetworks.includes(network) }
                isActive={ searchAiInput.socialNetworks.includes(network) && !disabledNetworks.includes(network) }
                onClick={ () => toggleNetwork(network) }
                additionalClasses="cp_component-ai-network-filter-button"
              />
            )
          }) }
        </Stack>
      </Stack>
    </FilterPanel>
  )
}
