import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import { FormLabel, InputAdornment } from "@mui/material"

import Input from "../Input"

import "./audience-filters.sass"

type Props = {
  customClass: string,
  resultValue: number,
  handleChange: (value: number | null) => void,
}

function handleChangeWrapper(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  handleChange: (value: number | null) => void,
): void {
  if (e.target.value === "") {
    handleChange(null)
    return
  }

  const newValue = parseInt(e.target.value, 10)
  if (Number.isNaN(newValue)) return

  if (newValue < 1) {
    handleChange(1)
    return
  }

  if (newValue > 100) {
    handleChange(100)
    return
  }

  handleChange(newValue)
}

export default function MinResultInput({
  resultValue,
  handleChange,
  customClass,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.MinResultsInput" })

  return (
    <div className={ `cp_component_search-ai-audience-min-results-${ customClass } cp_min_results-field` }>
      <FormLabel component="legend">
        { translate("MINIMUM RESULTS %") }
      </FormLabel>
      <Input
        label=""
        placeholder={ translate("Provide Value") }
        value={ resultValue }
        onChange={ (e) => handleChangeWrapper(e, handleChange) }
        InputProps={ {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: {
            min: 1,
            max: 100,
            step: "1",
          },
        } }
      />
    </div>
  )
}
