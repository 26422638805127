import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export type MessageRecipient = {
  accountName: string
  email: string
  socialAccountId: string
}

export interface ModalCreateMessage {
  modalCreateMessageOpen: boolean,
  commTemplates: Status<GraphQL.GetCommunicationTemplatesQuery>,
  commGroupEmails: Status<GraphQL.SearchCommunicationGroupNetworkAccountQuery>,
  commSubmisssion: Status<GraphQL.CreateConversationsMutation | null>,
  commSaveAsDraft: Status<GraphQL.CreateCommunicationsDraftMutation | null>,
  isBatch: boolean,
  selectedRecipients: MessageRecipient[],
}

// Initialize state
const initialState: ModalCreateMessage = {
  modalCreateMessageOpen: false,
  commTemplates: "init",
  commGroupEmails: "init",
  commSubmisssion: "init",
  commSaveAsDraft: "init",
  isBatch: false,
  selectedRecipients: [],
}

export const ModalCreateMessageSlice = createSlice({
  name: "ModalCreateMessageSlice",
  initialState,
  reducers: {
    setModalCreateMessageOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      modalCreateMessageOpen: action.payload,
    }),
    setCommTemplates: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationTemplatesQuery>>,
    ) => ({
      ...state,
      commTemplates: action.payload,
    }),
    setCommEmails: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCommunicationGroupNetworkAccountQuery>>,
    ) => ({
      ...state,
      commGroupEmails: action.payload,
    }),
    setCommSubmission: (
      state,
      action: PayloadAction<Status<GraphQL.CreateConversationsMutation | null>>,
    ) => ({
      ...state,
      commSubmisssion: action.payload,
    }),
    setCommSaveAsDraft: (
      state,
      action: PayloadAction<Status<GraphQL.CreateCommunicationsDraftMutation | null>>,
    ) => ({
      ...state,
      commSaveAsDraft: action.payload,
    }),
    setIsBatch: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isBatch: action.payload,
    }),
    setSelectedRecipients: (state, action: PayloadAction<MessageRecipient[]>) => ({
      ...state,
      selectedRecipients: action.payload,
    }),
    resetSelectedRecipients: (state) => ({ ...state, selectedRecipients: [] }),
    resetCommSubmission: (state) => ({ ...state, commSubmisssion: "init" }),
    resetCommSaveAsDraft: (state) => ({ ...state, commSaveAsDraft: "init" }),
  },
})

export const {
  setModalCreateMessageOpen,
  setCommTemplates,
  setCommEmails,
  setCommSubmission,
  setSelectedRecipients,
  resetSelectedRecipients,
  resetCommSubmission,
  setCommSaveAsDraft,
  resetCommSaveAsDraft,
  setIsBatch,
} = ModalCreateMessageSlice.actions
export default ModalCreateMessageSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchCommTemplates = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommTemplates("loading"))

  const commTemplates = await API.fetchCommTemplates()

  dispatch(setCommTemplates(commTemplates))
}

export const fetchCommEmails = (
  communicationGroupId: string,
  startsWith: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommEmails("loading"))

  const commEmails = await API.fetchCommunicationGroupEmails(communicationGroupId, startsWith)

  dispatch(setCommEmails(commEmails))
}

export const createCommSubmission = (
  {
    communicationGroupId,
    subject,
    body,
    recipients,
    attachments,
    enableUploadLink,
  }: GraphQL.CreateConversationsMutationVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommSubmission("loading"))

  const commSubmissionResponse = await API.createCommConversation(
    {
      communicationGroupId,
      subject,
      body,
      recipients,
      attachments,
      enableUploadLink,
    },
  )

  dispatch(setCommSubmission(commSubmissionResponse))
}

export const saveCommunicationAsDraft = (
  params: GraphQL.CreateCommunicationsDraftMutationVariables,
) => async (dispatch: Dispatch): Promise<void> => {
  // Set loading state
  dispatch(setCommSaveAsDraft("loading"))

  // Call to create
  const results = await API.createCommunicationsDraft(params)

  // Set the results
  dispatch(setCommSaveAsDraft(results))
}

/**
 * This will create a new batch conversation
 * @param params Values needed to create batch conversation
 * @returns void
 */
export const createBatchConversation = (params: {
  vars: GraphQL.CreateConversationBatchMutationVariables,
  onSuccess: () => void,
  onError: () => void,
}) => async (): Promise<void> => {
  // Call to create batch conversation
  const result = await API.createConversationBatch(params.vars)

  // Make callbacks
  if (API.isSuccess(result)) params.onSuccess()
  if (API.isError(result)) params.onError()
}
