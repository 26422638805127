import React, {
  useCallback,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import CloseIcon from "@mui/icons-material/Close"
import {
  Autocomplete,
  debounce,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material"

import Avatar from "../Avatar"
import Modal from "../Modal"
import Input, { Props as InputProps } from "../Input"
import Divider from "../Divider"
import Radio from "../Radio"
import Pill from "../Pill"
import "./style.sass"
import {
  Gender,
  UpdateSuggestionListSocialAccountMutationVariables,
  VerticalFragment,
} from "../../graphql"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector, useDispatch } from "../../state/hooks"
import * as API from "../../util/apiClient"
import {
  searchVerticals,
  setEditModalOpen,
  updateListSocialProfile,
} from "../../state/listSocialProfileSlice"

const filterVerticals = createFilterOptions<VerticalFragment>()

function ModalEditListProfile(): React.JSX.Element {
  const [ profileUserName, setProfileUserName ] = React.useState("")
  const [ profileImage, setProfileImage ] = React.useState("")
  const [ fullNameInput, setFullNameInput ] = React.useState("")
  const [ locationInput, setLocationInput ] = React.useState("")
  const [ genderInput, setGenderInput ] = React.useState<Gender | null>()
  const [ ageInput, setAgeInput ] = React.useState("")
  const [ bioInput, setBioInput ] = React.useState("")
  const [ verticalsInput, setVerticalsInput ] = React.useState<VerticalFragment[]>([])

  const {
    listProfile,
    loadingVerticals,
    loadedVerticals,
    editModalOpen,
    editModalLoadingPrimaryAction: loadingPrimaryAction,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)

  const dispatch = useDispatch()

  const resetState = () => {
    setProfileImage("")
    setFullNameInput("")
    setLocationInput("")
    setGenderInput(undefined)
    setAgeInput("")
    setBioInput("")
  }

  const fetchVerticals = async (startsWith: string) => dispatch(searchVerticals({ startsWith }))

  useEffect(() => { fetchVerticals("") }, [])

  useEffect(() => {
    if (API.isSuccess(listProfile)) {
      const {
        bio,
        age,
        name,
        gender,
        location,
        verticals,
        avatar,
        socialAccount: {
          userName,
          profilePictureUrl,
        },
      } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      setProfileUserName(`@${ userName }`)
      setProfileImage(avatar?.url.address || profilePictureUrl)
      setFullNameInput(name)
      setLocationInput(location || "")
      setGenderInput(gender)
      setAgeInput(age?.toString() || "")
      setBioInput(bio || "")
      setVerticalsInput(verticals)
    }
  }, [ listProfile, editModalOpen ])

  const onAutoCompleteChange = (event: React.SyntheticEvent, verticals: (string | VerticalFragment)[]) => {
    // remove the add keyword in filtered options
    const cleanedVerticals = verticals.map((vertical) => {
      if (typeof vertical === "string") return { name: vertical, id: "" }
      const { name, id } = vertical
      // eslint-disable-next-line quotes
      const newVerticalSubstring = `Add: "`
      if (!name.includes(newVerticalSubstring)) return vertical
      const cleanedVerticalName = name.replace(newVerticalSubstring, "").slice(0, -1)
      return { name: cleanedVerticalName, id }
    })
    setVerticalsInput(cleanedVerticals)
  }

  const autoCompleteFilter = (verticals: VerticalFragment[], params: FilterOptionsState<VerticalFragment>) => {
    const { inputValue } = params

    // Suggest the creation of a new value
    const isExisting = verticals.some(({ name }) => inputValue.toLowerCase() === name.toLowerCase())

    const filtered = filterVerticals(verticals, params)
      .filter(({
        id: filteredId,
      }) => !verticalsInput.some(({ id: selectedId }) => selectedId === filteredId))

    if (inputValue && !isExisting) filtered.push({ name: `Add: "${ inputValue }"`, id: inputValue })

    return filtered
  }

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalEditListProfile" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "component.Common" })

  const closeModal = () => {
    resetState()
    dispatch(setEditModalOpen(false))
  }

  const updateListProfileHandler = async () => {
    if (!listProfile || listProfile === "init" || listProfile === "loading" || API.isError(listProfile)) return
    const {
      id: listProfileId, suggestionList: { id: listId }, socialAccount: { id: socialAccountId },
    } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId

    const newListProfile: UpdateSuggestionListSocialAccountMutationVariables = {
      id: listProfileId,
      input: {
        name: fullNameInput,
        verticals: verticalsInput.map(({ id }) => id),
        age: parseInt(ageInput, 10),
        avatarId: null,
        bio: bioInput,
        gender: genderInput,
        location: locationInput,
        public: true,
      },
    }
    dispatch(updateListSocialProfile(newListProfile, listId, socialAccountId))
    closeModal()
  }

  const debouncedSearchVerticals = useCallback(debounce((e, inputValue) => {
    fetchVerticals(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchVerticals ])

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return translate("Save Changes")
  }, [ loadingPrimaryAction ])

  const handleAgeInput = (input: string) => {
    if (input === "") setAgeInput(input)

    const parsedInput = parseInt(input, 10)
    if (Number.isInteger(parsedInput) && parsedInput < 1000) {
      setAgeInput(parsedInput.toString())
    }
  }

  const renderContent = () => {
    if (listProfile === "init" || listProfile === "loading") {
      return <LoadingIndicator size={ 50 } flexWrapperEnabled={ true } />
    }

    if (API.isSuccess(listProfile)) {
      return (
        <div className="cp_component_modal-edit-list-profile_row cp_component_modal-edit-list-profile_panels">
          <div className="cp_component_modal-edit-list-profile_column">
            <div className="cp_component_modal-edit-list-profile-contact">
              <h4 className="headline_small">
                { profileUserName }
              </h4>
              <div className="cp_component_modal-edit-list-profile_photo">
                <Avatar src={ profileImage }>
                  <CameraAltOutlinedIcon fontSize="inherit" />
                </Avatar>
                <div>
                  { /* NOTE: Currently avatar upload isn't working on BE <AvatarUploader /> */ }
                  <h5>
                    { translate("Upload Thumbnail") }
                  </h5>
                  <h5 className="title_small">
                    { translate("Click to upload photo") }
                  </h5>
                </div>
              </div>
              <div className="cp_component_modal-edit-list-profile_column">
                <Input
                  value={ fullNameInput }
                  onChange={ (e) => setFullNameInput(e.currentTarget.value) }
                  fullWidth={ true }
                  placeholder={ translate("Enter Full Name") }
                  label={ translate("Full Name") }
                />
                <Input
                  value={ locationInput }
                  onChange={ (e) => setLocationInput(e.currentTarget.value) }
                  fullWidth={ true }
                  placeholder={ translate("Enter Location") }
                  label={ translate("Location") }
                />
                <Autocomplete
                  filterSelectedOptions={ true }
                  disabled={ verticalsInput.length === 5 }
                  value={ verticalsInput }
                  multiple={ true }
                  disableClearable={ true }
                  forcePopupIcon={ true }
                  getOptionLabel={ (vertical) => {
                  // Value selected with enter, right from the input
                    if (typeof vertical === "string") return vertical
                    return vertical.name
                  } }
                  ListboxProps={ {
                    className: "cp_component_modal-edit-list-profile_verticals-list",
                  } }
                  onInputChange={ debouncedSearchVerticals }
                  renderTags={ () => (
                    <div className="cp_component-modal-edit-list-profile_pills">
                      {

                        verticalsInput.map(({ id, name }) => (
                          <Pill
                            label={ name }
                            key={ id }
                            deleteIcon={ <CloseIcon /> }
                            onDelete={
                            () => setVerticalsInput(verticalsInput.filter(({ id: _id }) => _id !== id))
                          }
                          />
                        )) }

                    </div>
                  ) }
                  onChange={ onAutoCompleteChange }
                  filterOptions={ autoCompleteFilter }
                  selectOnFocus={ true }
                  clearOnBlur={ false }
                  handleHomeEndKeys={ true }
                  id="auto-complete-verticals"
                  options={ [ ...loadedVerticals ].sort() }
                  loading={ loadingVerticals }
                  loadingText={ <LoadingIndicator size={ 20 } /> }
                  renderOption={ (props, { name, id }, state, ownerState) => {
                    if (ownerState.loading && state.index > 0) return (<></>)
                    if (ownerState.loading) {
                      return (
                        <li
                          { ...props }
                        >
                          <LoadingIndicator size={ 20 } />
                        </li>
                      )
                    }
                    return (
                      <li
                        { ...props }
                        key={ id }
                      >
                        { name }
                      </li>
                    )
                  }
                    }
                  freeSolo={ true }
                  renderInput={ (params) => (
                    <Input
                      { ...params as InputProps }
                      placeholder={ translate("Type Tag Name") }
                      label={ translate("Tags (Categories)") }
                    />
                  )
                    }
                />
              </div>
            </div>

            <Divider />

            <div className="cp_component_modal-edit-list-profile-stats">
              <div className="cp_component_modal-edit-list-profile_row">
                <FormControl>
                  <FormLabel className="cp_component_modal_add_profile_gender-label">
                    { translate("Gender") }
                  </FormLabel>
                  <RadioGroup
                    onChange={ (_, genderInputOption) => setGenderInput(genderInputOption as Gender) }
                    className="cp_component_modal_add_profile_gender-radios"
                    value={ genderInput }
                  >

                    <FormControlLabel
                      value={ Gender.Female }
                      control={ <Radio /> }
                      label={ translate("Female") }
                      checked={ genderInput === Gender.Female }
                    />
                    <FormControlLabel
                      value={ Gender.Male }
                      control={ <Radio /> }
                      label={ translate("Male") }
                      checked={ genderInput === Gender.Male }
                    />
                    <FormControlLabel
                      value={ Gender.Other }
                      control={ <Radio /> }
                      label={ translate("Other") }
                      checked={ genderInput === Gender.Other }
                    />
                  </RadioGroup>
                </FormControl>
                <Input
                  className="cp_component_modal-edit-list-profile_input"
                  value={ ageInput }
                  onChange={ (e) => handleAgeInput(e.currentTarget.value) }
                  type="text"
                  fullWidth={ true }
                  InputProps={ {
                    inputProps: {
                      maxLength: 60,
                    },
                  } }
                  required={ true }
                  label={ translate("Age") }
                  placeholder={ translate("Enter Age") }
                />
              </div>
            </div>

            <Divider />
            <div className="cp_component_modal-edit-list-profile-bio">

              <div
                className="cp_component_modal-edit-list-profile_row"
              >
                <Input
                  className="cp_component_modal-edit-list-profile_input"
                  value={ bioInput }
                  onChange={ (e) => setBioInput(e.currentTarget.value) }
                  type="text"
                  multiline={ true }
                  maxRows={ 4 }
                  fullWidth={ true }
                  required={ true }
                  label={ translate("List Bio") }
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return <h2>{ translateCommon("An unexpected error occurred!") }</h2>
  }

  return (
    <Modal
      title={ translate("Public Account Details") }
      subtitle={
        translate("Changes made will not affect the Account, and will only be visible on the publicly viewable suggestion list.")
      }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ editModalOpen }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ updateListProfileHandler }
      maxWidth="xl"
      className="cp_component_modal-edit-list-profile"
      disabled={ !API.isSuccess(listProfile) }
    >
      { renderContent() }
    </Modal>
  )
}

export default ModalEditListProfile
