import React from "react"
import "./style.sass"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

type Props = {
  variant: "success" | "error" | "warning" | "info"
  message: string
}

export default function MessageBar({
  variant,
  message,
}: Props) {
  // Return JSX
  return (
    <div className={ `cp_component_message-bar ${ variant }` }>
      { variant === "error" && (
        <ErrorOutlineOutlinedIcon className="message-bar-icon" />
      ) }
      { variant === "info" && (
        <InfoOutlinedIcon className="message-bar-icon" />
      ) }
      { variant === "success" && (
        <DoneOutlinedIcon className="message-bar-icon" />
      ) }
      { variant === "warning" && (
        <WarningAmberOutlinedIcon className="message-bar-icon" />
      ) }
      <p className="body_small">{ message }</p>
    </div>
  )
}
