import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import * as SearchHelper from "../../../util/searchHelper"
import Button from "../../Button"
import SearchContentForm from "../../SearchContentForm"
import SearchFilter from "../../SearchFilter"
import SearchResults from "../../SearchResults"
import { SearchContentEllipsisMenuOptions } from "../../SearchResultsTable/ContentEllipsisMenuCell"
import { fetchSearchResults, setSearchInput } from "../../../state/searchSlice"
import { useDispatch, useSelector } from "../../../state/hooks"

import "./content-form.sass"
import "./search.sass"
import { ALL_NETWORKS } from "../../../util/types"
import LoadingIndicator from "../../LoadingIndicator"

type Props = {
  mountContext: "profile" | "searchSocialAccount" | "listSocialAccount"
  contentContextMenu: SearchContentEllipsisMenuOptions
}

export default function Search(props: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabContent" })
  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const profile = useSelector(({ profile: profileSlice }) => profileSlice.profile)
  const { listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const { profile: socialProfileState } = useSelector(({ socialProfile }) => socialProfile)
  // Set account search params and kick off search on mount
  // NOTE: Only relevant to Profile search.
  useEffect(() => {
    if (props.mountContext !== "profile" || !API.isSuccess(profile)) return

    const socialAccountIds = profile.payload.personality.socialAccounts
      .map(({ id }) => id)

    const socialAccountContentSearch = SearchHelper.initialSearchState()
    const contentInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_CONTENT,
    )
    if (contentInputQueryString) {
      const contentParams = SearchHelper.baseQueryToContentInput(contentInputQueryString)
      socialAccountContentSearch.contentParams.optionalImageTags = contentParams.optionalImageTags ?? []
    }

    socialAccountContentSearch.socialAccountIds = socialAccountIds
    socialAccountContentSearch.resultType = GraphQL.GodSearchType.Post
    socialAccountContentSearch.influencerParams.minFollowers = null
    dispatch(setSearchInput(socialAccountContentSearch))
    SearchHelper.setSearchInputQueryParams(
      socialAccountContentSearch,
      searchParams,
      setSearchParams,
    )

    dispatch(fetchSearchResults(true))
  }, [ profile ])

  // Set account search params and kick off search on mount
  // NOTE: Only relevant to List Social Account search.
  useEffect(() => {
    if (
      props.mountContext !== "searchSocialAccount"
      || !API.isSuccess(socialProfileState)
    ) {
      return
    }

    const socialAccountId = socialProfileState.payload.socialAccount.id
    const socialAccountContentSearch = SearchHelper.initialSearchState()
    const contentInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_CONTENT,
    )
    if (contentInputQueryString != null) {
      const newContentParams = {
        ...socialAccountContentSearch.contentParams,
        ...SearchHelper.baseQueryToContentInput(contentInputQueryString),
      }

      socialAccountContentSearch.contentParams = newContentParams
    }
    socialAccountContentSearch.socialAccountIds = [ socialAccountId ]
    socialAccountContentSearch.resultType = GraphQL.GodSearchType.Post
    socialAccountContentSearch.influencerParams.minFollowers = null
    dispatch(setSearchInput(socialAccountContentSearch))
    SearchHelper.setSearchInputQueryParams(
      socialAccountContentSearch,
      searchParams,
      setSearchParams,
    )

    dispatch(fetchSearchResults(true))
  }, [ socialProfileState ])

  // Set account search params and kick off search on mount
  // NOTE: Only relevant to List Social Account search.
  useEffect(() => {
    if (
      props.mountContext !== "listSocialAccount"
      || !API.isSuccess(listProfile)
    ) {
      return
    }

    const socialAccountId = listProfile.payload
      .suggestionListSocialAccountByListIdSocialAccountId.socialAccount.id

    const socialAccountContentSearch = SearchHelper.initialSearchState()
    socialAccountContentSearch.socialAccountIds = [ socialAccountId ]
    socialAccountContentSearch.resultType = GraphQL.GodSearchType.Post
    socialAccountContentSearch.influencerParams.minFollowers = null
    dispatch(setSearchInput(socialAccountContentSearch))
    SearchHelper.setSearchInputQueryParams(
      socialAccountContentSearch,
      searchParams,
      setSearchParams,
    )

    dispatch(fetchSearchResults(true))
  }, [ listProfile ])

  const searchFilterComponent = useMemo(() => {
    if (props.mountContext === "listSocialAccount") {
      return null
    }
    if (props.mountContext === "profile" && !API.isSuccess(profile)) {
      return <LoadingIndicator />
    }
    if (props.mountContext === "profile" && API.isSuccess(profile)) {
      const enabledNetworks = profile.payload.personality.socialAccounts.map((socialAccount) => socialAccount.network)
      const disabledNetworks = [ ...ALL_NETWORKS ].filter((network) => !enabledNetworks.includes(network))
      return <SearchFilter excludeSection="target" disabledNetworks={ disabledNetworks } hideDisabled={ true } />
    }
    return <SearchFilter excludeSection="target" />
  }, [ profile, props.mountContext ])

  // Profile mount context requires a profile.
  if (props.mountContext === "profile" && !API.isSuccess(profile)) {
    return null
  }

  // Search Social Account mount context requires a social account
  if (
    props.mountContext === "searchSocialAccount"
    && !API.isSuccess(socialProfileState)
  ) {
    return null
  }

  // List Social Account mount context requires a social account on
  // the list slice
  if (
    props.mountContext === "listSocialAccount"
    && !API.isSuccess(listProfile)
  ) {
    return null
  }

  return (
    <div className="cp_account-content-search_component">
      <div className="cp_account-content-search_component-row">
        <div className="cp_account-content-search_component-widget cp_account-content-search_component-column">
          <div className="cp_account-content-search_component-content-filter cp_component_search-content">
            { searchFilterComponent }
            <SearchContentForm showContextualRelevancy={ false } />
            <aside className="cp_page_search-button">
              <Button
                isEnabled={ true }
                isPrimary={ true }
                label={ translate("Search") }
                onClick={ () => dispatch(fetchSearchResults(true)) }
              />
            </aside>
          </div>
        </div>
        <div className="cp_account-content-search_component-widget cp_account-content-search_component-column">
          <div className="cp_page_search-container">
            <div className="cp_page_search-results">
              <SearchResults
                mountContext="search"
                showFormFields={ false }
                isSelectable={ false }
                contentContextMenu={ props.contentContextMenu }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
