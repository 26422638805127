import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  gql,
  useMutation,
  useQuery,
} from "@apollo/client"
import { useParams } from "react-router-dom"
import UserListCard, { UserCardListRow } from "./UserListCard"
import ModalAddCategoriesToCampaign from "./Modals/ModalAddCategoriesToCampaign"

const CAMPAIGN_CATEGORIES_CONTAINER_FRAGMENT = gql`
  fragment CampaignCategoriesContainerForCard on CampaignCategoriesContainer {
    id
    categoryNames
  }
`

const QUERY = gql`
  query CampaignCategoriesCard($campaignId: ID!) {
    campaignCategories(campaignId: $campaignId) {
      ...CampaignCategoriesContainerForCard
    }
  }

  ${ CAMPAIGN_CATEGORIES_CONTAINER_FRAGMENT }
`

const REMOVE_MUTATION = gql`
  mutation CampaignCategoriesCard_remove($input: RemoveCategoriesFromCampaignInput!) {
    removeCategoriesFromCampaign(input: $input) {
      campaignCategories {
        ...CampaignCategoriesContainerForCard
      }
    }
  }

  ${ CAMPAIGN_CATEGORIES_CONTAINER_FRAGMENT }
`

const useModalState = (props?: { initialVisibleState?: boolean }) => {
  const [ visible, setVisible ] = useState(props?.initialVisibleState ?? false)

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
  }

  return {
    visible,
    open,
    close,
  }
}

export default function CampaignCategoriesCard() {
  const { campaignID: campaignId } = useParams()

  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignOverviewDetails" })

  const query$ = useQuery<{
    campaignCategories: {
      id: string;
      categoryNames: string[];
    };
  }>(QUERY, {
    variables: {
      campaignId,
    },
  })

  const [ removeCategories, removeCategories$ ] = useMutation(REMOVE_MUTATION)

  const addModal = useModalState()

  const categoryNames: string[] = query$.data?.campaignCategories.categoryNames || []

  const rows: UserCardListRow[] = categoryNames.map((categoryName) => ({
    id: categoryName,
    name: categoryName,
    showRemoveButton: true,
  }))

  const handleAddClick = () => {
    addModal.open()
  }

  const handleRemoveClick = (event: { row: UserCardListRow }) => {
    const categoryName = event.row.name

    removeCategories({
      variables: {
        input: {
          campaignId,
          categoryNames: [ categoryName ],
        },
      },
    })
  }

  return (
    <>
      <ModalAddCategoriesToCampaign campaignId={ campaignId! } open={ addModal.visible } onClose={ addModal.close } />

      <UserListCard
        loading={ query$.loading || removeCategories$.loading }
        rows={ rows }
        title={ translate("Campaign Categories") }
        emptyText={ translate("No Campaign Categories Added") }
        onButtonClick={ handleAddClick }
        onRemoveClick={ handleRemoveClick }
      />
    </>
  )
}
