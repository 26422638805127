import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import { ReadableNetwork } from "../../util/constant"
import { fetchCurrentUser, setOpenOAuthContinueModal } from "../../state/userSlice"
import * as API from "../../util/apiClient"
import LoadingIndicatorCard from "../LoadingIndicatorCard"

export default function UserOauthContinueModal() {
  // Field variabls/imported functions
  const { t: translate } = useTranslation([], { keyPrefix: "component.OAuthUserContinueModal" })
  const dispatch = useDispatch()

  // Global state values
  const {
    openOAuthContinueModal: open,
    oauthModalNetwork: network,
    user: currentUser,
  } = useSelector(({ user }) => user)

  // Local state
  const [ waitingForOauth, setWaitingForOauth ] = React.useState<boolean>(false)

  // React hooks

  React.useEffect(() => {
    if (API.isSuccess(currentUser)) {
      closeAction()
    }
  }, [ currentUser ])

  // Functions

  const closeAction = () => {
    // close the dialog box
    dispatch(setOpenOAuthContinueModal(false))
  }

  const handleContinue = () => {
    // Placeholder
    setWaitingForOauth(true)
    dispatch(fetchCurrentUser())
  }

  return (
    <Modal
      className="cp_component_modal_oauth-user-continue"
      title={ `${ translate("OAuth") } ${ ReadableNetwork[network] } ${ translate("Account") }` }
      primaryLabel={ translate("Continue") }
      secondaryLabel={ translate("Cancel") }
      open={ open }
      closeAction={ closeAction }
      primaryAction={ handleContinue }
      secondaryAction={ closeAction }
      disabled={ waitingForOauth }
      secondaryDisabled={ waitingForOauth }
    >
      <div className="cp_component_modal_oauth-continiue">
        { waitingForOauth
          ? <LoadingIndicatorCard />
          : <p className="body_medium">{ translate("Please press continue once you have completed OAuth.") }</p>
        }
      </div>
    </Modal>
  )
}
