import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { DeliverableStatus } from "../../graphql"
import * as GraphQL from "../../graphql"

export interface StatusDeliverableProps extends
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  deliverableStatus: DeliverableStatus
  postTracker?: GraphQL.CampaignQuery["campaign"]["deliverables"][0]["postTracker"]
  /**
   * @default 'md'
   */
  size?: "sm" | "md"
  hideLabel?: boolean
}

export default function StatusDeliverable({
  deliverableStatus,
  postTracker,
  size = "md",
  className = "",
  hideLabel = false,
  ...props
}: StatusDeliverableProps) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.StatusDeliverable" })
  const status = useMemo(() => {
    switch (deliverableStatus) {
      case DeliverableStatus.Pending:
        return "pending"
      case DeliverableStatus.AwaitingContent:
        return "awaiting-content"
      case DeliverableStatus.Live:
        return "live"
      case DeliverableStatus.Uploaded:
        return "uploaded"
      case DeliverableStatus.Finalized:
        return "finalized"
      default:
        return "pending"
    }
  }, [ deliverableStatus ])
  const classes = [ "cp_component_deliverable-status",
    `cp_component_deliverable-status_${ status }`,
    `cp_component_deliverable-status_${ size }`,
  ]
  if (className) classes.push(className)
  const finalClassName = classes.join(" ")

  /**
   * Determines the current status of the deliverable tracking
   * @returns True/false if tracking
   */
  const determineIfTracking = (): boolean => {
    // Check to see if the label should be hidden and that post tracker exists
    if (!hideLabel && postTracker) {
      // Determine if enabled and it's active
      if (postTracker.enabled && postTracker.status === GraphQL.PostTrackerStatus.Active) {
        return true
      }
    }

    // Default result
    return false
  }

  /**
   * Determines the current status to see if it was tracked, but isn't currently
   * @returns True/False if tracked
   */
  const determineIfTracked = (): boolean => {
    // Check to see if the label should be hidden and that post tracker exists
    if (!hideLabel && postTracker) {
      // Determine if enabled and it's not active
      if (postTracker.enabled && postTracker.status !== GraphQL.PostTrackerStatus.Active) {
        return true
      }

      // If not enabled, then it most likely was at some point so set as tracked
      if (!postTracker.enabled) {
        return true
      }
    }

    // Return default result
    return false
  }

  // Determimne what tracking status is
  const isTrackingEnabled = determineIfTracking()
  const isTracked = determineIfTracked()

  return (
    <div className="cp_component_deliverable-container">
      <div className="cp_component_deliverable-status">
        <span className={ finalClassName } { ...props } />
        {
          hideLabel ? null : (
            <span className="cp_component_deliverable-status-label">
              { translate(deliverableStatus) }
            </span>
          )
        }
      </div>
      <div className="cp_component_deliverable-post">
        { isTrackingEnabled && (
          <div className="cp_component_deliverable-post-tracking">
            { translate("(TRACKING)") }
          </div>
        ) }
        { isTracked && (
          <div className="cp_component_deliverable-post-tracking">
            { translate("(TRACKED)") }
          </div>
        ) }
      </div>
    </div>
  )
}
