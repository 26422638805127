import React from "react"
import { ApolloSandbox } from "@apollo/sandbox/react"
import "./style.sass"

type Props = {
  endpoint: string,
}

export default function GraphQLSandbox({ endpoint }: Props) {
  return (
    <div className="page_graphql-sandbox">
      <ApolloSandbox
        initialEndpoint={ endpoint }
        initialState={ {
          includeCookies: true,
        } }
      />
    </div>
  )
}
