import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import { HighlightOff } from "@mui/icons-material"
import Modal from "../Modal"
import { INFLUENTIAL_PRIVACY_POLICY_URL, ReadableNetwork } from "../../util/constant"
import { useDispatch, useSelector } from "../../state/hooks"
import { setOpenOAuthContinueModal, setOpenOAuthModal } from "../../state/userSlice"
import Button from "../Button"

export default function OAuthUserAccountModal() {
  // Field variabls/imported functions
  const { t: translate } = useTranslation([], { keyPrefix: "component.OAuthUserAccountModal" })
  const dispatch = useDispatch()

  // Global state values
  const {
    openOauthModal: open,
    oauthModalNetwork: network,
    oauthModalUrl: authUrl,
  } = useSelector(({ user }) => user)

  // Functions

  const closeAction = () => {
    // Close the modal
    dispatch(setOpenOAuthModal(false))
  }

  const handleContinue = () => {
    // Open auth url in separate tab
    window.open(authUrl, "_blank")
    dispatch(setOpenOAuthModal(false))
    dispatch(setOpenOAuthContinueModal(true))
  }

  const getCustomBRActions = (): React.JSX.Element[] => {
    // Create array
    const results: React.JSX.Element[] = []

    // Add buttons/elements
    results.push((
      <Button
        id="cp_component_modal-privacy-policy"
        isTextButton={ true }
        onClick={ () => { window.open(INFLUENTIAL_PRIVACY_POLICY_URL, "_blank") } }
        label="Review Privacy Policy"
      />
    ))

    // Return the buttons
    return results
  }

  return (
    <Modal
      className="cp_component_modal_oauth-user-account"
      title={ `${ translate("Connect") } ${ ReadableNetwork[network] }` }
      primaryLabel={ translate("Continue") }
      open={ open }
      closeAction={ closeAction }
      primaryAction={ handleContinue }
      hasSecondaryButton={ false }
      customBottomRightActions={ getCustomBRActions() }
    >
      <div className="cp_component_modal_oauth-user-account_container">
        <p className="body_medium">
          {
            `${ translate("When you connect your") }
            ${ ReadableNetwork[network] }
            ${ translate("account, be sure to grant us full permissions (") }${ ReadableNetwork[network] }
            ${ translate("will ask you to grant Influential different permissions). It’s important to click") }`
          }
          &nbsp;
          <b>{ translate("\"Allow\"") }</b>
          &nbsp;
          { translate("for everything so that we can verify it’s really you, and to secure a proper connection.") }
        </p>
        <p className="bold-message">
          { `${ translate("Don't stress, we absolutely WILL NOT") }:` }
        </p>
        <ul className="will-not-list-items">
          <li className="will-not-list-items_item">
            <HighlightOff className="list-item-icon" />
            { translate("Post to your timeline without your permission") }
          </li>
          <li className="will-not-list-items_item">
            <HighlightOff className="list-item-icon" />
            { translate("Comment on or like content on your behalf") }
          </li>
          <li className="will-not-list-items_item">
            <HighlightOff className="list-item-icon" />
            { translate("Read or reply to your direct messages") }
          </li>
        </ul>
      </div>
    </Modal>
  )
}
