import * as React from "react"
import { useTranslation } from "react-i18next"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import NearMeIcon from "@mui/icons-material/NearMe"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

import {
  AccountCircleOutlined,
  Folder,
  MailOutline,
  SupervisedUserCircleOutlined,
} from "@mui/icons-material"
import Dropdown from "../Dropdown"
import "./style.sass"
import Divider from "../Divider"
import { useDispatch, useSelector } from "../../state/hooks"
import { setEditMode, setOpenModal } from "../../state/profileSlice"
import { openNewCampaignModal } from "../../state/campaignModalSlice"
import { setModalNewTrackerOpen } from "../../state/ModalNewTrackerSlice"
import { setListGroupModalOpen } from "../../state/templateSlice"
import { setCreateCustomerModalOpen } from "../../state/createCustomer"
import { setCommGroupModalOpen } from "../../state/ModalCommGroupSlice"
import { Scope } from "../../util/types"
import { setListModalMode, setListModalOpen } from "../../state/listModalFormSlice"
import { openSelectCampaignModal } from "../../state/selectCampaignModalSlice"
import { openCommsCreateTemplate } from "../../state/commsCreateEditTemplateModal"

export default function NewDropdown() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NewDropdown" })

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const dispatch = useDispatch()

  const { scopes } = useSelector(({ user }) => user)

  const handleListGroupClick = () => {
    dispatch(setListGroupModalOpen(true))
    setAnchorEl(null)
  }

  const handleListFormModalClick = () => {
    dispatch(setListModalMode({ isEditMode: false, listId: "" }))
    dispatch(setListModalOpen(true))
    setAnchorEl(null)
  }

  function handleSocialTrackerClick() {
    dispatch(setModalNewTrackerOpen(true))
  }

  const onDeliverableClick = () => {
    dispatch(openSelectCampaignModal({}))
    setAnchorEl(null)
  }

  const handleCommGroupClick = () => {
    dispatch(setCommGroupModalOpen(true))
    setAnchorEl(null)
  }

  const handleTemplateClick = () => {
    dispatch(openCommsCreateTemplate())
    setAnchorEl(null)
  }

  return (
    <Dropdown
      id="cp_component_new-dropdown-button"
      buttonTitle={ translate("+ New") }
      buttonType="split"
      customAnchorEl={ anchorEl }
      setCustomAnchorEl={ setAnchorEl }
    >
      <List className="cp_component_new-dropdown" disablePadding={ true }>
        { scopes.includes(Scope.FEATURE_INFLUENCERS) && scopes.includes(Scope.INFLUENCERS_CREATE) ? (
          <ListItem
            disablePadding={ true }
            disableGutters={ true }
          >
            <ListItemButton
              id="cp_component_new-dropdown-profile-button"
              onClick={ () => {
                dispatch(setOpenModal(true))
                dispatch(setEditMode(false))
                setAnchorEl(null)
              } }
            >
              <ListItemIcon>
                <AccountCircleOutlined className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Profile") } />
            </ListItemButton>
          </ListItem>
        ) : null
        }
        { scopes.includes(Scope.FEATURE_INFLUENCER_LIST) && (
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton
            id="cp_component_new-dropdown-list-button"
            onClick={ handleListFormModalClick }
          >
            <ListItemIcon>
              <FormatListBulletedIcon className="list-item-icon" />
            </ListItemIcon>
            <ListItemText primary={ translate("List") } />
          </ListItemButton>
        </ListItem>
        ) }
        { scopes.includes(Scope.FEATURE_INFLUENCER_LIST) && (
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton
            id="cp_component_new-dropdown-list-group-button"
            onClick={ handleListGroupClick }
          >
            <ListItemIcon>
              <Folder className="list-item-icon" />
            </ListItemIcon>
            <ListItemText primary={ translate("List Group") } />
          </ListItemButton>
        </ListItem>
        ) }

        {
          ((((scopes.includes(Scope.FEATURE_INFLUENCERS) && scopes.includes(Scope.INFLUENCERS_CREATE))
          || scopes.includes(Scope.FEATURE_INFLUENCER_LIST)) && (scopes.includes(Scope.FEATURE_CAMPAIGN)
          && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          && scopes.includes(Scope.CAMPAIGN_TYPE_OPTIONS))))
          && <Divider />
        }

        { scopes.includes(Scope.FEATURE_CAMPAIGN)
          && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          // && scopes.includes(Scope.CAMPAIGN_TYPE_OPTIONS)
          && (
          <ListItem
            disablePadding={ true }
            disableGutters={ true }
          >
            <ListItemButton
              onClick={ onDeliverableClick }
              id="cp_component_new-dropdown-deliverable-button"
            >
              <ListItemIcon>
                <PlayCircleOutlinedIcon className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Deliverable") } />
            </ListItemButton>
          </ListItem>
          ) }

        { scopes.includes(Scope.FEATURE_CAMPAIGN)
          && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          && scopes.includes(Scope.CAMPAIGN_TYPE_OPTIONS)
          && (
          <ListItem
            disablePadding={ true }
            disableGutters={ true }
          >
            <ListItemButton
              id="cp_component_new-dropdown-campaign-button"
              onClick={ () => {
                dispatch(openNewCampaignModal())
                setAnchorEl(null)
              } }
            >
              <ListItemIcon>
                <TrackChangesIcon className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Campaign") } />
            </ListItemButton>
          </ListItem>
          ) }
        {
            scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT) && (
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_component_new-dropdown-customer-button"
                onClick={ () => {
                  dispatch(setCreateCustomerModalOpen(true))
                  setAnchorEl(null)
                } }
              >
                <ListItemIcon>
                  <SupervisedUserCircleOutlined className="list-item-icon" />
                </ListItemIcon>
                <ListItemText primary={ translate("Customer") } />
              </ListItemButton>
            </ListItem>
            ) }
        { scopes.includes(Scope.FEATURE_SOCIAL_TRACKER) ? (
          <ListItem
            disablePadding={ true }
            disableGutters={ true }
          >
            <ListItemButton
              id="cp_component_new-dropdown-social-tracker"
              onClick={ () => {
                handleSocialTrackerClick()
              } }
            >
              <ListItemIcon>
                <NearMeIcon className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Social Tracker") } />
            </ListItemButton>
          </ListItem>
        ) : null
        }
        { (scopes.includes(Scope.DEVELOPMENT)
        && scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT)
        && scopes.includes(Scope.FEATURE_COMMUNICATIONS))
         && (
         <ListItem
           disablePadding={ true }
           disableGutters={ true }
         >
           <ListItemButton
             id="cp_component_new-dropdown-communication-group-button"
             onClick={ handleCommGroupClick }
           >
             <ListItemIcon>
               <Folder className="list-item-icon" />
             </ListItemIcon>
             <ListItemText primary={ translate("Communication Group") } />
           </ListItemButton>
         </ListItem>
         ) }
        { scopes.includes(Scope.DEVELOPMENT)
          && scopes.includes(Scope.FEATURE_COMMUNICATIONS)
         && scopes.includes(Scope.COMMUNICATIONS_TEMPLATE_MANAGEMENT) && (
         <ListItem
           disablePadding={ true }
           disableGutters={ true }
         >
           <ListItemButton
             id="cp_component_new-dropdown-template-button"
             onClick={ handleTemplateClick }
           >
             <ListItemIcon>
               <MailOutline className="list-item-icon" />
             </ListItemIcon>
             <ListItemText primary={ translate("Template") } />
           </ListItemButton>
         </ListItem>
        ) }
      </List>
    </Dropdown>

  )
}
