import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { DeleteOutlineOutlined } from "@mui/icons-material"
import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import { useDispatch, useSelector } from "../../../state/hooks"
import CommGroupEmailsAutocomplete, { NetworkInfo } from "../../ModalCreateMessage/CommGroupEmailsAutocomplete"
import {
  createBatchConversation,
  createCommSubmission,
  fetchCommTemplates,
} from "../../../state/modalCreateMessageSlice"
import {
  CommunicationDraftData,
  Toast,
  UploadedFile,
} from "../../../util/types"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"
import DraftMessageEmptyEditPanel from "./DraftEditPanelEmpty"
import ErrorHandler from "../../ErrorHandler"
import Select from "../../Select"
import MenuItem from "../../MenuItem"
import Checkbox from "../../Checkbox"
import Divider from "../../Divider"
import Input from "../../Input"
import RichTextEditor from "../../RichTextEditor"
import Button from "../../Button"
import { attachCommFile } from "../../../util/miscHelper"
import { deleteDraftMessage, resetListViewState } from "../../../state/commEmailListView"
import { pushToast } from "../../../state/toastSlice"

export default function DraftMessageEditPanel() {
  // ********************* Variables *********************
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationDraftEdit" })
  const dispatch = useDispatch()
  const { commGroupID } = useParams()

  // ********************* Redux state *********************
  const { draftMessage } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const { commTemplates } = useSelector(({ ModalCreateMessageSlice }) => ModalCreateMessageSlice)

  // ********************* Local state *********************
  const [ selectedTemplate, setSelectedTemplate ] = React.useState("")
  const [ isContentUploadLink, setIsContentUploadLink ] = React.useState(false)
  const [ subject, setSubject ] = React.useState("")
  const [ commTo, setCommTo ] = React.useState<NetworkInfo[] | []>([])
  const [ commCCEmails, setCommCCEmails ] = React.useState<NetworkInfo[] | []>([])
  const [ showCommCC, setShowCommCC ] = React.useState(false)
  const [ editorContent, setEditorContent ] = React.useState("")
  const [ attachments, setAttachments ] = React.useState<GraphQL.ConversationAttachmentInput[]>([])
  const [ uploadedfiles, setUploadedFiles ] = React.useState<UploadedFile[]>([])
  const [ sendEnabled, setSendEnabled ] = React.useState<boolean>(true)
  const [ isBatch, setIsBatch ] = React.useState<boolean>(false)

  // ********************* React hooks *********************
  React.useEffect(() => {
    // Fetch communication templates, if in init state
    if (commTemplates === "init") {
      dispatch(fetchCommTemplates())
    }

    // Clean up
    return clearState
  }, [ ])

  React.useEffect(() => {
    if (API.isSuccess(draftMessage)) {
      const { data } = draftMessage.payload.getCommunicationGroupDraft
      const draft: CommunicationDraftData = JSON.parse(data)
      if (draft.template) setSelectedTemplate(draft.template)
      setIsContentUploadLink(draft.enableUploadLink)
      setSubject(draft.subject)
      setCommTo(draft.toList.map((to): NetworkInfo => ({
        accountName: to.socialAccountUsername,
        email: to.email,
        socialAccountId: to.socialAccountId,
      })))
      setCommCCEmails(draft.ccList.map((cc): NetworkInfo => ({
        accountName: cc.socialAccountUsername,
        email: cc.email,
        socialAccountId: cc.socialAccountId,
      })))
      setEditorContent(draft.richTextEditorState.content)
      setAttachments(draft.richTextEditorState.attachments)
      setUploadedFiles(draft.richTextEditorState.uploadedfiles)
      setIsBatch(draft.mode === GraphQL.CommunicationGroupDraftType.Batch)
    } else clearState()
  }, [ draftMessage ])

  // ********************* functions *********************

  /**
   * Reset all local state values
   */
  const clearState = () => {
    setSelectedTemplate("")
    setIsContentUploadLink(false)
    setSubject("")
    setCommTo([])
    setCommCCEmails([])
    setEditorContent("")
    setAttachments([])
    setUploadedFiles([])
    setSendEnabled(true)
  }

  /**
   * Extract template with given ID and populate the rich text editor with
   * the template content
   * @param templateId The ID value for the template
   */
  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId)
    // filter template and add to editor
    if (API.isSuccess(commTemplates)) {
      const { getCommunicationTemplates } = commTemplates.payload
      if (getCommunicationTemplates) {
        const currTemplate = getCommunicationTemplates.find((template) => template.id === templateId)
        if (currTemplate) {
          setEditorContent(currTemplate.body)
        }
      }
    }
  }

  /**
   * Toggle between showing and hiding the CC value
   */
  const handleShowCommCC = () => {
    setShowCommCC(!showCommCC)
  }

  /**
   * Attach a file to the message and update the fields
   * @param file The file being uploaded
   */
  const handleFileAttachment = async (file: File) => {
    const fileStatus = await attachCommFile(file)
    if (fileStatus.id) {
      setAttachments([
        ...attachments,
        {
          contentId: fileStatus.content.id,
          name: fileStatus.name,
          thumbnailId: fileStatus.thumbnail ? fileStatus.thumbnail.id : undefined,
        },
      ])

      setUploadedFiles([
        ...uploadedfiles,
        {
          url: fileStatus.content.url,
          id: fileStatus.content.id,
          name: fileStatus.name,
        },
      ])
    }
  }

  /**
   * Delete an attachment from the draft message
   * @param id The file ID
   */
  const handleDeleteUploadedFile = (id: string) => {
    const matchedFile = uploadedfiles.find((uploadedfile) => uploadedfile.id === id)

    if (matchedFile) {
      setUploadedFiles((prevUrls) => prevUrls.filter((thumbnail) => thumbnail.id !== id))
      setAttachments((prevAttachments) => prevAttachments.filter((attachment) => attachment.contentId !== id))
    }
  }

  /**
   * Get all the recipients and their information for preperation to
   * send the email
   * @returns A list of to/cc emails and social account id for each
   */
  const getRecipients = () => commTo.map((recipient) => ({
    ccEmailAddresses: commCCEmails.map((cc) => cc.email),
    socialAccountId: recipient.socialAccountId,
    toEmailAddress: recipient.email,
  }))

  /**
   * Send the draft meail and remove it from draft messages
   */
  const handleSendEmail = () => {
    if (commGroupID && API.isSuccess(draftMessage)) {
      // Disable send, so they don't click on it twice
      setSendEnabled(false)

      // Create message to send
      const messageInfo = {
        subject,
        enableUploadLink: isContentUploadLink,
        attachments,
        body: editorContent,
        communicationGroupId: commGroupID,
        recipients: getRecipients(),
      }

      if (isBatch) {
        dispatch(createBatchConversation({
          vars: messageInfo,
          onSuccess: () => {
            const toast: Toast = {
              type: "success",
              message: translate("Success! Batch message sent."),
            }
            dispatch(pushToast(toast))
          },
          onError: () => {
            const toast: Toast = {
              type: "error",
              message: translate("Failed to create batch message. try again later."),
            }
            dispatch(pushToast(toast))
          },
        }))
      } else dispatch(createCommSubmission(messageInfo))

      // Delete draft email
      dispatch(deleteDraftMessage({
        vars: { communicationGroupDraftId: draftMessage.payload.getCommunicationGroupDraft.id },
        onSuccess: () => {
          const toast: Toast = {
            type: "success",
            message: translate("Successfully sent message."),
          }
          dispatch(pushToast(toast))
          dispatch(resetListViewState())
          clearState()
        },
        onError: () => {
          const toast: Toast = {
            type: "error",
            message: translate("Failed to send draft message."),
          }
          dispatch(pushToast(toast))
        },
      }))
    }
  }

  // ********************* other *********************

  // Loading draft message, show loading indicator
  if (draftMessage === "loading") {
    return (
      <div className="cp_component-drafts-folder-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  // No draft message has been fetched, show an empty screen
  if (draftMessage === "init") {
    return (
      <div id="cp_comm-messages-empty" className="cp_component-drafts-folder-view">
        <DraftMessageEmptyEditPanel parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  // Error loading draft message, handle the error
  if (draftMessage.status === "error") return <ErrorHandler />

  // Return JSX
  return (
    <div className="cp_component-drafts-folder-view">
      <section className="draft-messages-view_edit-header">
        <DeleteOutlineOutlined className="hidden-icon-for-spacing" />
      </section>
      <section className="draft-messages-view_edit-template">
        <div className="edit-template-select">
          <div className="edit-template-select-container">
            <p className="edit-template-select-container-label">{ translate("Template") }</p>
            { API.isSuccess(commTemplates) && (
            <Select
              id="commTemplateSelect"
              className="edit-template-select-container-select"
              label=""
              labelId="modal-manage-brand-managers-label"
              fullWidth={ true }
              value={ selectedTemplate || "" }
              onChange={ (e) => handleTemplateChange(e.target.value) }
              displayEmpty={ true }
              menuItems={ [
                // Placeholder MenuItem
                <MenuItem key="placeholder" value="" disabled={ true }>
                  <em>{ translate("Select message template") }</em>
                </MenuItem>,
                // Dynamically generated menu items
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...commTemplates.payload?.getCommunicationTemplates.map((template) => (
                  <MenuItem key={ template.id } value={ template.id }>
                    { template.name }
                  </MenuItem>
                )),
              ] }
            />
            ) }
            <div className="upload-link-container">
              <Checkbox
                id="commTemplateCheckbox"
                color="primary"
                checked={ isContentUploadLink }
                onChange={ () => setIsContentUploadLink(!isContentUploadLink) }
              />
              <label
                className="upload-link-container-label"
                htmlFor="commTemplateCheckbox"
              >
                { translate("Content Upload Link") }
              </label>
            </div>
          </div>
        </div>
      </section>
      <Divider />
      <section className="draft-messages-view_edit-configure">
        <div className="draft-message-fields">
          <p className="draft-message-fields-label">{ `${ translate("TO") }:` }</p>
          { commGroupID && (
          <CommGroupEmailsAutocomplete
            communicationGroupId={ commGroupID }
            setSelectedEmails={ setCommTo }
            selectedEmails={ commTo }
            setShowCommCC={ handleShowCommCC }
          />
          ) }
          { (commGroupID && showCommCC) && (
            <>
              <p className="draft-message-fields-label">CC:</p>
              <CommGroupEmailsAutocomplete
                communicationGroupId={ commGroupID }
                setSelectedEmails={ setCommCCEmails }
                selectedEmails={ commCCEmails }
                setShowCommCC={ null }
                size="small"
              />
            </>
          ) }
          <Input
            className="draft-message-fields-subject"
            id="draft-subject"
            label=""
            placeholder="Subject Line:"
            fullWidth={ true }
            value={ subject }
            onChange={ (e) => setSubject(e.target.value) }
          />
          <RichTextEditor
            editorContent={ editorContent }
            setEditorContent={ setEditorContent }
            handleFileAttachment={ handleFileAttachment }
            uploadedFiles={ uploadedfiles }
            onDeleteThumbnail={ (id) => handleDeleteUploadedFile(id) }
          />
        </div>
      </section>
      <section className="draft-messages-view_edit-actions">
        <Button
          isEnabled={ sendEnabled }
          isPrimary={ true }
          label={ translate("Send") }
          onClick={ handleSendEmail }
        />
      </section>
    </div>
  )
}
