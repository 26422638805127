import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  Avatar,
  Paper,
  Typography,
  Box,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"

interface User {
  id: string
  fullName: string
  initials: string
  avatarUrl: string
}

interface UserAssignment {
  userId: string
  permission: "FINALIZE" | "APPROVE" | "COMMENT"
  createdAt: string
  updatedAt: string
  user: User
}

export interface ReviewerGroup {
  id: string
  displayName: string
  description: string
  userAssignments: UserAssignment[]
}

interface ReviewerGroupsListProps {
  groups: ReviewerGroup[]
  onSelectGroup: (group: ReviewerGroup) => void
  loading: boolean
}

export default function ReviewerGroupsList({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  groups, onSelectGroup, loading,
}: ReviewerGroupsListProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ReviewerGroupsList" })
  const [ groupMenuAnchor, setGroupMenuAnchor ] = useState<null | HTMLElement>(null)
  const [ userMenuAnchor, setUserMenuAnchor ] = useState<null | HTMLElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedGroup, setSelectedGroup ] = useState<ReviewerGroup | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedUser, setSelectedUser ] = useState<UserAssignment | null>(null)
  const handleGroupMenuOpen = (event: React.MouseEvent<HTMLElement>, group: ReviewerGroup) => {
    setGroupMenuAnchor(event.currentTarget)
    setSelectedGroup(group)
  }

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>, assignment: UserAssignment) => {
    event.stopPropagation()
    setUserMenuAnchor(event.currentTarget)
    setSelectedUser(assignment)
  }

  const handleMenuClose = () => {
    setGroupMenuAnchor(null)
    setUserMenuAnchor(null)
    setSelectedGroup(null)
    setSelectedUser(null)
  }

  return (
    <div className="reviewer-groups-list">
      <div className="groups-grid">
        { loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" width="100vw">
            <LoadingIndicatorCard />
          </Box>
        ) : groups.map((group) => (
          <Paper
            key={ group.id }
            elevation={ 0 }
            className="group-container"
          >
            <div className="group-header">
              <div>
                <Typography variant="h6" className="group-name">
                  { group.displayName }
                </Typography>
              </div>
              <IconButton
                onClick={ (e) => handleGroupMenuOpen(e, group) }
                aria-label={ translate("Group Actions") }
                className="user-actions"
              >
                <MoreHorizIcon />
              </IconButton>
            </div>
            <List className="users-list">
              {
                group.userAssignments?.map((assignment) => (
                  <ListItem key={ assignment.userId } className="user-item">
                    <div className="user-info">
                      <Avatar
                        src={ assignment.user.avatarUrl }
                        alt={ assignment.user.fullName }
                      >
                        { assignment.user.initials }
                      </Avatar>
                      <div className="user-details">
                        <Typography variant="subtitle1" fontWeight="bold">
                          { assignment.user.fullName }
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" textTransform="capitalize">
                          { assignment.permission.toLowerCase() }
                        </Typography>
                      </div>
                    </div>
                    <IconButton
                      size="small"
                      onClick={ (e) => handleUserMenuOpen(e, assignment) }
                      aria-label={ translate("User Actions") }
                      className="user-actions"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </ListItem>
                ))
               }
            </List>

          </Paper>
        )) }
      </div>

      <Menu
        anchorEl={ groupMenuAnchor }
        open={ Boolean(groupMenuAnchor) }
        onClose={ handleMenuClose }
        className="group-menu"
      >
        <MenuItem onClick={ handleMenuClose }>
          { translate("Edit Group") }
        </MenuItem>
        <MenuItem onClick={ handleMenuClose }>
          { translate("Delete Group") }
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={ userMenuAnchor }
        open={ Boolean(userMenuAnchor) }
        onClose={ handleMenuClose }
        className="group-menu"
      >
        <MenuItem onClick={ handleMenuClose }>
          { translate("Edit Permissions") }
        </MenuItem>
        <MenuItem onClick={ handleMenuClose }>
          { translate("Delete User") }
        </MenuItem>
      </Menu>
    </div>
  )
}
