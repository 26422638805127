import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import ToastController from "../../controller/Toast"
import PageShell from "../../component/PageShell"
import UserSettingsOverview from "../../component/UserSettingsOverview"

export default function UserSettings() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.UserSettings" })
  return (
    <div className="cp_user-settings_page">
      <ToastController />
      <PageShell title={ translate("User Settings") } />
      <div className="cp_user-settings_page-container">
        <UserSettingsOverview />
      </div>
    </div>
  )
}
